import { Navigate } from 'react-router-dom';
import { usePermissions } from '../hooks/usePermissions';

type ProtectedRouteProps = {
  children: React.ReactNode;
  redirectTo?: string;
  checkAccess?: () => boolean;
};

const ProtectedRoute = ({
  children,
  redirectTo = '/home',
  checkAccess = () => false,
}: ProtectedRouteProps) => {
  if (!checkAccess()) {
    return <Navigate to={redirectTo} replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
