import React from "react";
import { BackgroundJobStatus, BackgroundJobType } from "../utils";
import { GoLiveIcon, RecurringIcon, TurnOverIcon } from "../../../../../components/icons";

export const Badge = (value, type, processed) => {
  const formattedBadge = {
    [BackgroundJobStatus.DONE]:  <div className='flex items-center rounded-md px-2 py-1 w-fit bg-success-50 text-success-500'>
      <p className="text-xs font-semibold">{type === BackgroundJobType.GO_LIVE ?  `${processed} live magazines` : 'Success'}</p>
    </div>,
    [BackgroundJobStatus.PROCESSING]: <div className='flex items-center rounded-md px-2 py-1 w-fit bg-orange-50 text-orange-500'>
      <p className="text-xs font-semibold">Processing</p>
    </div>,
    [BackgroundJobStatus.ERROR]: <div className='flex items-center rounded-md px-2 py-1 w-fit bg-danger-50 text-danger-500'>
      <p className="text-xs font-semibold">Failed. Please try again!</p>
    </div>,
  }
  return formattedBadge[value] || null;
};

export const Icon = (value, status) => {
  const formattedBgColor = {
    [BackgroundJobStatus.ERROR]: 'bg-error-400',
    [BackgroundJobStatus.DONE]: 'bg-success-400',
    [BackgroundJobStatus.PROCESSING]: 'bg-orange-400',
  }
  const backgroundColor = formattedBgColor[status] || 'bg-success-400';
  const formattedIcon = {
    [BackgroundJobType.MONTHLY_TURN_OVER]: <div className={`flex items-center justify-center w-[34px] h-[34px] rounded-full ${backgroundColor}`}>
      <TurnOverIcon />
    </div>,
    [BackgroundJobType.GO_LIVE]: <div className={`flex items-center justify-center w-[34px] h-[34px] rounded-full ${backgroundColor}`}>
      <GoLiveIcon />
    </div>,
    [BackgroundJobType.RECURRING_TICKET]: <div className={`flex items-center justify-center w-[34px] h-[34px] rounded-full ${backgroundColor}`}>
      <RecurringIcon />
    </div>,
  };

  return formattedIcon[value] || null;
};

export const StepsBadge = (steps, isCompleted) => {
  if (isCompleted) {
    return (
      <div className='flex items-center rounded-md px-2 py-1 w-fit bg-success-50 text-success-500'>
        <p className="text-xs font-semibold">Completed</p>
      </div>
    );
  }

  const stepsDone = steps.filter(step => step.status === BackgroundJobStatus.DONE).length;

  return (
    <div className='flex items-center rounded-md px-2 py-1 w-fit bg-orange-50 text-orange-500'>
      <p className="text-xs font-semibold">{stepsDone} / 3 steps completed</p>
    </div>
  );
};