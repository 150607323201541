import React, { useState, useEffect } from 'react';
import { useMOD } from '../context/MODContext';
import TextInput from '../../../../components/TextInput';
import OrdersTable from './OrdersTable';
import Datepicker from 'react-tailwindcss-datepicker';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import ProcessShipmentModal from './ProcessShipmentModal';
import BatchListView from './BatchListView';
import SkeletonLoader from '../../../../components/SkeletonLoader';
import MODDropdown from '../../../../components/MODDropdown';

interface DraftName {
  title: string;
  draftId: string;
}

type MODContentProps = {
  currentTab: 'awaiting' | 'batches';
  setCurrentTab: (tab: 'awaiting' | 'batches') => void;
  awaitingOrdersCount: number;
  batchesCount: number;
  selectedOrders: string[];
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  processOrders: (orders: string[]) => Promise<void>;
  isLoading: boolean;
  draftNames: DraftName[];
  selectedDraftName: string[];
  setSelectedDraftName: (draftIds: string[]) => void;
  fetchOrders: (startDate?: string, endDate?: string) => Promise<void>;
};

const MODContent: React.FC = () => {
  const props = useMOD() as MODContentProps;
  const {
    currentTab,
    setCurrentTab,
    awaitingOrdersCount,
    batchesCount,
    selectedOrders,
    searchTerm,
    setSearchTerm,
    processOrders,
    isLoading,
    draftNames,
    selectedDraftName,
    setSelectedDraftName,
    fetchOrders,
  } = props;

  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
  });

  const handleValueChange = (newValue: DateValueType) => {
    setValue(newValue);
    if (newValue?.startDate && newValue?.endDate) {
      const startDate = new Date(newValue.startDate);
      const endDate = new Date(newValue.endDate);
      const formattedStartDate = startDate.toISOString().split('T')[0];
      const formattedEndDate = endDate.toISOString().split('T')[0];
      fetchOrders(formattedStartDate, formattedEndDate);
    }
  };

  useEffect(() => {
    if (currentTab === 'awaiting') {
      const startDate = value?.startDate
        ? new Date(value.startDate).toISOString().split('T')[0]
        : undefined;
      const endDate = value?.endDate
        ? new Date(value.endDate).toISOString().split('T')[0]
        : undefined;
      fetchOrders(startDate, endDate);
    }
  }, [selectedDraftName, currentTab, fetchOrders, value]);

  const [isProcessModalOpen, setIsProcessModalOpen] = useState(false);

  return (
    <div className="flex flex-col gap-6 mb-28">
      <div className="relative h-[38px]">
        <div className="absolute left-0 top-[37px] w-full h-[1px] bg-neutral-200" />
        <div className="absolute left-0 top-0 flex items-center gap-8">
          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => setCurrentTab('awaiting')}
          >
            <div className="px-1 pb-4 flex items-center gap-2">
              <span
                className={`text-sm font-semibold ${
                  currentTab === 'awaiting'
                    ? 'text-neutral-800'
                    : 'text-neutral-600'
                }`}
              >
                Awaiting Printing
              </span>
              <div className="px-2.5 py-0.5 bg-error-50 rounded-[10px]">
                <span className="text-error-500 text-xs font-semibold">
                  {awaitingOrdersCount}
                </span>
              </div>
            </div>
            <div
              className={`h-[2px] w-full relative -top-[7px] ${
                currentTab === 'awaiting' ? 'bg-primary-500' : ''
              }`}
            />
          </div>

          <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => setCurrentTab('batches')}
          >
            <div className="px-1 pb-4 flex items-center gap-2">
              <span
                className={`text-sm font-semibold ${
                  currentTab === 'batches'
                    ? 'text-neutral-800'
                    : 'text-neutral-600'
                }`}
              >
                Batches
              </span>
              <div className="px-2.5 py-0.5 bg-error-50 rounded-[10px]">
                <span className="text-error-500 text-xs font-semibold">
                  {batchesCount}
                </span>
              </div>
            </div>
            <div
              className={`h-[2px] w-full relative -top-[7px] ${
                currentTab === 'batches' ? 'bg-primary-500' : ''
              }`}
            />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="bg-white rounded-lg shadow">
          <SkeletonLoader
            rows={10}
            columns={currentTab === 'awaiting' ? 6 : 4}
          />
        </div>
      ) : (
        <>
          {currentTab === 'awaiting' ? (
            <div className="flex flex-col gap-6">
              <div className="bg-white rounded-lg shadow px-6 py-[22px]">
                <div className="flex gap-3.5">
                  <TextInput
                    id="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search for order no., name, email, or phone number"
                    width="374px"
                  />
                  <MODDropdown
                    options={draftNames}
                    selectedValues={selectedDraftName}
                    onSelect={setSelectedDraftName}
                  />
                  <div className="w-[184px]">
                    <Datepicker
                      value={value}
                      onChange={handleValueChange}
                      primaryColor="sky"
                      separator="-"
                      showShortcuts={true}
                      showFooter={true}
                      displayFormat="MMM DD"
                      maxDate={new Date()}
                    />
                  </div>
                </div>
              </div>
              <OrdersTable onProcessClick={() => setIsProcessModalOpen(true)} />
            </div>
          ) : (
            <BatchListView />
          )}
        </>
      )}

      <ProcessShipmentModal
        isOpen={isProcessModalOpen}
        onClose={() => setIsProcessModalOpen(false)}
        selectedCount={selectedOrders.length}
        onConfirm={async () => {
          await processOrders(selectedOrders);
        }}
      />
    </div>
  );
};

export default MODContent;
