import qs from "qs";
import offerCustomPaths from "./offerCustomPaths";
import Cookies from "js-cookie";
import env from '../../../../environments';

type getOnboardUrlParams = {
  launchOfferType?: string;
  offerCode: string;
  isProductCatalogOffer?: boolean;
};

type UserInfo = {
  applicationId: string;
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  roles: string[];
  scope: string;
  sid: string;
  sub: string;
  tid: string;
};

const getOnboardUrl = ({
  offerCode,
  launchOfferType,
  isProductCatalogOffer,
}: getOnboardUrlParams) => {
  
  const orderSystem = "directSales";
  const uuid = Date.now();
  const userDataStr = Cookies.get("user");
  const userData: UserInfo = userDataStr ? JSON.parse(userDataStr) : null;
  const emailAgent = userData?.email;
  
  const query = qs.stringify({ orderSystem, salesAgent: emailAgent, uuid });
  
  if (isProductCatalogOffer) {
    const productCatalogOfferUrl = env.PUBLISHED_OFFER_URL + offerCode;
    return `${productCatalogOfferUrl}?${query}`;
  }

  const CLICK_FUNNEL_OFFER_TYPE = "clickfunnel";
  const locationOrigin = import.meta.env.VITE_LAUNCH_OFFER_URL;
  
  const isClickFunnelOffer = launchOfferType === CLICK_FUNNEL_OFFER_TYPE;
  const code = isClickFunnelOffer ? offerCustomPaths[offerCode] : offerCode;
  const projectPath = isClickFunnelOffer ? "landing-pages" : "onboard";
  const step = isClickFunnelOffer ? "" : "/place-order";

  return `${locationOrigin}/${projectPath}${step}/${code}?${query}`;
};

export default getOnboardUrl;