import React, { useMemo, useState } from "react";
import Title from '../../../../components/Title';
import { useNavigate, useParams } from 'react-router-dom';
import SkeletonLoader from "../../../../components/SkeletonLoader";
import PaginationFlowBite from "../../../../components/PaginationFlowBite";
import noItemsImage from '../../../../../assets/images/no-items.png';
import TextInput from "../../../../components/TextInput";
import Button from '../../../../components/Button';
import { useQuery } from "react-query";
import { getTurnOverDetails } from '../../../../../modules/api/rmm-automation';
import AfyTable from "../../../../components/V2/Table";
import { ExportIcon,  BackArrow} from "../../../../../components/icons";
import useDebounce from '../../../../utils/useDebounce';
import { getFullMonth } from '../../../reports/rmMagazineReport/utils';

export default function RmmAutomationDetails() {
  const navigate = useNavigate();
  const [ customerEmail, setCustomerEmail ] = useState('');
  const [ displayedCustomerEmail, setDisplayedCustomerEmail ] = useState('');
  const [ rmmData, setRmmData ] = useState([]);
  const [ perPage, setPerPage ] = useState(10);
  const [ currentTablePage, setCurrentTablePage ] = useState(1);
  const [ tableMeta, setTableMeta ] = useState({ total: 0, lastPage: 0 });
  const [ isLoadingContent, setIsLoadingContent ] = useState(true);
  const { id } = useParams();

  const { isLoading: isFetching } = useQuery(
    ['getTurnOverDetails', id, customerEmail || '', currentTablePage, perPage],
    () => getTurnOverDetails(id, customerEmail || '', currentTablePage, perPage),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      onSuccess: ({ data, meta }) => {
        setRmmData(data);
        setTableMeta({ total: meta.total, lastPage: Math.ceil((meta.total/meta.perPage))});
        setPerPage(meta.perPage || 10);
        setIsLoadingContent(false);
      },
      onError: (error) => {
        console.error(error);
        setIsLoadingContent(false);
      },
    }
  );

  const magazinesMonth = rmmData.length > 0 ? getFullMonth[rmmData[0].month] : '';
  const magazinesYear = rmmData.length > 0 ? rmmData[0].year : '';

  const debouncedSearch = useDebounce((value) => {
    setCustomerEmail(value);
    setCurrentTablePage(1);
    setPerPage(10);
  }, 500);

  const handleSearch = (value) => {
    debouncedSearch(value);
    setDisplayedCustomerEmail(value);
  }

  const handleDownloadItem = (link) => {
    window.open(link, "_blank");
  };

  const handlePerPage = (value, firstItemIndex) => {
    const perPage = value.value;
    const pageNumber = Math.ceil(firstItemIndex / perPage) || 1;
    setPerPage(perPage);
    setCurrentTablePage(pageNumber);
    setTableMeta({ ...tableMeta, lastPage: Math.ceil((tableMeta.total/perPage))})
  };

  const handlePageChange = (newPage) => {
    setCurrentTablePage(newPage);
  };

  const resetFilters = () => {
    setCustomerEmail('');
    setDisplayedCustomerEmail('');
  };

  const tableColumns = useMemo(() => [
    {
      header: 'Member',
      accessorFn: (row) => row.id,
      cell: (info) => (
        <div className="flex justify-between gap-2">
          <div>
            <div className="text-gray-900">
              {info.row.original.customerInfo?.firstName} {info.row.original.customerInfo?.lastName}
            </div>
            <div className="text-zinc-500 text-sm font-medium leading-tight">
              {info.row.original.customerInfo?.email}
            </div>
          </div>

          <div className="flex gap-2">
            <Button
              variant="OUTLINED"
              onClick={() => handleDownloadItem(info.row.original.generatedInfo.coversOnlyUrl)}
            >
              <ExportIcon className="mr-2" />
              Download cover
            </Button>

            <Button
              variant="DARK"
              onClick={() => handleDownloadItem(info.row.original.generatedInfo.url)}
            >
              <ExportIcon stroke="#FFFFFF" className="mr-2" />
              Download PDF
            </Button>
          </div>
        </div>
      ),
      customProp: 'customerData',
    },
  ], []);

  return (
    <div className="min-h-screen max-w-full">
      <div className="mb-4 pt-6">
        <div
          className="flex gap-2 text-sm font-medium text-neutral-600 cursor-pointer"
          onClick={() => navigate('/referral-marketing')}
        >
          <BackArrow />
          Back
        </div>
      </div>
      <div className="mb-6">
        <Title><p className="capitalize">{magazinesMonth} {magazinesYear} Magazines</p></Title>
      </div>

      <div>
        <div className="w-full h-full bg-white shadow-card rounded-t-lg p-6">
          <div className="h-[46px] max-w-[374px]">
            <TextInput
              value={displayedCustomerEmail}
              placeholder="Search customer email"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
        </div>
        {(isLoadingContent || isFetching ) ? (
          <SkeletonLoader />
        ) : (
          <div className="w-full">
            {rmmData.length > 0 ? (
              <>
                <div className="w-full bg-white justify-center items-center overflow-x-auto border-t">
                  <AfyTable
                    data={rmmData}
                    columns={tableColumns}
                    perPage={perPage}
                    handleSort={()=> {}}
                    columnName={{ key: '', value: '' }}
                    showTableHead={false}
                  />
                </div>
                <div className="bg-white rounded-md py-4 px-6 max-[1180px]:px-0 h-full mb-28">
                  <PaginationFlowBite
                    tablePagination={{
                      pageSize: perPage,
                      total: tableMeta.total,
                      lastPage: tableMeta.lastPage,
                      currentPage: currentTablePage,
                      onChange: handlePageChange,
                    }}
                    handlePerPage={handlePerPage}
                  />
                </div>
              </>
            ) : (
              <div className="w-full h-[382px] py-[100px] flex flex-col justify-center items-center gap-3.5">
                <img
                  className="w-[182px] h-[121px] object-cover"
                  src={noItemsImage}
                  alt="No items found"
                />
                <div className="self-stretch h-[47px] flex flex-col justify-start items-center gap-1">
                  <div className="self-stretch text-center text-neutral-800 text-base font-semibold font-['Figtree'] leading-tight">
                    No results found
                    {customerEmail ? ` for "${customerEmail}".` : '.'}
                  </div>
                  <div className="text-center">
                    <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                      Try a different keyword or{' '}
                    </span>
                    <button
                      onClick={resetFilters}
                      className="text-sky-400 text-sm font-medium underline leading-[23px] focus:outline-none"
                    >
                      reset filters
                    </button>
                    <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                      .
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      
    </div>
  );
}