import * as yup from 'yup';

type CurrentStep = number;

export const validationSchema = () =>
  yup.object().shape({
    currentStep: yup.number(),

    magazine: yup.object().when('currentStep', {
      is: (step: CurrentStep) => step === 0,
      then: () =>
        yup.object({
          _formFields: yup.object({
            agentName: yup.string().required('Type your agent name'),
            phoneNumber: yup
              .string()
              .test('len', 'Phone number must be valid', (val) => {
                const validMobile = val?.replace(/[^\d]/g, '');
                return validMobile?.length === 10;
              })
              .required('Phone Number is required'),
            email: yup
              .string()
              .email('Email must be valid')
              .matches(
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/,
                'Please enter valid email',
              )
              .required('Type your email address'),
            website: yup
              .string()
              .max(50, 'Only 50 characters are allowed.')
              .matches(
                /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9-]+(?:\.[a-zA-Z]{2,})+(?:\/[\w#!:.?+=&%@!\-/]*)?$/,
                'Please enter valid domain',
              ),
          }),
        }),
    }),

    frontInsideCover: yup.object().when('currentStep', {
      is: (step: CurrentStep) => step === 2,
      then: () =>
        yup.object({
          _formFields: yup.object({
            frontInsideCoverText: yup
              .string()
              .max(1500, 'Description must not be more than 1500 characters.'),
          }),
        }),
    }),

    backInsideCover: yup.object().when('currentStep', {
      is: (step: CurrentStep) => step === 3,
      then: () =>
        yup.object().when('selectedOption', {
          is: (option: string) => {
            return option;
          },
          then: () =>
            yup.object().when('selectedOption', (selectedOption: string[]) => {
              const option = selectedOption[0];
              switch (option) {
                case 'backInsideCover-option-2':
                  return yup.object({
                    _formFields: yup.object({
                      clientName1: yup.string().required('Enter Client Name 1'),
                      testimonial1: yup
                        .string()
                        .required('Enter Testimonial 1'),
                      clientName2: yup.string().required('Enter Client Name 2'),
                      testimonial2: yup
                        .string()
                        .required('Enter Testimonial 2'),
                      clientName3: yup.string().required('Enter Client Name 3'),
                      testimonial3: yup
                        .string()
                        .required('Enter Testimonial 3'),
                      clientName4: yup.string(),
                      testimonial4: yup.string(),
                      clientName5: yup.string(),
                      testimonial5: yup.string(),
                    }),
                  });

                case 'backInsideCover-option-3':
                  return yup.object({
                    _formFields: yup.object({
                      listingAddress: yup
                        .string()
                        .required('Enter Listing Address'),
                      listingPrice: yup
                        .string()
                        .required('Enter Listing Price'),
                    }),
                  });

                case 'backInsideCover-option-4':
                  return yup.object({
                    _formFields: yup.object({
                      listingAddress: yup
                        .string()
                        .required('Enter Listing Address'),
                      listingName: yup
                        .string()
                        .required('Enter Listing Name'),
                      description: yup.string().required('Enter Description'),  
                      features: yup.string().required('Enter Features'),
                    }),
                  });

                case 'backInsideCover-option-6':
                  return yup.object({
                    _formFields: yup.object({
                      partnerName: yup.string().required('Enter Partner Name'),
                      partnerHeader: yup
                        .string()
                        .required('Enter Partner Header'),
                      partnerPhone: yup
                        .string()
                        .required('Enter Partner Phone'),
                      partnerEmail: yup
                        .string()
                        .email('Email must be valid')
                        .matches(
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/,
                          'Please enter valid email',
                        )
                        .required('Enter Partner Email'),
                      partnerAddress: yup
                        .string()
                        .required('Enter Partner Address'),
                      partnerDescription: yup
                        .string()
                        .required('Enter Partner Description'),
                    }),
                  });

                default:
                  return yup.object();
              }
            }),
        }),
    }),

    backCover: yup.object().when('currentStep', {
      is: (step: CurrentStep) => step === 4,
      then: () =>
        yup.object().when('selectedOption', {
          is: (option: string) => {
            return option;
          },
          then: () =>
            yup.object().when('selectedOption', (selectedOption: string[]) => {
              const option = selectedOption[0];
              switch (option) {
                case 'backCover-option-3':
                  return yup.object({
                    _formFields: yup.object({
                      bookLink: yup.string().required('Enter Book Link'),
                    }),
                  });

                case 'backCover-option-4':
                  return yup.object({
                    _formFields: yup.object({
                      listingAddress: yup
                        .string()
                        .required('Enter Listing Address'),
                      listingPrice: yup
                        .string()
                        .required('Enter Listing Price'),
                    }),
                  });

                case 'backCover-option-5':
                  return yup.object({
                    _formFields: yup.object({
                      listingAddress: yup
                        .string()
                        .required('Enter Listing Address'),
                      listingName: yup.string().required('Enter Listing Name'),
                      description: yup.string().required('Enter Description'),
                      features: yup.string().required('Enter Features'),
                    }),
                  });

                default:
                  return yup.object();
              }
            }),
        }),
    }),
  });

export default validationSchema;
