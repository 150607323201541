import { useFusionAuth } from '@fusionauth/react-sdk';
import { ROLES } from '../constants/roles';

export const usePermissions = () => {
  const { user } = useFusionAuth();
  const userRoles = user?.roles || [];

  return {
    canAccessCoachMaintenance: () => userRoles.includes(ROLES.COACH_MAINTENANCE),
    canAccessRMM: () => userRoles.includes(ROLES.RMM_ACCESS),
    canAccessEditOffer: () => userRoles.includes(ROLES.OFFER_ADMIN),
  };
};
