import { Flowbite, Pagination } from 'flowbite-react';
import React, { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import { customTheme } from '../../utils/customTheme';

interface TablePagination {
  pageSize: number | string;
  total: number | string;
  lastPage: number | string;
  currentPage: number | string;
  onChange: (page: number) => void;
}

interface PaginationFlowBiteProps {
  tablePagination: TablePagination;
  handlePerPage: (selectedOption: { value: number; label: string }) => void;
}

interface PerPageOption {
  value: number;
  label: string;
}

const PaginationFlowBite: React.FC<PaginationFlowBiteProps> = ({
  tablePagination,
  handlePerPage,
}) => {
  const {
    pageSize: perPage,
    total: totalPage,
    lastPage: lastPageNumber,
    currentPage,
    onChange,
  } = tablePagination;

  const [showIcons, setShowIcons] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 430) {
        setShowIcons(false);
      } else {
        setShowIcons(true);
      }
    };

    // Set the initial value
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const total = Number(totalPage);
  const lastPage = Number(lastPageNumber);

  const numNextPage = Number(currentPage);
  const numPerPage = Number(perPage);
  const firstItemIndex = (numNextPage - 1) * numPerPage + 1;
  const lastItemIndex = numNextPage * numPerPage;
  const lastItemValueShow = lastItemIndex >= total ? total : lastItemIndex;
  const content = `Showing ${firstItemIndex} to ${lastItemValueShow} of ${total}`;

  const itemsPerPage: PerPageOption[] = [
    { value: 10, label: '10' },
    { value: 25, label: '25' },
    { value: 50, label: '50' },
  ];

  const handleSelectChange = (option: SingleValue<PerPageOption>) => {
    if (option) {
      handlePerPage(option);
    }
  };

  return (
    <div className="flex justify-between items-center max-[1180px]:flex-col h-full gap-2">
      <div className="flex items-center flex-wrap justify-center">
        View
        <Select<PerPageOption>
          className="w-20 mx-2"
          defaultValue={itemsPerPage.find(
            (option) => option.value === numPerPage,
          )}
          onChange={handleSelectChange}
          options={itemsPerPage}
        />
        {content}
      </div>
      <div>
        <Flowbite theme={{ theme: customTheme }}>
          <Pagination
            currentPage={numNextPage}
            totalPages={lastPage || 100}
            previousLabel="Prev"
            onPageChange={onChange}
            showIcons={showIcons}
          />
        </Flowbite>
      </div>
    </div>
  );
};

export default PaginationFlowBite;
