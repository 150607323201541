import qs from "qs";
import offerCustomPaths from "./offerCustomPaths";
import env from '../../../../environments';

type urlParams = {
  launchOfferType?: string;
  offerCode: string;
  isProductCatalogOffer?: boolean;
  params?: { [key: string]: string };
};


const getUtmTrackingUrl = ({
  offerCode,
  launchOfferType,
  isProductCatalogOffer,
  params,
}: urlParams) => {
  
  const query = qs.stringify(params);
  
  if (isProductCatalogOffer) {
    const productCatalogOfferUrl = env.PUBLISHED_OFFER_URL + offerCode;
    return `${productCatalogOfferUrl}?${query}`;
  }

  const CLICK_FUNNEL_OFFER_TYPE = "clickfunnel";
  const locationOrigin = import.meta.env.VITE_LAUNCH_OFFER_URL;
  
  const isClickFunnelOffer = launchOfferType === CLICK_FUNNEL_OFFER_TYPE;
  const code = isClickFunnelOffer ? offerCustomPaths[offerCode] : offerCode;
  const projectPath = isClickFunnelOffer ? "landing-pages" : "onboard";
  const step = isClickFunnelOffer ? "" : "/place-order";

  return `${locationOrigin}/${projectPath}${step}/${code}?${query}`;
};

export default getUtmTrackingUrl;