import React, { useState } from 'react';
import Button from '../../../../components/Button';
import { Modal } from 'flowbite-react';
import { SuccessCheck } from '../../../../../components/icons';
import Loader from '../../../../components/Loaders';
import { useNavigate } from 'react-router-dom';
import { useMOD } from '../context/MODContext';

interface ProcessShipmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCount: number;
  onConfirm: () => Promise<void>;
}

const ProcessShipmentModal: React.FC<ProcessShipmentModalProps> = ({
  isOpen,
  onClose,
  selectedCount,
  onConfirm,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();
  const { batches } = useMOD();

  const handleConfirm = async () => {
    setIsProcessing(true);
    try {
      await onConfirm();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsProcessing(false);
      setIsSuccess(true);
    } catch (error) {
      setIsProcessing(false);
    }
  };

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setIsSuccess(false);
    }, 200);
  };

  if (isProcessing) {
    return (
      <div className="fixed inset-0 bg-black/50 z-50">
        <Loader />
      </div>
    );
  }

  if (isSuccess) {
    return (
      <Modal show={isOpen} onClose={handleClose} size="md">
        <Modal.Body>
          <div className="flex flex-col items-center justify-center px-[30px] gap-6">
            <div className="w-[52px] h-[52px] bg-success-500 rounded-full flex items-center justify-center">
              <SuccessCheck className="w-[34px] h-[34px]" />
            </div>
            <div className="flex flex-col items-center gap-3.5">
              <p className="text-neutral-800 text-base font-bold">
                Orders are now being processed
              </p>
              <p className="text-neutral-500 text-sm font-medium text-center">
                Please don't forget to upload the shipment tracking file.
              </p>
            </div>
            <Button variant="LIGHT" onClick={handleClose}>
              Okay
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={isOpen} onClose={onClose} size="md">
      <div className="p-[30px]">
        <div className="flex flex-col gap-[18px]">
          <div className="pb-3.5 border-b border-neutral-200 flex justify-between items-center">
            <h3 className="text-neutral-800 text-base font-bold">
              Confirm shipment
            </h3>
            <button
              onClick={onClose}
              className="w-[38px] h-[38px] p-2.5 bg-neutral-100 rounded-md"
            >
              <span className="sr-only">Close</span>
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <p className="text-neutral-800 text-sm font-medium">
            You're about to process the shipment for{' '}
            <span className="font-bold">
              {selectedCount} {selectedCount === 1 ? 'order' : 'orders'}
            </span>
            . Please confirm that all details are correct before proceeding.
          </p>
          <div className="flex justify-end gap-2 pt-4">
            <Button variant="LIGHT" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="PRIMARY" onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProcessShipmentModal;
