import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  productTitle: Yup.string()
    .trim(),
  productDescription: Yup.string()
    .trim(),
  templateId: Yup.string()
    .trim()
    .required('Template ID is required'),
  ctaButtonTitle: Yup.string()
    .trim(),
  customScheduleHeader: Yup.string()
    .trim()
    .required('Custom Schedule Header is required!'),
});

export default validationSchema;
