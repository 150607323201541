import React, { useState, useEffect, useRef, FocusEventHandler } from 'react';
import classnames from 'classnames';
import { Badge, Tooltip } from 'flowbite-react';
import { ArrowDown } from '../../components/icons';

interface DropdownOption {
  id: string;
  label: string | React.ReactNode;
  value: string;
  notAllowed?: boolean;
  selectedLabel?: string;
}

interface DropdownProps {
  options: DropdownOption[];
  placeholder?: string;
  onSelect: (value: string | string[]) => void;
  multiple?: boolean;
  selectedValues?: string | string[];
  disabled?: boolean;
  disabledText?: string;
  error?: boolean;
  id?: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  dropdownClasses?: string;
  inputFit?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  placeholder = 'Select an option',
  onSelect,
  multiple = false,
  selectedValues = [],
  disabled = false,
  disabledText = '',
  error = false,
  id,
  onBlur,
  dropdownClasses,
  inputFit,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (value: string) => {
    let newSelectedValues;
    if (multiple) {
      if (Array.isArray(selectedValues) && selectedValues.includes(value)) {
        newSelectedValues = selectedValues.filter((v) => v !== value);
      } else {
        newSelectedValues = Array.isArray(selectedValues)
          ? [...selectedValues, value]
          : [value];
      }
    } else {
      newSelectedValues = value;
      setShowDropdown(false);
    }
    onSelect(newSelectedValues);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  const showTooltip = () => Boolean(disabled && disabledText);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Tooltip
      content={disabledText}
      theme={{
        base: `text-neutral-50 text-sm p-2 px-3 rounded-lg font-normal font-figtree ${showTooltip() ? '' : 'invisible'}`,
        target: 'w-full',
      }}
    >
      <div ref={dropdownRef} className="relative w-full">
        <button
          className={classnames(
            'flex items-center justify-between border border-neutral-200 w-full h-[42px] pl-3.5 pr-4 rounded-md focus:border-2 focus:border-primary-500 focus:outline-none focus:ring-0 focus:ring-transparent',
            disabled && 'bg-neutral-50 text-neutral-400 cursor-not-allowed',
            error && 'border-2 border-red-500',
            inputFit ? "h-fit min-h-[42px] py-[7px]" : "h-[42px]"
          )}
          type="button"
          onClick={() => setShowDropdown(!showDropdown)}
          disabled={disabled}
          id={id}
          onBlur={onBlur}
        >
          <div
            className={classnames(inputFit ? "flex flex-wrap items-center gap-2 w-full h-full" : "flex items-center gap-2 w-full h-full overflow-x-auto")}
          >
            {Array.isArray(selectedValues) && selectedValues.length > 0 && multiple ? (
              <>
                {selectedValues.slice(0, 2).map((value, index) => (
                  <Badge
                    key={index}
                    color="neutral-50"
                    className="mr-1 bg-neutral-50 block"
                  >
                    {value}
                  </Badge>
                ))}

                {selectedValues.length > 2 && (
                  <div className="mr-1 block text-xs font-semibold">
                    {`+${selectedValues.length - 2} more`}
                  </div>
                )}
              </>
            ) : selectedValues.length > 0 && !multiple ? (
              <span className="text-sm font-medium text-neutral-900">
                {selectedValues}
              </span>
            ) : (
              <span className="text-sm font-medium text-neutral-300 ">
                {placeholder || 'Nenhum item selecionado'}
              </span>
            )}
          </div>
          <ArrowDown
            className={classnames(
              'transition-transform',
              showDropdown && 'transform rotate-180',
            )}
          />
        </button>
        {showDropdown && (
          <ul className={classnames("absolute left-0 right-0 py-2 bg-white w-full rounded-md border border-neutral-100 mt-1 shadow-box z-10", dropdownClasses)}>
            {options.map((option, index) => (
              <li key={index}>
                <button
                  type="button"
                  onClick={() => {
                    if (option.notAllowed) return;
                    handleSelect(option.selectedLabel || option.value);
                  }}
                  className={classnames(
                    'py-2 px-5 text-sm font-medium w-full text-left',
                    !option.notAllowed && 'hover:bg-neutral-100 cursor-pointer',
                    selectedValues.includes(option.value) && 'bg-neutral-100',
                  )}
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </Tooltip>
  );
};

export default Dropdown;
