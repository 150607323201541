import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Tooltip } from 'flowbite-react';
import { trashBlackIcon, plusIcon, questionCircleIcon } from '../../../../../../assets/images';
import { Button, InputLabel, TextInput } from '../../../../../components';
import DefaultSideModal from '../../../../../components/modals/DefaultSideModal';
import { getUtmTrackingUrl } from '../../../utils';

interface UTMParametersProps {
    showUTMModal: boolean;
    setShowUTMModal: React.Dispatch<React.SetStateAction<boolean>>;
    offer: any;
    setShowLinkModal: React.Dispatch<React.SetStateAction<boolean>>;
    setUtmTrackingLink?: React.Dispatch<React.SetStateAction<string>>;
}

const UTMParametersModal: React.FC<UTMParametersProps> = ({
    showUTMModal,
    setShowUTMModal,
    offer,
    setShowLinkModal,
    setUtmTrackingLink,
}) => {
    const { code } = offer ?? {};

    const [customParamsCount, setCustomParamsCount] = useState<number>(3);
    const [allParams, setAllParams] = useState<Array<{ key: string, value: string }>>([]);

    const defaultInitialValues = {
        utm_source: '',
        utm_medium: '',
        utm_content: '',
        utm_term: '',
        channel: '',
    };

    const [initialValues, setInitialValues] = useState<{ [key: string]: string }>(defaultInitialValues);

    const loadSavedParams = () => {
        const savedOffers = JSON.parse(localStorage.getItem('utmParams') || '{}');
        const savedParams = savedOffers[code];

        if (savedParams) {
            const paramsArray = Object.entries(savedParams).map(([key, value]) => ({
                key,
                value: value as string,
            }));
            setAllParams(paramsArray);

            const initialValuesFromStorage = paramsArray.reduce((acc, param) => {
                acc[param.key] = param.value;
                return acc;
            }, { ...defaultInitialValues });

            setInitialValues(initialValuesFromStorage);
        } else {
            setInitialValues(defaultInitialValues);
            setAllParams([]);
        }
    };

    useEffect(() => {
        if (code) {
            loadSavedParams(code);
        }
    }, [code, showUTMModal]);

    const getUrl = (validParams) => {
        let obj;
        if (offer?.isProductCatalog) {
          obj = {
            offerCode: code,
            isProductCatalogOffer: true,
            params: validParams,
          };
        } else {
          obj = {
            offerCode: code,
            launchOfferType: offer.launchOfferType,
            params: validParams,
          };
        }
    
        const url = getUtmTrackingUrl(obj);
        
        return url;
    };

    const handleAdd = () => {
        if (!code) {
            console.error("Offer code is required");
            return;
        }
        const validParams: { [key: string]: string } = {};

        allParams.forEach((param) => {
            if (param && param.key && param.value) {
                validParams[param.key] = param.value;
            }
        });

        const savedOffers = JSON.parse(localStorage.getItem('utmParams') || '{}');
        savedOffers[code] = validParams;

        localStorage.setItem('utmParams', JSON.stringify(savedOffers));

        const url = getUrl(validParams);
        
        setUtmTrackingLink(url);
        setShowLinkModal(true);
    };

    const handleAddParameter = () => {
        setCustomParamsCount((prevCount) => prevCount + 1);
    };

    const handleFillParam = (key: string, value: string, index: number, isCustom: boolean = false) => {
        setAllParams((prevParams) => {
            let newParams = [...prevParams];

            if (key.trim() === '' || value.trim() === '') {
                if (isCustom) {
                    newParams.splice(index, 1);
                } else {
                    newParams = newParams.filter((param) => param.key !== key);
                }
                return newParams;
            }

            if (isCustom) {
                newParams[index] = { ...newParams[index], [key]: value };
            } else {
                const paramIndex = newParams.findIndex((param) => param?.key === key);
                if (paramIndex >= 0) {
                    newParams[paramIndex] = { key, value };
                } else {
                    newParams.push({ key, value });
                }
            }
            return newParams;
        });
    };

    const handleDeleteCustomParam = (index) => {
        setAllParams((prevParams) => {
            const newParams = [...prevParams];
            newParams.splice(index + 5, 1);
            return newParams;
        });
        setCustomParamsCount((prevCount) => prevCount - 1);
    };

    // Validation to allow only letters, numbers, and underscore
    const validateInput = (value: string) => {
        const regex = /^[a-zA-Z0-9_]*$/;
        return regex.test(value);
    };

    const showCustomParameters = ({ values, handleChange, handleBlur }) => {
        const customInputs: JSX.Element[] = [];
        for (let i = 0; i < customParamsCount; i++) {
            const key = values[`key-${i}`] || '';
            const value = values[`value-${i}`] || '';

            const hasError = (key.trim() !== '' && value.trim() === '') || (key.trim() === '' && value.trim() !== '');

            customInputs.push(
                <div key={i} className="flex gap-3.5">
                    <TextInput
                        type="text"
                        id={`key-${i}`}
                        placeholder="Parameter key"
                        onChange={(e) => {
                            const isValid = validateInput(e.target.value);
                            if (isValid) handleChange(e);
                        }}
                        onBlur={(element) => {
                            handleBlur(element);
                            handleFillParam('key', element.target.value, i + 5, true);
                        }}
                        value={key}
                        error={hasError} // Show error only if one field is filled and the other is not
                    />
                    <TextInput
                        type="text"
                        id={`value-${i}`}
                        placeholder="Parameter value"
                        onChange={(e) => {
                            const isValid = validateInput(e.target.value);
                            if (isValid) handleChange(e);
                        }}
                        onBlur={(element) => {
                            handleBlur(element);
                            handleFillParam('value', element.target.value, i + 5, true);
                        }}
                        value={value}
                        error={hasError} // Show error only if one field is filled and the other is not
                    />
                    <button
                        onClick={() => handleDeleteCustomParam(i)}
                        className="min-w-[38px] min-h-[38px] p-2.5 bg-neutral-100 rounded-md flex justify-center items-center"
                    >
                        <img src={trashBlackIcon} alt="check" className="min-w-[18px] min-h-[18px]" />
                    </button>
                </div>
            );
        }
        return (
            <div>
                <InputLabel
                    label={
                        <div className="flex items-center gap-2">
                            <span>Custom parameters</span>
                            <Tooltip
                                content="Add additional UTM parameters that’s not already listed for more detailed tracking"
                                className="w-[224px]"
                            >
                                <img src={questionCircleIcon} alt="question" className="w-5 h-5" />
                            </Tooltip>
                        </div>
                    }
                />
                <div className="flex flex-col gap-3.5">
                    {customInputs}
                    <Button
                        variant="OUTLINED"
                        type="button"
                        className="w-fit mb-[130px]"
                        onClick={handleAddParameter}
                    >
                        <div className="flex gap-2 items-center">
                            <img src={plusIcon} alt="add" className="w-[16px] h-[16px]" />
                            <span>Add parameter</span>
                        </div>
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <Formik initialValues={initialValues} enableReinitialize onSubmit={handleAdd}>
            {({ values, handleChange, handleSubmit, handleBlur }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <DefaultSideModal
                            showModal={showUTMModal}
                            setShowModal={setShowUTMModal}
                            title="Create UTM tracking link"
                            footer={
                                <Button type="submit" onClick={handleAdd}>
                                    Create
                                </Button>
                            }
                        >
                            <div className="flex flex-col gap-6 max-w-[740px]">
                                {Object.keys(initialValues).map((key, index) => (
                                    <div key={index}>
                                        <InputLabel label={key} />
                                        <TextInput
                                            type="text"
                                            id={key}
                                            onChange={(e) => {
                                                const isValid = validateInput(e.target.value);
                                                if (isValid) handleChange(e);
                                            }}
                                            onBlur={(element) => {
                                                handleBlur(element);
                                                handleFillParam(key, element.target.value, index);
                                            }}
                                            value={values[key] || ''}
                                        />
                                    </div>
                                ))}
                                {showCustomParameters({ values, handleChange, handleBlur })}
                            </div>
                        </DefaultSideModal>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default UTMParametersModal;
