import React from "react";
import classnames from "classnames";

type InputLabelProps = {
  label: string | React.ReactNode;
  className?: string;
  description?: string;
  required?: boolean;
};

const InputLabel = ({
  label,
  className,
  description,
  required,
  ...props
}: InputLabelProps) => {
  return (
    <label
      className={classnames(
        "text-sm font-semibold text-neutral-800 mb-1 block",
        className
      )}
      {...props}
    >
      {label}
          {required && <span className="text-error-400">*</span>}
          
      {description && (
        <p className="text-sm text-neutral-500 font-medium">
          {description}
        </p>
      )}
    </label>
  );
};

export default InputLabel;
