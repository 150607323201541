import * as React from 'react';
import type { SVGProps } from 'react';

const SvgShippedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="#fff" clipPath="url(#ShippedIcon_svg__a)">
      <path d="M9.333 1.947v5.54c0 .68-.553 1.233-1.233 1.233H2a.67.67 0 0 1-.667-.667v-4.26c0-1.36 1.1-2.46 2.46-2.46h4.92c.347 0 .62.274.62.614" />
      <path d="M14.333 10.333a.33.33 0 0 1 .334.334v.666c0 1.107-.894 2-2 2 0-1.1-.9-2-2-2s-2 .9-2 2H7.333c0-1.1-.9-2-2-2s-2 .9-2 2c-1.106 0-2-.893-2-2V10c0-.367.3-.667.667-.667h6.333c.92 0 1.667-.746 1.667-1.666V4c0-.367.3-.667.667-.667h.56c.48 0 .92.26 1.16.674l.426.746a.168.168 0 0 1-.146.247C11.747 5 11 5.747 11 6.667v2c0 .92.747 1.666 1.667 1.666z" />
      <path d="M5.333 14.667a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667M10.667 14.667a1.333 1.333 0 1 0 0-2.667 1.333 1.333 0 0 0 0 2.667M14.667 8.353v.98h-2A.67.67 0 0 1 12 8.667v-2c0-.367.3-.667.667-.667h.86l.966 1.693c.114.2.174.427.174.66" />
    </g>
    <defs>
      <clipPath id="ShippedIcon_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgShippedIcon;
