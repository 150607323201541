import React from "react";

const RecurringIcon = ({ fill = "white", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" {...props} >
      <path d="M2.45321 7.55555H1.70321V7.55556L2.45321 7.55555ZM2.45321 8.66667L1.92496 9.19908C2.21737 9.4892 2.68904 9.4892 2.98145 9.19908L2.45321 8.66667ZM4.10132 8.08797C4.39536 7.79622 4.39723 7.32135 4.10549 7.02731C3.81375 6.73327 3.33888 6.73141 3.04484 7.02315L4.10132 8.08797ZM1.86157 7.02315C1.56753 6.73141 1.09266 6.73327 0.800923 7.02731C0.509182 7.32135 0.511048 7.79622 0.805089 8.08797L1.86157 7.02315ZM12.1974 5.05946C12.4143 5.41232 12.8762 5.52252 13.2291 5.30558C13.582 5.08865 13.6922 4.62674 13.4752 4.27388L12.1974 5.05946ZM8.05258 1.25C4.55148 1.25 1.70321 4.06755 1.70321 7.55555H3.20321C3.20321 4.90706 5.36878 2.75 8.05258 2.75V1.25ZM1.70321 7.55556L1.70321 8.66667L3.20321 8.66667L3.20321 7.55555L1.70321 7.55556ZM2.98145 9.19908L4.10132 8.08797L3.04484 7.02315L1.92496 8.13426L2.98145 9.19908ZM2.98145 8.13426L1.86157 7.02315L0.805089 8.08797L1.92496 9.19908L2.98145 8.13426ZM13.4752 4.27388C12.3604 2.46049 10.348 1.25 8.05258 1.25V2.75C9.80912 2.75 11.3461 3.67466 12.1974 5.05946L13.4752 4.27388Z" fill={fill}/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0154 6.79862C13.3076 6.50984 13.7777 6.50984 14.0698 6.79862L15.194 7.90973C15.4886 8.2009 15.4914 8.67577 15.2002 8.97037C14.9464 9.22714 14.5531 9.26221 14.2612 9.07418C13.9411 12.2669 11.2178 14.7487 7.92184 14.7487C5.62001 14.7487 3.60075 13.5395 2.48156 11.7259C2.26403 11.3734 2.37344 10.9113 2.72593 10.6938C3.07843 10.4763 3.54052 10.5857 3.75806 10.9382C4.61254 12.3228 6.15621 13.2487 7.92184 13.2487C10.3864 13.2487 12.4123 11.4469 12.7448 9.11866C12.464 9.25432 12.1165 9.20454 11.885 8.97037C11.5939 8.67577 11.5966 8.2009 11.8912 7.90973L13.0154 6.79862Z" fill={fill}/>
    </svg>
  );
};

export default RecurringIcon;