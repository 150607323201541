import { FC, Dispatch, SetStateAction, ReactNode } from 'react';
import { Modal } from 'flowbite-react';
import { closeButton } from '../../../assets/images'

interface SideModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  title: string;
  children: ReactNode,
  footer?: ReactNode;
  dismissible?: boolean;
}

const DefaultSideModal: FC<SideModalProps> = ({ showModal, setShowModal, title, children, footer, dismissible = true }) => {
  return (
    <Modal
      show={showModal}
      onClose={() => setShowModal(false)}
      position="center"
      size="lg"
      className="!transform-none"
      dismissible={dismissible}
    >
      <div className="fixed inset-y-0 right-0 z-50 w-[448px] max-w-full overflow-hidden bg-white shadow-lg transform transition-all duration-500 ease-in-out">
        {/* Header */}
        <div className="flex items-center justify-between mx-6 py-4 border-b border-neutral-200">
          <h2 className="text-lg font-bold text-neutral-800">{title}</h2>
          <div
            className="cursor-pointer"
            onClick={() => setShowModal(false)}
          >
            <img src={closeButton} alt="close" />
          </div>
        </div>

        {/* Content */}
        <div 
          className='py-3.5 px-6 overflow-y-auto'
          style={{
            height: footer ? 'calc(100vh - 180px)' : 'calc(100vh - 112px)',
          }}
        >
          {children}
        </div>

        {/* Footer */}
        {footer && (
          <div className="border-t bg-white border-neutral-200 min-h-[100px] fixed bottom-0 w-full p-6 flex flex-col justify-center">
            {footer}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DefaultSideModal;
