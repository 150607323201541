import React, {
  createContext,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react';
import { useLocation } from "react-router-dom";
import {
  BookPackageProps,
  BookPackageOptionProps,
  BenefitsProps,
} from './types';
interface OfferProviderProps {
  children: React.ReactNode;
}

export interface OfferContextData {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
  templateId: string;
  setTemplateId: Dispatch<SetStateAction<string>>;
  offerId?: string;
  setOfferId?: Dispatch<SetStateAction<string>>;
  offerName?: string;
  setOfferName?: Dispatch<SetStateAction<string>>;
  offerCode?: string;
  setOfferCode?: Dispatch<SetStateAction<string>>;
  offerCountry?: string[];
  setOfferCountry?: Dispatch<SetStateAction<string[]>>;
  offerProduct?: string;
  setOfferProduct?: Dispatch<SetStateAction<string>>;
  productValueInCents?: number;
  setProductValueInCents?: Dispatch<SetStateAction<number>>;
  emphasisOptions?: BookPackageOptionProps[];
  setEmphasisOptions?: Dispatch<SetStateAction<BookPackageOptionProps[]>>;
  booksOptions?: BookPackageOptionProps[];
  setBooksOptions?: Dispatch<SetStateAction<BookPackageOptionProps[]>>;
  offerBookPackages?: BookPackageProps[];
  setOfferBookPackages?: Dispatch<SetStateAction<BookPackageProps[]>>;
  offerBookCategories?: BookPackageProps[];
  setOfferBookCategories?: Dispatch<SetStateAction<BookPackageProps[]>>;
  offerBookPackageNames?: string;
  setOfferBookPackageNames?: Dispatch<SetStateAction<string[]>>;
  offerTrialEnabled?: boolean;
  setOfferTrialEnabled?: Dispatch<SetStateAction<boolean>>;
  offerTrialPrice?: string;
  setOfferTrialPrice?: Dispatch<SetStateAction<string>>;
  offerTrialCredits?: number;
  setOfferTrialDuration?: Dispatch<SetStateAction<string>>;
  offerTrialDuration?: string;
  setOfferTrialCredits?: Dispatch<SetStateAction<number>>;
  productHeroImage: string;
  setProductHeroImage: Dispatch<SetStateAction<string>>;
  productBackgroundImage: string;
  setProductBackgroundImage: Dispatch<SetStateAction<string>>;
  productTitle: string;
  setProductTitle: Dispatch<SetStateAction<string>>;
  productDescription?: string;
  setProductDescription?: Dispatch<SetStateAction<string>>;
  benefitsTitle: string;
  setBenefitsTitle: Dispatch<SetStateAction<string>>;
  benefits: BenefitsProps[];
  setBenefits: Dispatch<SetStateAction<BenefitsProps[]>>;
  templateVariables: { handle: string; value: string; isObject?: boolean }[];
  setTemplateVariables: Dispatch<
    SetStateAction<{ handle: string; value: string; isObject?: boolean }[]>
  >;
  emphasis: string[];
  setEmphasis: Dispatch<SetStateAction<string[]>>;
  selectedTemplate: 'template1' | 'template2';
  setSelectedTemplate: Dispatch<SetStateAction<'template1' | 'template2'>>;
  defaultPackages: null | [];
  setDefaultPackages: Dispatch<SetStateAction<null | []>>;
  selectedBooks: string[];
  setSelectedBooks: Dispatch<SetStateAction<string[]>>;
  selectedPackageNames: string[];
  setSelectedPackageNames: Dispatch<SetStateAction<string[]>>;
  availableBookPackages: string[];
  setAvailableBookPackages: Dispatch<SetStateAction<string[]>>;
  allowedBookEmphasis: string[];
  setAllowedBookEmphasis: Dispatch<SetStateAction<string[]>>;
  bookIdPackages: null | string[];
  showOfferAndPrice?: boolean;
  setShowOfferAndPrice?: Dispatch<SetStateAction<boolean>>;
  showNextPaymentDate?: boolean;
  setShowNextPaymentDate?: Dispatch<SetStateAction<boolean>>;
  showCancellationDisclaimer?: boolean;
  setShowCancellationDisclaimer?: Dispatch<SetStateAction<boolean>>;
  showTermsOfService?: boolean;
  setShowTermsOfService?: Dispatch<SetStateAction<boolean>>;
  showAddCustom?: boolean;
  setShowAddCustom?: Dispatch<SetStateAction<boolean>>;
  addCustomText?: string;
  setAddCustomText?: Dispatch<SetStateAction<string>>;
  ctaButtonTitle: string;
  setCtaButtonTitle: Dispatch<SetStateAction<string>>;
  customScheduleHeader: string;
  setCustomScheduleHeader: Dispatch<SetStateAction<string>>;
  moveScheduleStepToWelcomeJourney: boolean;
  setMoveScheduleStepToWelcomeJourney: Dispatch<SetStateAction<boolean>>;
  productName: string;
  setProductName: Dispatch<SetStateAction<string>>;
  productId: string,
  setProductId: Dispatch<SetStateAction<string>>;
  productRenewInterval: string,
  setProductRenewInterval: Dispatch<SetStateAction<string>>;
  showPlanInformation?: boolean;
  setShowPlanInformation?: Dispatch<SetStateAction<boolean>>;
  offerIsBeingEdited: () => boolean,
}

export const OfferContext = createContext<Partial<OfferContextData>>({});

export const OfferProvider = ({ children }: OfferProviderProps) => {
  const location = useLocation();

  const [step, setStep] = useState<number>(1);
  const [templateId, setTemplateId] = useState<string>(
    '668b9ee38cd3037dded6d8fd',
  );

  const [offerId, setOfferId] = useState<string>('');
  const [offerName, setOfferName] = useState<string>('');
  const [offerCode, setOfferCode] = useState<string>('');
  const [offerCountry, setOfferCountry] = useState<string[]>([
    'Canada',
    'United States',
  ]);
  const [offerProduct, setOfferProduct] = useState<string>('');
  const [productValueInCents, setProductValueInCents] = useState<number>(0);
  const [offerBookCategories, setOfferBookCategories] = useState<
    BookPackageProps[]
  >([]);
  const [offerBookPackages, setOfferBookPackages] = useState<
    BookPackageProps[]
  >([]);
  const [offerBookPackageNames, setOfferBookPackageNames] =
    useState<string>('');
  const [offerTrialEnabled, setOfferTrialEnabled] = useState<boolean>(false);
  const [offerTrialPrice, setOfferTrialPrice] = useState<string>('');
  const [offerTrialDuration, setOfferTrialDuration] = useState<string>('');
  const [offerTrialCredits, setOfferTrialCredits] = useState<number | null>(
    null,
  );
  const [benefitsTitle, setBenefitsTitle] = useState<string>(
    'Here’s What You’ll Get',
  );
  const [benefits, setBenefits] = useState<BenefitsProps[]>([]);
  const [productHeroImage, setProductHeroImage] = useState<string>('');
  const [productBackgroundImage, setProductBackgroundImage] =
    useState<string>('');
  const [productTitle, setProductTitle] = useState<string>('');
  const [productId, setProductId] = useState<string>('')
  const [productDescription, setProductDescription] = useState<string>('');
  const [templateVariables, setTemplateVariables] = useState<
    { handle: string; value: string; isObject?: boolean }[]
  >([]);
  const [emphasis, setEmphasis] = useState<string[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<
    'template1' | 'template2'
  >('template1');
  const [emphasisOptions, setEmphasisOptions] = useState<
    BookPackageOptionProps[]
  >([]);
  const [booksOptions, setBooksOptions] = useState<BookPackageOptionProps[]>(
    [],
  );
  const [defaultPackages, setDefaultPackages] = useState<null | []>(null);
  const [bookIdPackages, setBookIdPackages] = useState<string[] | null>(null);
  const [availableBookPackages, setAvailableBookPackages] = useState<string[]>(
    [],
  );
  const [allowedBookEmphasis, setAllowedBookEmphasis] = useState<string[]>([]);
  const [selectedBooks, setSelectedBooks] = useState<string[]>([]);
  const [selectedPackageNames, setSelectedPackageNames] = useState<string[]>(
    [],
  );
  const [showOfferAndPrice, setShowOfferAndPrice] = useState<boolean>(true);
  const [showNextPaymentDate, setShowNextPaymentDate] =
    useState<boolean>(true);
  const [showCancellationDisclaimer, setShowCancellationDisclaimer] =
    useState<boolean>(true);
  const [showTermsOfService, setShowTermsOfService] = useState<boolean>(true);
  const [showAddCustom, setShowAddCustom] = useState<boolean>(false);
  const [addCustomText, setAddCustomText] = useState<string>('');
  const [ctaButtonTitle, setCtaButtonTitle] = useState<string>('Place Order');
  const [customScheduleHeader, setCustomScheduleHeader] = useState<string>(
    'Schedule your Coaching Call',
  );
  const [
    moveScheduleStepToWelcomeJourney,
    setMoveScheduleStepToWelcomeJourney,
  ] = useState<boolean>(false);

  const [productName, setProductName] = useState<string>('');
  const [productRenewInterval, setProductRenewInterval] = useState<string>('');

  const [showPlanInformation, setShowPlanInformation] = useState<boolean>(false);

  useEffect(() => {
    if (offerBookPackages && offerBookPackages.length > 0) {
      const bookIds = offerBookPackages.map(({ _id }) => _id);
      setBookIdPackages(bookIds);
    }
  }, [offerBookPackages]);

  useEffect(() => {
    if (selectedPackageNames.length > 0) {
      const bookIdEmphasis = availableBookPackages.flatMap((category) =>
        category.packages
          .filter((pkg) => selectedPackageNames.includes(pkg.name))
          .map((pkg) => pkg._id),
      );

      setEmphasis(bookIdEmphasis);
    }
  }, [offerBookPackageNames]);

  const offerIsBeingEdited = useCallback(
    () => location?.pathname?.includes('/edit'),
    [location?.pathname],
  );

  const contextData: OfferContextData = {
    step,
    setStep,
    templateId,
    setTemplateId,
    offerId,
    setOfferId,
    offerName,
    setOfferName,
    offerCode,
    setOfferCode,
    offerCountry,
    setOfferCountry,
    offerProduct,
    setOfferProduct,
    productValueInCents,
    setProductValueInCents,
    emphasisOptions,
    setEmphasisOptions,
    booksOptions,
    setBooksOptions,
    offerBookCategories,
    setOfferBookCategories,
    offerBookPackages,
    setOfferBookPackages,
    offerBookPackageNames,
    setOfferBookPackageNames,
    offerTrialEnabled,
    setOfferTrialEnabled,
    offerTrialPrice,
    setOfferTrialPrice,
    offerTrialDuration,
    setOfferTrialDuration,
    offerTrialCredits,
    setOfferTrialCredits,
    benefitsTitle,
    setBenefitsTitle,
    benefits,
    setBenefits,
    productBackgroundImage,
    setProductBackgroundImage,
    productHeroImage,
    setProductHeroImage,
    productTitle,
    setProductTitle,
    productDescription,
    setProductDescription,
    productId,
    setProductId,
    productRenewInterval,
    setProductRenewInterval,
    templateVariables,
    setTemplateVariables,
    emphasis,
    setEmphasis,
    selectedTemplate,
    setSelectedTemplate,
    defaultPackages,
    setDefaultPackages,
    availableBookPackages,
    setAvailableBookPackages,
    allowedBookEmphasis,
    setAllowedBookEmphasis,
    selectedBooks,
    setSelectedBooks,
    selectedPackageNames,
    setSelectedPackageNames,
    showOfferAndPrice,
    setShowOfferAndPrice,
    bookIdPackages,
    showNextPaymentDate,
    setShowNextPaymentDate,
    showCancellationDisclaimer,
    setShowCancellationDisclaimer,
    showTermsOfService,
    setShowTermsOfService,
    showAddCustom,
    setShowAddCustom,
    addCustomText,
    setAddCustomText,
    ctaButtonTitle,
    setCtaButtonTitle,
    customScheduleHeader,
    setCustomScheduleHeader,
    moveScheduleStepToWelcomeJourney,
    setMoveScheduleStepToWelcomeJourney,
    showPlanInformation,
    setShowPlanInformation,
    productName,
    setProductName,
    offerIsBeingEdited,
  };

  const memoizedContextData = useMemo(
    () => contextData,
    [
      step,
      templateId,
      offerName,
      offerCode,
      offerCountry,
      offerProduct,
      productValueInCents,
      emphasisOptions,
      booksOptions,
      offerBookCategories,
      offerBookPackages,
      offerTrialEnabled,
      offerTrialPrice,
      offerTrialDuration,
      offerTrialCredits,
      benefitsTitle,
      benefits,
      productBackgroundImage,
      productHeroImage,
      productTitle,
      productId,
      productRenewInterval,
      productDescription,
      templateVariables,
      emphasis,
      selectedTemplate,
      defaultPackages,
      selectedBooks,
      selectedPackageNames,
      availableBookPackages,
      allowedBookEmphasis,
      bookIdPackages,
      showOfferAndPrice,
      showNextPaymentDate,
      showCancellationDisclaimer,
      showTermsOfService,
      showAddCustom,
      addCustomText,
      ctaButtonTitle,
      customScheduleHeader,
      moveScheduleStepToWelcomeJourney,
      showPlanInformation,
      productName,
      offerIsBeingEdited,
    ],
  );

  return (
    <OfferContext.Provider value={memoizedContextData}>
      {children}
    </OfferContext.Provider>
  );
};
