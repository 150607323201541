import { AxiosResponse } from 'axios';
import { getProductCatalogInstance } from './instance';
import { ToastType } from '../../../utils/enums';
import { OfferCreationProps } from '../../pages/marketing/CreateOffer/utils/types';
import ToastNotification from '../../components/ToastNotification';
import Cookies from "js-cookie";

interface PaginatedInput {
  q?: string;
  productCatalogOnly?: boolean;
  page?: number;
  perPage?: number;
}

const productCatalogInstance = getProductCatalogInstance();

export async function getOffers({
  page,
  perPage,
  productCatalogOnly,
  q,
}: PaginatedInput) {
  try {
    const url = `offers?page=${
      page - 1
    }&perPage=${perPage}&productCatalogOnly=${productCatalogOnly}&q=${q}`;
    const { data } = await productCatalogInstance.get(url);
    return data;
  } catch (err) {
    console.error(err);
  }
}

export async function getProductFamilies() {
  try {
    const url = `product-families`;
    const { data } = await productCatalogInstance.get(url);
    return data;
  } catch (err) {
    console.error(err);
  }
}

export async function getProductPackages(productId: string) {
  try {
    const url = `packages?productId=${productId}`;
    const { data } = await productCatalogInstance.get(url);
    return data;
  } catch (err) {
    console.error(err);
  }
}

export async function getProducts({ page, perPage } = <PaginatedInput>{}) {
  const url = `products?page=${page}&perPage=${perPage}`;
  return productCatalogInstance
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => Promise.reject(error));
}

export const getUploadList = () => {
  const url = 'upload/list';
  return productCatalogInstance
    .get(url)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => Promise.reject(error));
};

export const getPreSignedUrl = async (
  fileName: string,
  fileExtension: string,
): Promise<AxiosResponse> => {
  try {
    const response = await productCatalogInstance.post(
      'upload/pre-signed-url',
      {
        fileName,
        fileExtension,
      },
    );
    return response;
  } catch (error) {
    console.error('Error in getPreSignedUrl:', error);
    throw error;
  }
};

export const previewTemplate = async (
  templateId: string,
  inputs: Record<string, string>,
): Promise<AxiosResponse> => {
  try {
    const formattedInputs = { ...inputs };
    if (inputs.benefits) {
      try {
        formattedInputs.benefits = JSON.stringify(JSON.parse(inputs.benefits));
      } catch (jsonError) {
        console.error('Error parsing or stringifying benefits:', jsonError);
        throw new Error('Invalid benefits format');
      }
    } else {
      formattedInputs.benefits = JSON.stringify([]);
    }
    const response = await productCatalogInstance.post('templates/preview', {
      id: templateId,
      inputs: formattedInputs,
    });
    return response;
  } catch (error) {
    console.error('Error in previewTemplate:', error);
    throw error;
  }
};

export const createOffer = async (offerData: OfferCreationProps) => {
  try {
    if (offerData.country) {
      offerData.country = offerData.country?.map((c) => {
        if (c === 'Canada') {
          return 'CA';
        }
        return 'US';
      });
    }
    const response = await productCatalogInstance.post('offers', offerData);
    return response;
  } catch (error) {
    console.error('Error in createOffer:', error);
    throw error;
  }
};

export const publishOffer = (offerCode: string) => {
  const url = `/offers/${offerCode}/publish`;
  return productCatalogInstance
    .post(url)
    .then((response) => {
      return response;
    })
    .catch((error) => Promise.reject(error));
};

export const updateOffer = async (updatePayload: {
  offerId: string,
  payload: Partial<OfferCreationProps>
}) => {
  const { offerId, payload } = updatePayload;

  const authToken = Cookies.get('app.idt');
  const userPayload = Cookies.get('user');
  const userPayloadAsString = decodeURIComponent(userPayload);

  try {
    await productCatalogInstance.patch(
      `/offer/${offerId}?apiKey=afy-api-key`,
      payload,
      { headers: { 'Authorization': authToken, 'x-user': btoa(userPayloadAsString) } },
    );

    ToastNotification({
      type: ToastType.SUCCESS,
      message: 'Offer updated successfully',
    });
  } catch (error) {
    ToastNotification({
      type: ToastType.ERROR,
      message: 'Error saving offer',
    });
  }
};

export const checkOfferCodeExists = async (offerCode: string) => {
  const url = `offers/${offerCode}`;
  try {
    const response = await productCatalogInstance.get(url);
    if (response.data && Object.keys(response.data).length > 0) {
      throw new Error('Offer code already exists');
    }
    return false;
  } catch (error) {
    console.error('Error in checkOfferCodeExists:', error);
    throw error;
  }
};

export const saveDraft = async ({
  name,
  template,
  templateVariables,
  valueInCents,
  offerCode,
  product,
  trialParameters,
  emphasis,
  packages,
  country,
}: {
  name: string;
  template: string;
  templateVariables: {
    handle: string;
    value: string;
    isObject?: boolean;
  }[];
  valueInCents: number;
  offerCode: string;
  product: string;
  trialParameters?: {
    priceInCents: number;
    credits: number;
    interval: number;
    intervalUnit: 'month' | 'day';
  };
  emphasis: string[];
  packages: string[];
  country: string[];
}) => {
  try {
    await createOffer({
      name,
      template,
      templateVariables,
      valueInCents,
      offerCode,
      product,
      trialParameters,
      emphasis,
      packages,
      country,
    });
    ToastNotification({
      type: ToastType.SUCCESS,
      message: 'Offer saved as draft successfully',
    });
  } catch (error) {
    ToastNotification({
      type: ToastType.ERROR,
      message: 'Error saving offer as draft',
    });
  }
};
