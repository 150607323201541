type Item = {
  _id: string;
  alias: string;
  name: string;
  category: string;
  status: string;
};

export const getUniqueCategories = (items: Item[]): string[] => {
  const categories = items.map(item => item.category);
  const uniqueCategories = Array.from(new Set(categories));

  return uniqueCategories.map(category => ({ name: category }));
}

export const getAllowedBookEmphasis = (books: string[], availableBooks) => {
    return availableBooks
        .map((category) => {
            const filteredPackages = category.packages.filter((pkg) =>
                books.includes(pkg.name)
            );

            return { ...category, packages: filteredPackages };
        })
        .filter((category) => category.packages.length > 0);
};

export const findOrCreateBookPackage = (name, prevPackages, availablePackages) => {
    const existingPackage = prevPackages.find((pkg) => pkg?.name === name);
    if (existingPackage) {
        return existingPackage;
    }
    for (let packages of availablePackages) {
        const newPackage = packages?.packages?.find((pkg) => pkg?.name === name);

        if (newPackage) {
            return newPackage;
        }
    }
    return null;
};

export const formatEmphatizedBooks = (offer, productPackagesData) => {
  const booksEmphatizeds = productPackagesData?.data?.reduce((acc, productPackage) => {
    const matchingPackages = productPackage?.packages?.filter(({ _id }) => offer.emphasis.includes(_id));
    const mappedPackages = matchingPackages.map(({ name }) => name);

    return [...acc, ...mappedPackages];
  }, []);

  return booksEmphatizeds;
};

export const offerIsEditable = (offer: any) => {
  return Boolean(
    offer?.isProductCatalog &&
    offer?.status === 'PUBLISHED'
  );
};
