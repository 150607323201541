import React, { useEffect, useState } from 'react';
import { useMOD } from '../context/MODContext';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../../../components/TextInput';
import PaginationFlowBite from '../../../../components/PaginationFlowBite';
import { format } from 'date-fns';
import {
  ShipmentIcon,
  PendingIcon,
  GreenCheck,
} from '../../../../../components/icons';
import SkeletonLoader from '../../../../components/SkeletonLoader';

const BatchListView: React.FC = () => {
  const navigate = useNavigate();
  const {
    batches,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    searchTerm,
    setSearchTerm,
    batchesTotal,
    isLoading,
    setStatusFilter,
    sortByfilter,
    setSortByfilter,
  } = useMOD();

  const [localStatusFilter, setLocalStatusFilter] = useState<
    'All' | 'Pending' | 'Uploaded'
  >('All');

  useEffect(() => {
    const status = localStatusFilter === 'All' ? '' : localStatusFilter;
    setStatusFilter(status);
  }, [localStatusFilter, setStatusFilter]);

  const handleBatchClick = (batchId: string) => {
    navigate(`/mail-on-demand/batch/${batchId}`);
  };

  const handlePerPage = (selectedOption: { value: number }) => {
    setPageSize(selectedOption.value);
    setCurrentPage(1);
  };

  const handleSort = (columnKey: string) => {
    setCurrentPage(1);
    if (sortByfilter.key === columnKey) {
      const isDesc = sortByfilter.value === 'desc';
      setSortByfilter({
        key: columnKey,
        value: isDesc ? 'asc' : 'desc',
      });
    } else {
      setSortByfilter({ key: columnKey, value: 'asc' });
    }
  };

  const totalPages = Math.ceil(batchesTotal / pageSize);

  return (
    <div className="bg-white rounded-lg shadow">
      <div className="px-6 py-[22px] border-b border-neutral-200">
        <div className="flex items-center gap-3.5">
          <TextInput
            id="search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search for batch number"
            width="374px"
          />
          <select
            value={localStatusFilter}
            onChange={(e) =>
              setLocalStatusFilter(e.target.value as typeof localStatusFilter)
            }
            className="h-[46px] block w-[184px] rounded-md border-0 py-3 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-primary-500 sm:text-sm sm:leading-6"
          >
            <option value="All">Status: All</option>
            <option value="Pending">Pending</option>
            <option value="Uploaded">Uploaded</option>
          </select>
        </div>
      </div>

      <div className="flex flex-col">
        {isLoading ? (
          <SkeletonLoader rows={pageSize} columns={4} />
        ) : (
          <table className="w-full">
            <thead>
              <tr className="bg-white">
                <th
                  className="w-[10%] px-6 py-3 border-b border-neutral-200 text-left cursor-pointer"
                  onClick={() => handleSort('id')}
                >
                  <span className="text-neutral-800 text-sm font-semibold flex items-center gap-1">
                    Batch#
                    {sortByfilter.key === 'id' && (
                      <span className="ml-1">
                        {sortByfilter.value === 'desc' ? '↓' : '↑'}
                      </span>
                    )}
                  </span>
                </th>
                <th
                  className="w-[15%] px-6 py-3 border-b border-neutral-200 text-left cursor-pointer"
                  onClick={() => handleSort('modOrderIds')}
                >
                  <span className="text-neutral-800 text-sm font-semibold flex items-center gap-1">
                    Number of shipments
                    {sortByfilter.key === 'modOrderIds' && (
                      <span className="ml-1">
                        {sortByfilter.value === 'desc' ? '↓' : '↑'}
                      </span>
                    )}
                  </span>
                </th>
                <th
                  className="w-[15%] px-6 py-3 border-b border-neutral-200 text-left cursor-pointer"
                  onClick={() => handleSort('createdAt')}
                >
                  <span className="text-neutral-800 text-sm font-semibold flex items-center gap-1">
                    Processed on
                    {sortByfilter.key === 'createdAt' && (
                      <span className="ml-1">
                        {sortByfilter.value === 'desc' ? '↓' : '↑'}
                      </span>
                    )}
                  </span>
                </th>
                <th
                  className="w-[15%] px-6 py-3 border-b border-neutral-200 text-left cursor-pointer"
                  onClick={() => handleSort('status')}
                >
                  <span className="text-neutral-800 text-sm font-semibold flex items-center gap-1">
                    Status
                    {sortByfilter.key === 'status' && (
                      <span className="ml-1">
                        {sortByfilter.value === 'desc' ? '↓' : '↑'}
                      </span>
                    )}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {batches.map((batch) => (
                <tr key={batch.id} className="border-b border-neutral-200">
                  <td className="px-6 py-3.5">
                    <span
                      className="text-neutral-800 text-sm font-semibold underline cursor-pointer"
                      onClick={() => handleBatchClick(batch.id)}
                    >
                      {batch.id}
                    </span>
                  </td>
                  <td className="px-6 py-3.5">
                    <div className="flex items-center gap-1">
                      <ShipmentIcon className="w-4 h-4" />
                      <span className="text-neutral-800 text-sm font-semibold">
                        {batch.modOrderIds.length}
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-3.5">
                    <span className="text-neutral-800 text-sm font-semibold">
                      {format(new Date(batch.createdAt), 'MMM d, yyyy')}
                    </span>
                  </td>
                  <td className="px-6 py-3.5">
                    {batch.status === 'Pending' ? (
                      <div className="px-2 py-1 bg-error-50 rounded-md inline-flex items-center gap-2">
                        <PendingIcon className="w-4 h-4" />
                        <span className="text-error-500 text-sm font-semibold">
                          Pending: Upload tracking file
                        </span>
                      </div>
                    ) : (
                      <div className="px-2 py-1 bg-success-50 rounded-md inline-flex items-center gap-2">
                        <GreenCheck className="w-4 h-4" />
                        <span className="text-success-500 text-sm font-semibold">
                          File uploaded
                        </span>
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <div className="px-6 py-[22px] border-t border-neutral-200">
          <PaginationFlowBite
            tablePagination={{
              pageSize,
              total: batchesTotal,
              lastPage: totalPages,
              currentPage,
              onChange: setCurrentPage,
            }}
            handlePerPage={handlePerPage}
          />
        </div>
      </div>
    </div>
  );
};

export default BatchListView;
