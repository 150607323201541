import React from 'react';
import classNames from 'classnames';
import { SvgCloseCircle, SvgCheckIcon } from '../../components/icons';

interface SwitchButtonProps {
  checked: boolean;
  setChecked?: (checked: boolean) => void;
  onChange?: (e: any) => void;
  className?: string;
  id?: string;
  label?: string;
  color?: string;
  hideCheckIcon?: boolean;
  disabled?: boolean;
}

const SwitchButton = ({
  checked,
  setChecked,
  onChange,
  className,
  id,
  label,
  color,
  hideCheckIcon = false,
  disabled = false,
}: SwitchButtonProps) => {
  const handleToggleButton = (event) => {
    if (disabled) return;

    setChecked && setChecked(!checked);

    if (onChange) {
      onChange(event);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleToggleButton(event);
    }
  };

  let bgColor;
  if (checked) {
    bgColor = color ? `bg-${color}`: 'bg-neutral-800';
  } else {
    bgColor = 'bg-toggleButtonBorder';
  }

  const iconFill = checked && color ? `text-${color}` : 'text-neutral-800';

  return (
    <div className={`flex items-center ${disabled ? '[&>*]:cursor-not-allowed' : '[&>*]:cursor-text'}`}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={handleToggleButton}
        className="hidden"
      />
      <div
        className={classNames(
          'w-10 h-6 rounded-full flex items-center cursor-pointer p-0.5 transition-colors duration-300',
          bgColor,
          className,
        )}
        onClick={handleToggleButton}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="switch"
        aria-checked={checked}
      >
        <div
          className={classNames(
            'w-4 h-4 flex justify-center items-center bg-white rounded-full transition-transform duration-300 transform',
            checked ? 'translate-x-5' : 'translate-x-0',
          )}
        >
          {checked && !hideCheckIcon ? (
            <SvgCheckIcon className={iconFill} width={12} height={12} />
          ) : (
            <SvgCloseCircle
              className="text-neutral-200"
              width={12}
              height={12}
            />
          )}
        </div>
      </div>
      {label && (
        <label className="ml-2 font-bold cursor-pointer" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default SwitchButton;
