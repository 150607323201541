import { boolean } from '../modules/utils/boolean';

export default {
  SCHEDULER_API: import.meta.env.SCHEDULER_API,
  FUSIONAUTH_ISSUER: import.meta.env.FUSIONAUTH_ISSUER,
  FUSIONAUTH_CLIENT_ID: import.meta.env.FUSIONAUTH_CLIENT_ID,
  FUSIONAUTH_CLIENT_SECRET: import.meta.env.FUSIONAUTH_CLIENT_SECRET,
  FUSIONAUTH_URL: import.meta.env.FUSIONAUTH_URL,
  FUSIONAUTH_TENANT_ID: import.meta.env.FUSIONAUTH_TENANT_ID,
  BASE_URL: import.meta.env.BASE_URL,
  DS_API_KEY: import.meta.env.DS_API_KEY,
  DIGITAL_SERVICES_URL: import.meta.env.DIGITAL_SERVICES_URL,
  DS_BUCKET: import.meta.env.VITE_DS_BUCKET,
  DATADOG_ENV: import.meta.env.VITE_DATADOG_ENV,
  DATADOG_APPLICATION_ID: import.meta.env.VITE_DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
  PRODUCT_CATALOG_URL: import.meta.env.VITE_PRODUCT_CATALOG_URL,
  PUBLISHED_OFFER_URL: import.meta.env.VITE_PUBLISHED_OFFER_URL,
  ENABLE_TEMPLATE_2: import.meta.env.VITE_ENABLE_TEMPLATE_2,
  ENABLE_MOD: boolean(import.meta.env.VITE_ENABLE_MOD),
  ENABLE_OFFER_STEPS_PREVIEW: boolean(import.meta.env.VITE_STEPS_OFFER_PREVIEW),
  THUMBNAIL_URL: import.meta.env.VITE_THUMBNAIL_URL,
};
