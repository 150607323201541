import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import env from '../../../../../../environments';
import {
  BackArrow,
  EditIcon,
  Reorder,
  TrashIcon,
  ArrowDown,
  ArrowUp,
} from '../../../../../../components/icons';
import {
  plusIcon,
  brushIcon,
  calendarIcon,
  magicStickIcon,
} from '../../../../../../assets/images';
import {
  Box,
  Button,
  DeleteModal,
  InputLabel,
  TextInput,
  SwitchButton,
} from '../../../../../components';
import BenefitModal from '../../../../../components/modals/BenefitModal';
import IconGalleryModal from '../../../../../components/modals/IconGalleryModal';
import UploadPhotoModal from '../../../../../components/modals/UploadPhotoModal';
import { OfferContext, OfferContextData } from '../../utils/OfferProvider';
import { BenefitsProps } from '../../utils/types';
import { templateConfig } from '../../utils/templateConfig';
import validationSchema from './validationSchema';
import {
  getUploadList,
  saveDraft,
} from '../../../../../api/productCatalog/actions';
import { Step1, Step2 } from '../../components/CustomizedSteps';

const DEFAULT_HERO_IMAGE_TEMPLATE1 =
  'https://afy-product-catalog-stg.s3.amazonaws.com/hero-images/template1-hero.png';
const DEFAULT_BACKGROUND_IMAGE_TEMPLATE2 =
  'https://afy-product-catalog-stg.s3.amazonaws.com/hero-images/template2-hero.png';
const DEFAULT_ICON_TEMPLATE2 =
  'https://afy-product-catalog-stg.s3.amazonaws.com/icons/benefits-check.svg';

const template2IsEnabled = env.ENABLE_TEMPLATE_2 === 'true';

const translateAdverb = (adverb: string) => adverb.replace('ly', '');

const SelectTemplate = () => {
  const {
    step,
    setStep,
    templateId,
    setTemplateId,
    offerName = '',
    offerCode = '',
    benefitsTitle,
    setBenefitsTitle,
    benefits,
    setBenefits,
    productId,
    productTitle,
    setProductTitle,
    productDescription,
    setProductDescription,
    productBackgroundImage,
    setProductBackgroundImage,
    productHeroImage,
    setProductHeroImage,
    offerTrialEnabled,
    offerTrialCredits,
    offerTrialPrice,
    offerTrialDuration,
    offerBookPackageNames,
    setTemplateVariables,
    productValueInCents,
    offerProduct,
    offerCountry,
    selectedTemplate,
    setSelectedTemplate,
    emphasis,
    bookIdPackages,
    selectedPackageNames,
    showOfferAndPrice,
    setShowOfferAndPrice,
    showNextPaymentDate,
    setShowNextPaymentDate,
    showCancellationDisclaimer,
    setShowCancellationDisclaimer,
    showTermsOfService,
    setShowTermsOfService,
    showAddCustom,
    setShowAddCustom,
    addCustomText,
    setAddCustomText,
    ctaButtonTitle,
    setCtaButtonTitle,
    customScheduleHeader,
    setCustomScheduleHeader,
    moveScheduleStepToWelcomeJourney,
    setMoveScheduleStepToWelcomeJourney,
    showPlanInformation,
    setShowPlanInformation,
    productName,
    productRenewInterval,
  } = useContext(OfferContext) as OfferContextData;

  const navigate = useNavigate();
  const [showAddBenefitModal, setShowAddBenefitModal] = useState(false);
  const [showEditBenefitModal, setShowEditBenefitModal] = useState(false);
  const [showDeleteBenefitModal, setShowDeleteBenefitModal] = useState(false);
  const [showIconGalleryModal, setShowIconGalleryModal] = useState(false);
  const [showUploadPhotoModal, setShowUploadPhotoModal] = useState(false);
  const [benefitToEdit, setBenefitToEdit] = useState<BenefitsProps | undefined>(
    undefined,
  );
  const [benefitToDelete, setBenefitToDelete] = useState<
    BenefitsProps | undefined
  >(undefined);
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [icon, setIcon] = useState<string>('');
  const [showCustomizeStep1, setShowCustomizeStep1] = useState(false);
  const [showCustomizeStep2, setShowCustomizeStep2] = useState(false);
  const [showCustomizeStep3, setShowCustomizeStep3] = useState(false);

  const template1Image =
    'https://afy-product-catalog-stg.s3.amazonaws.com/select-template-images/template1.png';
  const template2Image =
    'https://afy-product-catalog-stg.s3.amazonaws.com/select-template-images/template2.png';

  const queryClient = useQueryClient();
  const { data: uploadListData } = useQuery('uploadList', getUploadList);

  const handleAddBenefit = () => {
    const existingTrialBenefit = benefits.find(
      (benefit) => benefit.type === 'trial',
    );
    const existingBooksBenefit = benefits.find(
      (benefit) => benefit.type === 'books',
    );

    const permanentBenefits: BenefitsProps[] = [];

    if (existingTrialBenefit) {
      permanentBenefits.push(existingTrialBenefit);
    }
    if (existingBooksBenefit) {
      permanentBenefits.push(existingBooksBenefit);
    }

    const newBenefit: BenefitsProps = {
      title,
      description,
      icon: icon || DEFAULT_ICON_TEMPLATE2,
      position: benefits.length + 1,
    };

    const nonPermanentBenefits = benefits.filter(
      (benefit) => benefit.type !== 'trial' && benefit.type !== 'books',
    );

    setBenefits([...permanentBenefits, ...nonPermanentBenefits, newBenefit]);
    setShowAddBenefitModal(false);
  };

  const handleEditBenefit = () => {
    const updatedBenefit = {
      title,
      description,
      icon: icon || DEFAULT_ICON_TEMPLATE2,
      position: benefitToEdit?.position || 0,
      type: benefitToEdit?.type,
    };

    const updatedBenefits = benefits.map((benefit) =>
      benefit === benefitToEdit ? updatedBenefit : benefit,
    );

    setBenefits(updatedBenefits);
    setShowEditBenefitModal(false);
  };

  const handleDeleteBenefit = () => {
    if (benefitToDelete) {
      const updatedBenefits = benefits.filter(
        (benefit) => benefit !== benefitToDelete,
      );
      setBenefits(updatedBenefits);
      setShowDeleteBenefitModal(false);
    }
  };

  const handleSetBeenefits = (permanentBenefits: BenefitsProps[]) => {
    setBenefits((prevBenefits) => {
      const filteredBenefits = prevBenefits.filter(
        (benefit) => benefit.type !== 'trial' && benefit.type !== 'books',
      );
      return [...permanentBenefits, ...filteredBenefits];
    });
  };

  useEffect(() => {
    const updateBenefits = () => {
      const bookPackages = offerBookPackageNames?.map(
        (bookPackage) => bookPackage,
      );

      const separatedValues = bookPackages?.join(', ');
      const permanentBenefits: BenefitsProps[] = [];

      if (offerTrialCredits > 0) {
        permanentBenefits.push({
          title: `${offerTrialCredits} FREE personal branded books`,
          position: 1,
          type: 'trial',
        });
      }

      if (separatedValues) {
        permanentBenefits.push({
          title: `Access to ${separatedValues}`,
          position: 2,
          type: 'books',
        });
      }

      handleSetBeenefits(permanentBenefits);
    };

    if (offerBookPackageNames?.length > 0 || offerTrialEnabled) {
      updateBenefits();
    }
  }, [offerBookPackageNames, offerTrialEnabled, offerTrialCredits]);

  const handleShowImage = (handleBlur) => {
    const image =
      selectedTemplate === 'template1'
        ? productHeroImage
        : productBackgroundImage;
    return (
      <div className="flex items-center gap-3.5">
        <div className="w-[240px] h-[160px] border border-neutral-200 rounded-md">
          <img
            src={image}
            alt={selectedTemplate}
            className="w-[238px] h-[158px] object-cover rounded-md"
          />
        </div>
        <div className="flex items-center gap-3.5">
          <Button
            variant="OUTLINED"
            onClick={() => {
              setShowUploadPhotoModal(true);
            }}
            handleBlur={handleBlur}
          >
            Change
          </Button>
          <Button
            variant="OUTLINED"
            onClick={() => {
              if (selectedTemplate === 'template1') {
                setProductHeroImage('');
              } else if (selectedTemplate === 'template2') {
                setProductBackgroundImage('');
              }
            }}
          >
            Remove
          </Button>
        </div>
      </div>
    );
  };

  const handleIconChange = (url: string) => {
    setIcon(url);
    setShowIconGalleryModal(false);
  };

  const handleUploadPhoto = (url: string) => {
    if (selectedTemplate === 'template1') {
      setProductHeroImage(url);
    } else if (selectedTemplate === 'template2') {
      setProductBackgroundImage(url);
    }
    setShowUploadPhotoModal(false);
    queryClient.invalidateQueries('uploadList');
  };

  const handleUploadIcon = (url: string) => {
    setIcon(url);
    setShowIconGalleryModal(false);
  };

  const handleTemplateChange = (
    id: string,
    template: 'template1' | 'template2',
  ) => {
    setTemplateId(id);
    setSelectedTemplate(template);
  };

  const renderModalContent = () => (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-1">
        <div className="text-neutral-800 text-sm font-semibold leading-tight">
          Title
        </div>
        <div className="text-red-500 text-sm font-semibold leading-tight">
          *
        </div>
      </div>
      <TextInput
        value={title}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setTitle(e.target.value)
        }
        className="w-full"
        id={''}
      />
    </div>
  );

  const constructTemplateVariables = () => {
    const config = templateConfig[templateId];
    if (!config) return [];

    const finalizedOfferTrialPrice = offerTrialEnabled
      ? offerTrialPrice || '0'
      : '0';
    const finalizedOfferTrialDuration = offerTrialEnabled
      ? offerTrialDuration || '0'
      : '0';

    const planChargeFrequency = `$${productValueInCents}/${translateAdverb(productRenewInterval)}`

    const planInformation = {
      listTitle: `${productName} ${offerTrialEnabled && Number(offerTrialDuration) > 0 ? `($${offerTrialPrice} trial)` : ''}`.trim(),
      listItems: [
        offerTrialEnabled ? `${offerTrialPrice} today for the first ${offerTrialDuration} days` : `${planChargeFrequency} starting today`,
        offerTrialEnabled ? `After ${offerTrialDuration} days, renews at ${planChargeFrequency}` : `Renews ${productRenewInterval}`,
      ]
    };

    const context: Record<string, any> = {
      productBackgroundImage:
        productBackgroundImage || DEFAULT_BACKGROUND_IMAGE_TEMPLATE2,
      productHeroImage: productHeroImage || DEFAULT_HERO_IMAGE_TEMPLATE1,
      offerTrialCredits,
      offerTrialPrice: finalizedOfferTrialPrice,
      offerTrialDuration: finalizedOfferTrialDuration,
      productValueInCents,
      benefitsTitle: benefitsTitle,
      benefits: benefits.map((benefit) => ({
        ...benefit,
        icon: benefit.icon || DEFAULT_ICON_TEMPLATE2,
      })),
      productName,
      // Custom Express Onboarding
      // Step 1
      showOfferAndPrice,
      productTitle,
      productDescription,
      productId,
      showPlanInformation,
      planInformation,
      // Step 2
      showNextPaymentDate,
      showCancellationDisclaimer,
      showTermsOfService,
      showAddCustom,
      addCustomText,
      ctaButtonTitle,
      // Step 3
      customScheduleHeader,
      moveScheduleStepToWelcomeJourney,
    };

    context.offerTrialCredits = offerTrialCredits?.toString();
    context.trialOrOfferPrice = finalizedOfferTrialPrice;

    const variables = config.variables.map(
      ({ handle, contextKey, isObject }) => {
        let value = context[contextKey];

        if (contextKey === 'productValueInCents') {
          value = value;
        }

        return {
          handle,
          // The backend is only accepting string as value.
          value: isObject ? JSON.stringify(value) : value?.toString(),
          isObject,
        };
      },
    );

    return variables;
  };

  const handleSaveDraft = async () => {
    const variables = constructTemplateVariables();
    if (setTemplateVariables) {
      setTemplateVariables(variables);
    }
    await saveDraft({
      name: offerName,
      template: templateId,
      templateVariables: variables,
      valueInCents: productValueInCents,
      offerCode: offerCode,
      product: offerProduct,
      trialParameters: offerTrialEnabled
        ? {
          priceInCents: parseInt(offerTrialPrice) * 100,
          credits: +offerTrialCredits,
          interval: 30,
          intervalUnit: 'day',
        }
        : undefined,
      emphasis: emphasis,
      packages: bookIdPackages,
      country: offerCountry,
    });
  };

  const handleContinue = () => {
    const variables = constructTemplateVariables();
    if (setTemplateVariables) {
      setTemplateVariables(variables);
    }
    if (setStep) {
      setStep(step + 1);
    }
  };

  const initialValues = {
    productTitle: productTitle,
    productDescription: productDescription,
    benefits: JSON.stringify(benefits),
    heroImage: productHeroImage,
    backgroundImage: productBackgroundImage,
    templateId: templateId,
    ctaButtonTitle: ctaButtonTitle,
    addCustomText: addCustomText,
    customScheduleHeader: customScheduleHeader,
    benefitsTitle: benefitsTitle,
    showOfferAndPrice: showOfferAndPrice,
    showNextPaymentDate: showNextPaymentDate,
    showCancellationDisclaimer: showCancellationDisclaimer,
    showTermsOfService: showTermsOfService,
    showAddCustom: showAddCustom,
    showPlanInformation: showPlanInformation,
  };

  const renderChevron = (open) =>
    open ? (
      <ArrowUp aria-hidden="true" className="w-5 h-5" />
    ) : (
      <ArrowDown aria-hidden="true" className="w-5 h-5" />
    );

  return (
    <div className="pt-8 min-h-screen w-full">
      <button
        onClick={() => navigate('/offers')}
        className="flex gap-2 items-center px-2.5 py-2 mb-3.5"
      >
        <BackArrow />
        Offers
      </button>

      <Box className="flex items-center justify-between gap-5 px-6 py-3.5 mb-6">
        <div className="flex items-center gap-5">
          <div className="rounded-full flex justify-center items-center w-[30px] h-[30px] bg-success-500 text-white font-bold">
            {step - 1}
          </div>
          <div>
            <p className="font-semibold">Offer details</p>
            <p className="text-sm text-neutral-500">{offerName}</p>
          </div>
        </div>
        <Button
          variant="OUTLINED"
          className="w-[100px] fond-bold"
          onClick={() => setStep(step - 1)}
        >
          Edit
        </Button>
      </Box>

      <Box>
        <Formik
          initialValues={initialValues}
          onSubmit={handleContinue}
          validationSchema={validationSchema}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            errors,
            touched,
            isValid,
          }) => {
            const isDisabled = !isValid;

            return (
              <Form onSubmit={handleSubmit}>
                <div className="px-6 py-[22px] border-b border-neutral-200 font-semibold flex items-center gap-4 leading-5">
                  <div
                    className={classNames(
                      'rounded-full flex justify-center items-center w-[30px] h-[30px]',
                      isDisabled
                        ? 'bg-neutral-200 '
                        : 'bg-success-500 text-white',
                    )}
                  >
                    {step}
                  </div>
                  <div>
                    <p className="font-semibold">Select template</p>
                    <p className="text-sm text-neutral-500 font-normal">
                      Choose a template for this offer
                    </p>
                  </div>
                </div>
                <div>
                  <div>
                    <div>
                      <div>
                        <div className="p-6 bg-neutral-50 border-b border-neutral-200 flex flex-wrap items-center max-md:justify-center gap-6 mb-6">
                          <button
                            type="button"
                            className={classNames(
                              'rounded-lg p-0.5 ease-out duration-200',
                              selectedTemplate === 'template1'
                                ? 'border-2 border-primary-500'
                                : 'hover:border-2 hover:border-primary-500',
                            )}
                            onClick={() =>
                              handleTemplateChange(
                                '668b9ee38cd3037dded6d8fd',
                                'template1',
                              )
                            }
                          >
                            <img
                              src={template1Image}
                              alt="template1"
                              className="rounded-md"
                            />
                          </button>
                          {template2IsEnabled && (
                            <button
                              type="button"
                              className={classNames(
                                'rounded-lg p-0.5 ease-out duration-200',
                                selectedTemplate === 'template2'
                                  ? 'border-2 border-primary-500'
                                  : 'hover:border-2 hover:border-primary-500',
                              )}
                              onClick={() =>
                                handleTemplateChange(
                                  '668bacbd64df85d2005fc180',
                                  'template2',
                                )
                              }
                            >
                              <img
                                src={template2Image}
                                alt="template2"
                                className="rounded-md"
                              />
                            </button>
                          )}
                        </div>

                        <div className="p-6">
                          <InputLabel
                            label={
                              selectedTemplate === 'template1'
                                ? 'Hero image'
                                : 'Hero background image'
                            }
                            required
                            className="mb-3.5"
                          />
                          {(selectedTemplate === 'template1' &&
                            !(productHeroImage.trim() === '')) ||
                            (selectedTemplate === 'template2' &&
                              !(productBackgroundImage.trim() === '')) ? (
                            handleShowImage(handleBlur)
                          ) : (
                            <Button
                              variant="OUTLINED"
                              className="w-full max-w-[514px] fond-bold"
                              onClick={() => {
                                setShowUploadPhotoModal(true);
                              }}
                              onBlur={handleBlur}
                            >
                              Upload photo
                            </Button>
                          )}

                          <div className="flex flex-col gap-3.5 mt-6">
                          <InputLabel
                            label="Benefits Title"
                            required
                          />
                            <TextInput
                              id="benefitsTitle"
                              multiline
                              onChange={(element) => {
                                handleChange(element);
                                setBenefitsTitle(element.target.value);
                              }}
                              onBlur={handleBlur}
                              value={values.benefitsTitle}
                              className="h-[62px]"
                            />

                          <InputLabel
                            label="Benefits List"
                            required
                            className='pt-2.5'
                          />
                            {benefits.map((benefit) => (
                              <div
                                key={`${benefit.title}-${benefit.position}`}
                                className="p-3.5 w-full text-sm border border-neutral-200 rounded-md flex gap-2 items-center justify-between"
                              >
                                <div className="flex gap-2 items-center">
                                  <Reorder />
                                  <div>
                                    <p className="font-bold">{benefit.title}</p>
                                    <p className="font-medium text-neutral-500">
                                      {benefit.description}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex items-center justify-center border border-neutral-200 rounded-md">
                                  <button
                                    type="button"
                                    className="p-2 cursor-pointer w-8 h-8"
                                    onClick={() => {
                                      setBenefitToEdit(benefit);
                                      setTitle(benefit.title);
                                      setDescription(benefit.description || '');
                                      setIcon(benefit.icon || '');
                                      setShowEditBenefitModal(true);
                                    }}
                                  >
                                    <EditIcon />
                                  </button>
                                  {benefit.type !== 'trial' &&
                                    benefit.type !== 'books' && (
                                      <button
                                        type="button"
                                        className="border-l border-neutral-200 cursor-pointer"
                                        onClick={() => {
                                          setBenefitToDelete(benefit);
                                          setShowDeleteBenefitModal(true);
                                        }}
                                      >
                                        <TrashIcon className="rounded-md" />
                                      </button>
                                    )}
                                </div>
                              </div>
                            ))}
                            <Button
                              variant="OUTLINED"
                              onClick={() => {
                                setTitle('');
                                setDescription('');
                                setIcon('');
                                setShowAddBenefitModal(true);
                              }}
                              className="border-dotted border-2 border-neutral-200 w-full flex"
                            >
                              <img src={plusIcon} alt="plus" className="mr-2" />
                              <span>Add more</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div>
                    <div>
                      <div
                        className="flex justify-between p-6 border-t border-neutral-200 cursor-pointer"
                        onClick={() =>
                          setShowCustomizeStep1(!showCustomizeStep1)
                        }
                      >
                        <div className="flex item-center gap-6">
                          <div>
                            <img src={magicStickIcon} alt="magic-stick" />
                          </div>
                          <div className={classNames(
                            'font-semibold ease-in-out',
                            showCustomizeStep1 ? 'text-neutral-800' : 'text-neutral-500',
                          )}>Customize Step 1 (Account)</div>
                        </div>
                        {renderChevron(showCustomizeStep1)}
                      </div>
                      {showCustomizeStep1 && (
                        <div className="flex w-full max-sm:flex-wrap">
                          <div className="p-6 border-t min-w-[250px] w-3/6 max-sm:w-full border-neutral-200">
                            <div className="flex gap-3.5 p-3.5 pr-6 bg-neutral-50 border border-neutral-200 rounded-md w-full mb-4">
                              <SwitchButton
                                checked={!!showPlanInformation}
                                onChange={() =>
                                  setShowPlanInformation(!showPlanInformation)
                                }
                                color="primary-500"
                                id='showPlanInformation'
                              />
                              <span className="text-sm font-semibold">
                                Show Plan Information
                              </span>
                            </div>

                            <div className="flex gap-2 p-2 pr-6 bg-neutral-50 border border-neutral-200 rounded-md w-full mb-4">
                              <div className={`pl-1 ${showOfferAndPrice ? 'mt-3 self-start' : 'self-center'}`}>
                                <SwitchButton
                                  checked={!!showOfferAndPrice}
                                  onChange={() =>
                                    setShowOfferAndPrice(!showOfferAndPrice)
                                  }
                                  color="primary-500"
                                  id='showOfferAndPrice'
                                />
                              </div>
                              <div className="flex flex-col gap-6 w-full p-3.5">
                                <span className="text-sm font-semibold">Show Offer and Pricing Disclaimer</span>
                                {showOfferAndPrice && (
                                  <>
                                    <div>
                                      <InputLabel label="Offer title" />
                                      <TextInput
                                        id="productTitle"
                                        multiline={
                                          selectedTemplate === 'template2'
                                        }
                                        onChange={(element) => {
                                          handleChange(element);
                                          setProductTitle(element.target.value);
                                        }}
                                        placeholder="Optional"
                                        onBlur={handleBlur}
                                        value={values.productTitle}
                                      />
                                    </div>
                                    {selectedTemplate === 'template1' && (
                                      <div>
                                        <InputLabel label="Offer description" />
                                        <TextInput
                                          type="text"
                                          id="productDescription"
                                          onChange={(element) => {
                                            handleChange(element);
                                            setProductDescription(
                                              element.target.value,
                                            );
                                          }}
                                          onBlur={handleBlur}
                                          value={values.productDescription}
                                          placeholder="Optional"
                                        />
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                            <div className="flex gap-3.5 p-3.5 pr-6 bg-neutral-50 border border-neutral-200 rounded-md w-full">
                              <SwitchButton
                                checked={!!showTermsOfService}
                                onChange={() =>
                                  setShowTermsOfService(!showTermsOfService)
                                }
                                color="primary-500"
                                id='showTermsOfService'
                              />
                              <span className="text-sm font-semibold">
                                Show Terms of Service and Privacy Policy
                              </span>
                            </div>
                          </div>

                          <div className="p-6 bg-neutral-50 min-w-[250px] w-3/6 max-sm:w-full border-t border-neutral-200  flex items-center">
                            <Step1 />
                          </div>
                        </div>
                      )}
                    </div>
                    <div></div>
                    <div>
                      <div
                        className="flex justify-between p-6 border-t border-neutral-200 cursor-pointer"
                        onClick={() =>
                          setShowCustomizeStep2(!showCustomizeStep2)
                        }
                      >
                        <div className="flex item-center gap-6">
                          <div>
                            <img src={brushIcon} alt="brush" />
                          </div>
                          <div className={classNames(
                            'font-semibold ease-in-out',
                            showCustomizeStep2 ? 'text-neutral-800' : 'text-neutral-500',
                          )}>Customize Step 2 (Payment and order)</div>
                        </div>
                        {renderChevron(showCustomizeStep2)}
                      </div>
                      {showCustomizeStep2 && (
                        <div className="flex w-full max-sm:flex-wrap">
                          <div className="p-6 border-t min-w-[250px] w-3/6 max-sm:w-full border-neutral-200 flex flex-col gap-3.5">
                            <div className="flex gap-3.5 p-3.5 pr-6 bg-neutral-50 border border-neutral-200 rounded-md w-full">
                              <SwitchButton
                                checked={!!showNextPaymentDate}
                                onChange={() =>
                                  setShowNextPaymentDate(!showNextPaymentDate)
                                }
                                color="primary-500"
                                id='showNextPaymentDate'
                              />
                              <span className="text-sm font-semibold">
                                Show Next Payment Date
                              </span>
                            </div>

                            <div className="flex gap-3.5 p-3.5 pr-6 bg-neutral-50 border border-neutral-200 rounded-md w-full">
                              <SwitchButton
                                checked={!!showCancellationDisclaimer}
                                onChange={() =>
                                  setShowCancellationDisclaimer(
                                    !showCancellationDisclaimer,
                                  )
                                }
                                color="primary-500"
                                id='showCancellationDisclaimer'
                              />
                              <span className="text-sm font-semibold">
                                Show Cancellation Disclaimer
                              </span>
                            </div>

                            <div className="flex items-start gap-3.5 p-3.5 pr-6 bg-neutral-50 border border-neutral-200 rounded-md w-full">
                              <SwitchButton
                                checked={!!showAddCustom}
                                onChange={() =>
                                  setShowAddCustom(!showAddCustom)
                                }
                                color="primary-500"
                                id='showAddCustom'
                              />
                              <div className="w-full">
                                <span className="text-sm font-semibold">
                                  Add Custom
                                </span>
                                {showAddCustom && (
                                  <TextInput
                                    id="addCustomText"
                                    multiline
                                    onChange={(element) => {
                                      handleChange(element);
                                      setAddCustomText(element.target.value);
                                    }}
                                    placeholder="Type your custom disclaimer"
                                    onBlur={handleBlur}
                                    value={values.addCustomText}
                                    className="min-h-[70px] w-full"
                                  />
                                )}
                              </div>
                            </div>

                            <div className="h-[1px] border-t border-neutral-200 my-1.5" />

                            <div>
                              <InputLabel label="CTA button label" required />
                              <TextInput
                                type="text"
                                id="ctaButtonTitle"
                                onChange={(element) => {
                                  handleChange(element);
                                  setCtaButtonTitle(element.target.value);
                                }}
                                onBlur={handleBlur}
                                value={values.ctaButtonTitle}
                              />
                            </div>
                          </div>
                          <div className="p-6 bg-neutral-50 min-w-[250px] w-3/6 max-sm:w-full border-t border-neutral-200  flex items-center">
                            <Step2 />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div>
                      <div
                        className="flex justify-between p-6 border-t border-neutral-200 cursor-pointer"
                        onClick={() =>
                          setShowCustomizeStep3(!showCustomizeStep3)
                        }
                      >
                        <div className="flex item-center gap-6">
                          <div>
                            <img src={calendarIcon} alt="calendar" />
                          </div>
                          <div className={classNames(
                            'font-semibold ease-in-out',
                            showCustomizeStep3 ? 'text-neutral-800' : 'text-neutral-500',
                          )}>
                            Customize Step 3 (Schedule your Coaching Call)
                          </div>
                        </div>
                        {renderChevron(showCustomizeStep3)}
                      </div>
                      {showCustomizeStep3 && (
                        <div className="w-full p-6 border-t border-neutral-200">
                          <div className="w-full mb-5">
                            <InputLabel label="Custom header title" required />
                            <TextInput
                              id="customScheduleHeader"
                              multiline
                              onChange={(element) => {
                                handleChange(element);
                                setCustomScheduleHeader(element.target.value);
                              }}
                              onBlur={handleBlur}
                              value={values.customScheduleHeader}
                            />
                            {errors.customScheduleHeader &&
                              touched.customScheduleHeader && (
                                <div className="text-error-500 text-sm">
                                  {errors.customScheduleHeader}
                                </div>
                              )}
                          </div>
                          <div className="flex gap-3.5 p-3.5 pr-6 bg-neutral-50 border border-neutral-200 rounded-md w-full">
                            <SwitchButton
                              checked={!!moveScheduleStepToWelcomeJourney}
                              onChange={() =>
                                setMoveScheduleStepToWelcomeJourney(
                                  !moveScheduleStepToWelcomeJourney,
                                )
                              }
                              color="primary-500"
                            />
                            <span className="text-sm font-semibold">
                              Move this step to Welcome Journey
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div></div>
                  </div>
                </div>

                <div className="px-6 py-[22px] border-t border-neutral-200 flex gap-2">
                  {/* <Button
                      variant="OUTLINED"
                      onClick={handleSaveDraft}
                      disabled={isDisabled}
                    >
                      Save as draft
                    </Button> */}
                  <Button
                    type="submit"
                    disabled={isDisabled}
                    className="w-[140px]"
                  >
                    Preview
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Box>

      <BenefitModal
        show={showAddBenefitModal}
        onClose={() => setShowAddBenefitModal(false)}
        onSave={handleAddBenefit}
        isEdit={false}
        description={description}
        setDescription={setDescription}
        hasIcon={selectedTemplate === 'template2'}
        title="Add Benefit"
        icon={icon}
        setIcon={setIcon}
        benefitTitle={title}
        setBenefitTitle={setTitle}
      >
        {renderModalContent()}
      </BenefitModal>
      <BenefitModal
        show={showEditBenefitModal}
        onClose={() => setShowEditBenefitModal(false)}
        onSave={handleEditBenefit}
        initialBenefit={benefitToEdit}
        isEdit={true}
        description={description}
        setDescription={setDescription}
        hasIcon={selectedTemplate === 'template2'}
        title="Edit Benefit"
        icon={icon}
        setIcon={setIcon}
        benefitTitle={title}
        setBenefitTitle={setTitle}
      >
        {renderModalContent()}
      </BenefitModal>
      <DeleteModal
        title="Delete permanently"
        showModal={showDeleteBenefitModal}
        setShowModal={() => setShowDeleteBenefitModal(false)}
        handleDelete={handleDeleteBenefit}
        deleteButton="Delete"
      >
        <div>
          <p className="text-sm font-medium font-figtree">
            Are you sure you want to delete this from the list? Deleting this
            from the list is permanent and cannot be undone.
          </p>
        </div>
      </DeleteModal>
      <UploadPhotoModal
        show={showUploadPhotoModal}
        onClose={() => setShowUploadPhotoModal(false)}
        onUpload={handleUploadPhoto}
        photoGalleryList={uploadListData || null}
      />
      <IconGalleryModal
        show={showIconGalleryModal}
        onClose={() => setShowIconGalleryModal(false)}
        onSelectIcon={handleIconChange}
        onUploadIcon={handleUploadIcon}
      />
    </div>
  );
};

export default SelectTemplate;
