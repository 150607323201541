import React from "react";

const WorkflowIcon = ({ fill = "#252525", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path d="M18.3333 11.6654V9.9987C18.3333 9.2995 18.3333 8.67809 18.3226 8.1237H1.67742C1.66667 8.67809 1.66667 9.2995 1.66667 9.9987V11.6654C1.66667 14.8081 1.66667 16.3794 2.64298 17.3557C3.61929 18.332 5.19064 18.332 8.33333 18.332H11.6667C14.8094 18.332 16.3807 18.332 17.357 17.3557C18.3333 16.3794 18.3333 14.8081 18.3333 11.6654Z" fill={fill}/>
      <path d="M6.45833 2.08203C6.45833 1.73685 6.17851 1.45703 5.83333 1.45703C5.48816 1.45703 5.20833 1.73685 5.20833 2.08203V3.39808C4.00889 3.49413 3.22148 3.72984 2.64298 4.30834C2.06448 4.88684 1.82876 5.67426 1.73272 6.8737H18.2673C18.1712 5.67426 17.9355 4.88684 17.357 4.30834C16.7785 3.72984 15.9911 3.49413 14.7917 3.39808V2.08203C14.7917 1.73685 14.5118 1.45703 14.1667 1.45703C13.8215 1.45703 13.5417 1.73685 13.5417 2.08203V3.34278C12.9873 3.33203 12.3659 3.33203 11.6667 3.33203H8.33333C7.63414 3.33203 7.01272 3.33203 6.45833 3.34278V2.08203Z" fill={fill}/>
    </svg>
  );
};

export default WorkflowIcon;