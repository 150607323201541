/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { ListingDetailsComponent, QRCodeComponent, RenderOptionsComponent, SingleInputComponent } from "../StepsComponent";

import Button from "../../../../../../components/Button";
import { backCoverStripOptionsHeading } from "./constants";
import { CoverOptionType, ObjectStringType, ObjectType, SelectionsType } from "../../types";
import { useMagazineImageContext } from "../../../../../../../context/MagazineImageHook";
import { MagazineImageContextType } from "../../../../../../../context/MagazineImageContext";
import { MagazineFromDBContext } from "../../../../../../../context/MagazineFromDBContext";
import { convertHtmlListToText, fnConvertListToText } from "../../../utils";

const BackCover = ({ backCoverList, updateMagazineData, handleImageLightBox }) => {
  const { magazine = {}, handleUpdateMagazine, magazineDataFormik, previousMagazineData, setFieldValue } = updateMagazineData || {};

  const selectedCoverStripOption = get(magazine, "backCover");

  const lastSelectedCoverStripOption = useRef(selectedCoverStripOption?.formKeyword);
  const { setImageType } = useMagazineImageContext() as MagazineImageContextType;
  const { ctxMagazineFromDB } = React.useContext(MagazineFromDBContext);

  const objectResponse: any = ctxMagazineFromDB[0];
  const { selections }: SelectionsType = objectResponse as SelectionsType;
  const selectedCoverOption = get(magazine, "backCover");

  /**
   * Set initial value of selected option
   * Update magazineDataFormik values on selected option change
   */
  useEffect(() => {
    const currentSelectedOption = get(selectedCoverStripOption, "formKeyword");
    const lastSelectedOption = get(lastSelectedCoverStripOption, "current");

    if (lastSelectedOption !== currentSelectedOption) {
      const coverSelection = selections.find((selection: ObjectType) => selection.page === 4);
      if (!isEmpty(coverSelection)) {
        coverSelection.dynamicFields.map((field: ObjectType) => {
          const { keyword, value } = field;
          setFieldValue(`backCover._formFields.${keyword}`, value);
        });
      }
    }
  }, [selectedCoverStripOption]);

  useEffect(() => {
    if (!isEmpty(ctxMagazineFromDB)) {
      const coverSelection = selections.find((selection: ObjectType) => selection.page === 4);
      if (!isEmpty(coverSelection)) {
        coverSelection.dynamicFields.map((field: ObjectType) => {
          const { keyword, value } = field;
          setFieldValue(`backCover._formFields.${keyword}`, value);
        });
      }
    }
  }, [ctxMagazineFromDB]);

  useEffect(() => {
    setFieldValue("backCover.selectedOption", selectedCoverOption?.formKeyword);
    const fields = convertHtmlListToText(selectedCoverOption, lastSelectedCoverStripOption, ctxMagazineFromDB);
    if (fields.length > 0) {
      setFieldValue(
        "backCover._formFields",
        fields.reduce((acc, item) => ({ ...acc, ...item }), {})
      );
    }
  }, []);

  /**
   * Update the selected option
   *
   * @param option - CoverOptionType : Selected option
   */
  const handleCoverStripOptionChange = (option: CoverOptionType) => {
    const optionName = option.name.replace(" ", "");
    setImageType(`backCoverImage${optionName}`);
    setFieldValue("backCover.selectedOption", option.formKeyword);
    handleUpdateMagazine(option, "backCover");
  };

  /**
   * Reset the form fields to previous values
   * If magazine data is present in context, set the values from context
   * if previous magazine data is present, set the values from previous data
   */
  const onHandleResetFormFields = () => {
    if (!isEmpty(previousMagazineData)) {
      const insideCoverContent = previousMagazineData.selections.find((selection: ObjectType) => selection.page === 4);
      const { formKeyword } = insideCoverContent;
      const regex = /option-(\d)/;
      const match = formKeyword.match(regex);
      if (match.length > 0) {
        const optionSelected = match[0].replace("-", " ");
        const option = backCoverList.find((opt: ObjectStringType) => opt.name?.toLowerCase() === optionSelected.toLowerCase());
        handleUpdateMagazine(option, "backCover");
      }
    } else {
      const initialValueOfSelctedOptionForm = backCoverList.find((item: ObjectType) => item.name === "Option 1");
      handleUpdateMagazine(initialValueOfSelctedOptionForm, "backCover");
    }
    setFieldValue("currentStep", -1);
  };

  if (selectedCoverStripOption === undefined) return;

  const allowedResolutionsTextBackCover3 = { width: 600, height: 960 };
  const allowedResolutionsTextBackCover4 = { width: 960, height: 750 };

  const renderAdditionalData = (type) => {
    switch (type) {
      case "backCover-option-3":
        return (
          <SingleInputComponent
            imageDimensions={allowedResolutionsTextBackCover3}
            step="backCover"
            formikHandler={magazineDataFormik}
            uploadImageTitle="Your book image"
            propertyKey="bookImage"
            uploadImageType="backCoverImageOption3"
            presentImageLocation={get(magazineDataFormik, "values.backCover._formFields.bookImage")}
          />
        );
      case "backCover-option-2":
        return <QRCodeComponent formikHandler={magazineDataFormik} step="backCover" />;
      case "backCover-option-4":
        return (
          <ListingDetailsComponent
            imageDimensions={allowedResolutionsTextBackCover4}
            step="backCover"
            hasInputFields
            hasTextArea={false}
            formikHandler={magazineDataFormik}
            uploadImageType="backCoverImageOption4"
            propertyKey="listingImageJustSold"
            presentImageLocation={get(magazineDataFormik, "values.backCover._formFields.listingImageJustSold")}
          />
        );
      case "backCover-option-5":
        return (
          <ListingDetailsComponent
            imageDimensions={allowedResolutionsTextBackCover4}
            step="backCover"
            hasInputFields
            hasListingName
            hasTextArea
            formikHandler={magazineDataFormik}
            propertyKey="listingImageJustListed"
            presentImageLocation={get(magazineDataFormik, "values.backCover._formFields.listingImageJustListed")}
            uploadImageType="backCoverImageOption5"
          />
        );
      default:
        return null;
    }
  };

  const selectedCoverStripOptionName = get(selectedCoverStripOption, "name");
  const selectedOptionTitle = backCoverStripOptionsHeading[get(selectedCoverStripOption, "formKeyword")];

  return (
    <>
      <div className="mt-8 flex flex-col gap-6">
        <div className="w-full flex flex-col">
          <h5 className="text-neutral-800 text-sm font-semibold">Select back cover</h5>
          <RenderOptionsComponent onImageClick={handleImageLightBox} optionsList={backCoverList} handleChange={handleCoverStripOptionChange} selectedOption={selectedCoverStripOption} />
        </div>
        <div className="text-neutral-800 text-base font-semibold">
          {selectedOptionTitle && `${selectedCoverStripOptionName}: ${selectedOptionTitle}`}
          {selectedCoverStripOptionName.toLowerCase() === "option 2" && (
            <div className="overflow-hidden text-neutral-400 text-ellipsis  text-sm font-medium">A special QR code will generate once the order has been placed.</div>
          )}
        </div>
        <div className="w-full">{renderAdditionalData(selectedCoverStripOption?.formKeyword)}</div>
        <div className="flex justify-start w-full gap-2">
          <Button variant="LIGHT" onClick={onHandleResetFormFields}>
            Cancel
          </Button>
          <Button variant="DARK" type="submit" onClick={magazineDataFormik.handleSubmit}>
            Save changes
          </Button>
        </div>
      </div>
    </>
  );
};

export default BackCover;
