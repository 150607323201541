import { OfferContextData } from './OfferProvider';

interface TemplateConfig {
  [key: string]: {
    variables: {
      handle: string;
      contextKey: keyof OfferContextData;
      isObject?: boolean;
    }[];
  };
}

export const templateConfig: TemplateConfig = {
  '668b9ee38cd3037dded6d8fd': {
    variables: [
      { handle: 'trialOrOfferPrice', contextKey: 'trialOrOfferPrice' },
      { handle: 'offerPrice', contextKey: 'productValueInCents' },
      { handle: 'offerTrialDuration', contextKey: 'offerTrialDuration' },
      { handle: 'offerTrialFreeBooks', contextKey: 'offerTrialCredits' },
      { handle: 'benefitsTitle', contextKey: 'benefitsTitle' },
      { handle: 'benefits', contextKey: 'benefits', isObject: true },
      { handle: 'productHeroImage', contextKey: 'productHeroImage' },
      { handle: 'showPlanInformation', contextKey: 'showPlanInformation' },
      { handle: 'planInformation', contextKey: 'planInformation' as any, isObject: true },
      { handle: 'showOfferAndPrice', contextKey: 'showOfferAndPrice' },
      { handle: 'offerTitle', contextKey: 'productTitle' },
      { handle: 'offerDescription', contextKey: 'productDescription' },
      { handle: 'showNextPaymentDate', contextKey: 'showNextPaymentDate' },
      {
        handle: 'showCancellationDisclaimer',
        contextKey: 'showCancellationDisclaimer',
      },
      { handle: 'showTermsOfService', contextKey: 'showTermsOfService' },
      { handle: 'showAddCustom', contextKey: 'showAddCustom' },
      { handle: 'addCustomText', contextKey: 'addCustomText' },
      { handle: 'ctaButtonTitle', contextKey: 'ctaButtonTitle' },
      { handle: 'customScheduleHeader', contextKey: 'customScheduleHeader' },
      {
        handle: 'moveScheduleStepToWelcomeJourney',
        contextKey: 'moveScheduleStepToWelcomeJourney',
      },
      { handle: 'productName', contextKey: 'productName' },
      { handle: 'productId', contextKey: 'productId' },
    ],
  },
  '668bacbd64df85d2005fc180': {
    variables: [
      { handle: 'heroTitle', contextKey: 'productTitle' },
      { handle: 'benefits', contextKey: 'benefits', isObject: true },
      {
        handle: 'productBackgroundImage',
        contextKey: 'productBackgroundImage',
      },
    ],
  },
};
