import * as React from 'react';
import type { SVGProps } from 'react';
const SuccessCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={34}
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={5}
      d="m7.3 18.7 5.937 6.8 14.841-17"
    />
  </svg>
);
export default SuccessCheck;
