/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useContext } from "react";
import get from "lodash/get";
import Button from "../../../../../../components/Button";
import isEmpty from "lodash/isEmpty";
import { ListingDetailsComponent, ReferralPartnerComponent, RenderOptionsComponent, TestimonialsComponent } from "../StepsComponent";
import { MagazineFromDBContext, MagazineFromDBContextType } from "../../../../../../../context/MagazineFromDBContext";
import { backInsideCoverOptionsFormFields, backInsideCoverOptionsHeading } from "./constants";
import { CoverOptionType, ObjectType, SelectionsType } from "../../types";
import { convertHtmlListToText, fnConvertListToText } from "../../../utils";
import { useMagazineImageContext } from "../../../../../../../context/MagazineImageHook";
import { MagazineImageContextType } from "../../../../../../../context/MagazineImageContext";
import { values } from "lodash-es";

const BackInsideCover = ({ backInsideCover, handleImageLightBox, updateMagazineData }) => {
  const { magazine = {}, handleUpdateMagazine, magazineDataFormik, previousMagazineData, setFieldValue } = updateMagazineData || {};

  const selectedCoverOption = get(magazine, "backInsideCover");
  const backInsideCoverOptionValues = get(magazine, "backInsideCoverOptionValues");

  const lastSelectedCoverStripOption = useRef(selectedCoverOption?.formKeyword);

  const { ctxMagazineFromDB } = useContext(MagazineFromDBContext) as MagazineFromDBContextType<unknown>;
  const { setImageType, setSelectedUploadedImage } = useMagazineImageContext() as MagazineImageContextType;

  /**
   * Set initial value of selected option
   * Update magazineDataFormik values on selected option change
   */
  useEffect(() => {
    setFieldValue("backInsideCover.selectedOption", selectedCoverOption?.formKeyword);
    const fields = convertHtmlListToText(selectedCoverOption, lastSelectedCoverStripOption, ctxMagazineFromDB);
    if (fields.length > 0) {
      setFieldValue(
        "backInsideCover._formFields",
        fields.reduce((acc, item) => ({ ...acc, ...item }), {})
      );
    }
  }, []);

  useEffect(() => {
    const currentSelectedOption = get(selectedCoverOption, "formKeyword");
    const lastSelectedOption = get(lastSelectedCoverStripOption, "current");
    if (lastSelectedOption !== currentSelectedOption) {
      const currentValues = get(magazineDataFormik, "values.backInsideCover._formFields");
      lastSelectedCoverStripOption.current = currentSelectedOption;
      const initialValueOfSelctedOptionForm = backInsideCoverOptionsFormFields[currentSelectedOption];
      setFieldValue("backInsideCover._formFields", {
        ...initialValueOfSelctedOptionForm,
        ...currentValues,
      });
    }
  }, [selectedCoverOption, magazineDataFormik]);

  /**
   * Update the selected option
   *
   * @param option - CoverOptionType : Selected option
   */
  const handleCoverOptionChange = (option: CoverOptionType) => {
    const optionName = option.name.replace(" ", "");
    setImageType(`backInsideCoverImage${optionName}`);
    handleUpdateMagazine(option, "backInsideCover");
    setFieldValue("backInsideCover.selectedOption", option.formKeyword);
    setSelectedUploadedImage("");
  };

  /**
   * Reset the form fields to previous values
   * If magazine data is present in context, set the values from context
   * if previous magazine data is present, set the values from previous data
   */
  const onHandleResetFormFields = () => {
    if (!isEmpty(previousMagazineData)) {
      const insideCoverContent = previousMagazineData.selections.find((selection: CoverOptionType) => selection.page === 3);
      const { formKeyword } = insideCoverContent;
      const regex = /option-(\d)/;
      const match = formKeyword.match(regex);
      if (match.length > 0) {
        const optionSelected = match[0].replace("-", " ");
        const option = backInsideCover.find((opt) => opt.name.toLowerCase() === optionSelected.toLowerCase());
        handleUpdateMagazine(option, "backInsideCover");
      }
    } else if (!isEmpty(ctxMagazineFromDB)) {
      const objectResponse: any = ctxMagazineFromDB[0];
      const { selections }: SelectionsType = objectResponse as SelectionsType;
      const coverSelection = selections.find((selection: ObjectType) => selection.page === 3);

      if (!isEmpty(coverSelection)) {
        const { formKeyword } = coverSelection;
        const backInsideCoverSelection = backInsideCover.find((design: ObjectType) => design.formKeyword === formKeyword);
        handleUpdateMagazine(backInsideCoverSelection, "backInsideCover");
        setFieldValue("currentStep", -1);
      }
    } else {
      const initialValueOfSelctedOptionForm = backInsideCoverOptionsFormFields["backInsideCover-option-1"];
      handleUpdateMagazine(backInsideCover[0], "backInsideCover");
      setFieldValue("backInsideCover._formFields", initialValueOfSelctedOptionForm);
    }
    setFieldValue("currentStep", -1);
  };

  const allowedResolutionsTextBackInsideCover = { width: 1440, height: 960 };

  const renderAdditionalData = (type) => {
    switch (type) {
      case "backInsideCover-option-2":
        return <TestimonialsComponent step="backInsideCover" data={backInsideCoverOptionValues} formikHandler={magazineDataFormik} />;
      case "backInsideCover-option-3":
        return (
          <ListingDetailsComponent
            imageDimensions={allowedResolutionsTextBackInsideCover}
            step="backInsideCover"
            hasInputFields
            hasTextArea={false}
            formikHandler={magazineDataFormik}
            propertyKey="listingImageJustSold"
            uploadImageType="backInsideCoverImageOption3"
            presentImageLocation={get(magazineDataFormik, "values.backInsideCover._formFields.listingImageJustSold")}
          />
        );
      case "backInsideCover-option-4":
        return (
          <ListingDetailsComponent
            imageDimensions={allowedResolutionsTextBackInsideCover}
            step="backInsideCover"
            hasInputFields
            hasListingName
            hasTextArea
            formikHandler={magazineDataFormik}
            propertyKey="listingImageJustListed"
            presentImageLocation={get(magazineDataFormik, "values.backInsideCover._formFields.listingImageJustListed")}
            uploadImageType="backInsideCoverImageOption4"
          />
        );
      case "backInsideCover-option-6":
        return <ReferralPartnerComponent step="backInsideCover" data={backInsideCoverOptionValues} formikHandler={magazineDataFormik} />;
      default:
        return null;
    }
  };

  const selectedCoverStripOptionName = get(selectedCoverOption, "name");
  const selectedOptionTitle = backInsideCoverOptionsHeading[get(selectedCoverOption, "formKeyword")];

  return (
    <div>
      <div className="flex h-11 mt-[37px] items-center gap-5 self-stretch font-medium text-sm text-neutral-800">Select back inside cover</div>
      <RenderOptionsComponent
        onImageClick={handleImageLightBox}
        optionsList={backInsideCover}
        handleChange={handleCoverOptionChange}
        selectedOption={selectedCoverOption}
        setMaxWidth="182px"
        setMaxHeight="276px"
      />
      <div className="text-neutral-800 text-base font-semibold mt-8 mb-6">{selectedOptionTitle && `${selectedCoverStripOptionName}: ${selectedOptionTitle}`}</div>
      <div>{renderAdditionalData(selectedCoverOption?.formKeyword)}</div>
      <div className="flex gap-2 justify-start mt-6">
        <Button variant="LIGHT" onClick={onHandleResetFormFields}>
          Cancel
        </Button>
        <Button variant="DARK" buttonType="submit" onClick={magazineDataFormik.handleSubmit}>
          Save changes
        </Button>
      </div>
    </div>
  );
};

export default BackInsideCover;
