import React, { useState } from 'react';
import { Form, Formik } from 'formik';

import {
    Button,
    InputLabel,
    TextInput,
} from '../../../../../components';
import DefaultSideModal from '../../../../../components/modals/DefaultSideModal';
import { warningIcon } from '../../../../../../assets/images';

interface UTMParametersProps {
    showVerifyModal: boolean;
    setShowVerifyModal: React.Dispatch<React.SetStateAction<boolean>>;
    setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
    setVerifyLink: React.Dispatch<React.SetStateAction<string>>;
    setShowUTMModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const VerifyLinkModal: React.FC<UTMParametersProps> = ({
    showVerifyModal,
    setShowVerifyModal,
    setShowConfirmModal,
    setVerifyLink,
    setShowUTMModal,
}) => {
    const [link, setLink] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);

    const initialValues = {
        pasted_link: '',
    };

    const isValidUrl = (url: string): boolean => {
        try {
            setError('');

            // Validate the URL structure using the native URL constructor
            const parsedUrl = new URL(url);

            // Check if the protocol (scheme) is valid (either 'http' or 'https')
            if (!['http:', 'https:'].includes(parsedUrl.protocol)) {
                setError("Invalid protocol");
                return false;
            }

            // Check for invalid characters in the URL (spaces, quotes, etc.)
            const invalidCharacters = /[\s"'<>]/; // Spaces, quotes, <, >
            if (invalidCharacters.test(url)) {
                setError("Invalid characters found in URL");
                return false;
            }

            // Validate that the URL contains only one "?" (for query params)
            const questionMarkCount = (url.match(/\?/g) || []).length;
            if (questionMarkCount > 1) {
                setError("More than one '?' found");
                return false;
            }

            // Validate the query params structure
            const queryParams = new URLSearchParams(parsedUrl.search);
            const seenKeys = new Set<string>();

            // Iterate over each query parameter
            for (const [key, value] of queryParams.entries()) {
                // Check if the parameter key has been used before (i.e., duplicate parameters)
                if (seenKeys.has(key)) {
                    setError(`Duplicate query param found: ${key}`);
                    return false;
                }
                seenKeys.add(key);

                // Ensure that the value does not contain an equal sign "="
                if (value.includes("=")) {
                    setError(`Invalid character '=' found in the value for param: ${key}`);
                    return false;
                }

                // Ensure both the key and value of the query param are not empty
                if (key === '' || value === '') {
                    setError(`Empty key or value found for param: ${key || value}`);
                    return false;
                }
            }

            // Check for invalid use of symbols in the query string
            if (parsedUrl.search.includes("?&") || parsedUrl.search.includes("&&") || parsedUrl.search.includes("??")) {
                setError("Invalid query string symbols found");
                return false;
            }

            // Verify that the fragment (hash) comes after query params, and it doesn't contain any query parameters
            if (parsedUrl.hash && parsedUrl.hash.includes("?")) {
                setError("Invalid fragment with query parameters found");
                return false; // The hash part should not include query parameters
            }

            return true;
        } catch (error) {
            if(link) setError("Invalid URL");
            return false;
        }
    }

    const handleVerifyLink = () => {
        if (!link) {
            setError('This field is required');
            return
        }

        if (error) {
            let correctedLink = link.trim();

            // Try to fix invalid characters
            const invalidCharacters = /[\s"'<>]/; // Spaces, quotes, <, >
            if (invalidCharacters.test(correctedLink)) {
                correctedLink = correctedLink.replace(invalidCharacters, ''); // Remove invalid characters
            }

            // Try to fix duplicate "?" or "&&"
            correctedLink = correctedLink.replace(/\?\?+/g, '?').replace(/&&+/g, '&');

            // Remove all but the first occurrence of "?"
            const firstQuestionMarkIndex = correctedLink.indexOf('?');
            if (firstQuestionMarkIndex !== -1) {
                correctedLink = correctedLink.substring(0, firstQuestionMarkIndex + 1) + correctedLink.substring(firstQuestionMarkIndex + 1).replace(/\?/g, '');
            }

            // Validate the corrected URL
            const isValid = isValidUrl(correctedLink);

            if (isValid) {
                setShowWarningMessage(false);
                setShowVerifyModal(false);
                setShowConfirmModal(true);
                setVerifyLink(correctedLink);

            } else {
                setShowWarningMessage(true);
            }
        } else {
            setShowVerifyModal(false);
            setShowConfirmModal(true);
            setVerifyLink(link);
        }



    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleVerifyLink}
        >
            {({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldValue,
                errors,
                touched,
            }) => {
                return (
                    <Form onSubmit={handleSubmit}>
                        <DefaultSideModal
                            showModal={showVerifyModal}
                            setShowModal={setShowVerifyModal}
                            title="Verify UTM tracking link"
                            footer={
                                <Button type="submit" onClick={handleVerifyLink}>
                                    Verify and fix
                                </Button>
                            }
                        >
                            <div className='flex flex-col gap-6 max-w-[740px]'>
                                {showWarningMessage && (
                                    <div className='w-full px-3 py-2.5 bg-danger-50 flex gap-3 items-start rounded-md'>
                                        <img src={warningIcon} alt="warning" className='h-5 mt-[2px]' />
                                        <div>
                                            <p className='text-sm text-danger-500 font-semibold mb-3'>Unable to fix issues. Please try again or create a new UTM tracking link.</p>
                                            <Button
                                                variant='LIGHT'
                                                size='sm'
                                                onClick={() => setShowUTMModal(true)}
                                            >
                                                Create new
                                            </Button>
                                        </div>
                                    </div>
                                )}
                                <p className='text-sm text-neutral-500 font-medium'>Paste the link to verify its UTM parameters, and we'll correct any errors if found.</p>
                                <div>
                                    <InputLabel
                                        label="URL"
                                        description='Paste the link to copy its UTM parameters'
                                        required
                                    />
                                    <TextInput
                                        type="text"
                                        id="pasted_link"
                                        onChange={(e) => {
                                            handleChange(e);
                                            setError(''); // Reset error when typing
                                            setLink(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            handleBlur(e);
                                            setFieldValue('pasted_link', e.target.value);
                                            isValidUrl(e.target.value);
                                        }}
                                        value={values.pasted_link}
                                        error={error}
                                    />
                                    {error && (
                                        <span className="text-red-500">{error}</span>
                                    )}
                                </div>
                            </div>

                        </DefaultSideModal>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default VerifyLinkModal;
