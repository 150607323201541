import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Checkbox, Flowbite, Label, Modal } from 'flowbite-react';

import env from '../../../../environments';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import { formatDate } from '../../../../utils/date';
import { getOnboardUrl, getUtmTrackingUrl } from '../utils';
import ToastNotification from '../../../components/ToastNotification';
import DropdownTable from '../../../components/DropdownTable';
import { ColumnDef } from '@tanstack/react-table';
import AfyTable from '../../../components/V2/Table';
import PaginationFlowBite from '../../../components/PaginationFlowBite';
import { ToastContainer } from 'react-toastify';
import SkeletonLoader from '../../../components/SkeletonLoader';
import { ArrowRight, CopyLinkIcon } from '../../../../components/icons';
import TextInput from '../../../components/TextInput';
import { isEmpty, set } from 'lodash-es';
import noItemsImage from '../../../../assets/images/no-items.png';
import { checkCircleIcon, plusCircleIcon } from '../../../../assets/images';
import { customCheckboxTheme } from '../../../../utils/customTheme';
import { getOffers } from '../../../api/productCatalog/actions';
import { debounce } from 'lodash-es';
import {
  ConfirmUtmParamsModal,
  ConfirmVerifyLinkModal,
  UTMParametersModal,
  VerifyLinkModal,
} from './modals';
import { usePermissions } from '../../../hooks/usePermissions';
import { offerIsEditable } from '../CreateOffer/utils';

const Offers = () => {
  const { canAccessEditOffer } = usePermissions();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const [record, setRecord] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentTablePage, setCurrentTablePage] = useState(1);
  const [sortByfilter, setSortByfilter] = useState({
    key: 'createdAt',
    value: '-1',
  });
  const [showProductCatalogOffersOnly, setShowProductCatalogOffersOnly] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const [offerCode, setOfferCode] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [linkProps, setLinkProps] = useState({});
  const [showUTMModal, setShowUTMModal] = useState(false);
  const [showVerifyLinkModal, setShowVerifyLinkModal] = useState(false);
  const [showConfirmUtmLinkModal, setShowConfirmUtmLinkModal] = useState(false);
  const [showConfirmVerifyLinkModal, setShowConfirmVerifyLinkModal] = useState(false);
  const [utmTrackingLink, setUtmTrackingLink] = useState('');
  const [verifyLink, setVerifyLink] = useState('');

  const createOfferFlag = import.meta.env.VITE_ENABLE_CREATE_OFFER === 'true';

  useEffect(() => {
    if (location.state && location.state.showModal) {
      setShowModal(true);
      setOfferCode(location.state.offerCode);
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  const {
    data: productCatalogOffersResult,
    isLoading: isProductCatalogOffersLoading,
    refetch: refetchProductCatalogOffers,
  } = useQuery(
    [
      'productCatalogOffers',
      perPage,
      currentTablePage,
      sortByfilter,
      showProductCatalogOffersOnly,
      searchQuery,
    ],
    () =>
      getOffers({
        q: searchQuery,
        page: currentTablePage,
        perPage,
        sortBy: sortByfilter.key,
        order: sortByfilter.value,
        productCatalogOnly: showProductCatalogOffersOnly,
      }),
    {
      retry: 2,
      onError: (error) => {
        ToastNotification({
          type: 'failure',
          message: 'Product Catalog offers could not be retrieved',
        });
      },
    },
  );

  useEffect(() => {
    refetchProductCatalogOffers();
  }, [
    sortByfilter,
    showProductCatalogOffersOnly,
    currentTablePage,
    searchQuery,
    refetchProductCatalogOffers,
  ]);

  const productCatalogOffers =
    productCatalogOffersResult?.data?.map((offer) => ({
      ...offer,
    })) || [];

  const totalOffers = productCatalogOffersResult?.meta?.total || 0;

  const offersData = [...productCatalogOffers];

  const isOffersEmpty = isEmpty(offersData);

  const tablePagination = {
    pageSize: perPage,
    total: totalOffers,
    lastPage: Math.ceil(totalOffers / perPage),
    currentPage: currentTablePage,
    onChange: (page) => setCurrentTablePage(page),
  };

  const handleOfferChange = (offer) => {
    const offerCode = offer.code;
    navigate(`/offers/${offerCode}`, { state: offer });
  };

  const handleLaunch = ({ offerCode, launchOfferType }) => {
    const url = getOnboardUrl({ offerCode, launchOfferType });
    window.open(url, '_blank', 'incognito=yes');
  };

  const getCopyUrl = (offers, isUtmTracking?: boolean, params?: { [key: string]: string }) => {
    let obj;
    if (offers?.isProductCatalog) {
      obj = {
        offerCode: offers.code,
        isProductCatalogOffer: true,
      };
    } else {
      obj = {
        offerCode: offers.code,
        launchOfferType: offers.launchOfferType,
      };
    }

    let url
    if (isUtmTracking) {
      obj.params = params;
      url = getUtmTrackingUrl(obj);
    } else {
      url = getOnboardUrl(obj);
    }
    return url;
  };

  const onCopyText = () => {
    ToastNotification({ type: 'success', message: 'Link copied to clipboard' });

  };

  const handlePerPage = (value, firstItemIndex) => {
    const perPage = value.value;
    const pageNumber = Math.ceil(firstItemIndex / perPage) || 1;
    setPerPage(perPage);
    setCurrentTablePage(pageNumber);
  };

  const handleSort = (columnKey) => {
    setCurrentTablePage(1);
    if (sortByfilter.key === columnKey) {
      const descend =
        sortByfilter.key === columnKey && sortByfilter.value === '-1';
      const ascend =
        sortByfilter.key === columnKey && sortByfilter.value === '1';
      if (descend) {
        setSortByfilter({ key: columnKey, value: '1' });
      } else if (ascend) {
        setSortByfilter({ key: columnKey, value: '-1' });
      }
    } else {
      setSortByfilter({ key: columnKey, value: '1' });
    }
  };

  const handleFilterChange = () => {
    setShowProductCatalogOffersOnly(!showProductCatalogOffersOnly);
    setCurrentTablePage(1);
    refetchProductCatalogOffers();
  };

  const handleSearchInput = (e) => {
    const currValue = e.target.value;
    setSearchInput(currValue);
    debouncedSearch(currValue);
  };

  const debouncedSearch = debounce((value) => {
    setSearchQuery(value);
    setCurrentTablePage(1);
    refetchProductCatalogOffers();
  }, 300);

  const handleResetFilters = () => {
    setSearchQuery('');
    setRecord([]);
    setCurrentTablePage(1);
  };

  const canDisplayEditAction = (offer) => {
    return Boolean(
      offerIsEditable(offer) &&
      canAccessEditOffer()
    );
  };

  const tableColumns = React.useMemo<ColumnDef<any>[]>(
    () => [
      {
        header: 'Offer name',
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          const offerName = row.title;
          return (
            <div
              className="flex items-center hover:cursor-pointer"
              onClick={() => handleOfferChange(row)}
            >
              <span>
                <div className="text-sky-800 text-sm font-semibold font-['Figtree']">
                  <a className="underline">
                    {offerName}
                  </a>
                </div>

                <div className="text-neutral-800 text-sm font-['Figtree']">
                  {row.code || row.offerCode || '-'}
                </div>
              </span>

              <span className="ml-4">
                <ArrowRight />
              </span>
            </div>
          );
        },
        customProp: 'title',
      },
      {
        header: 'Product code',
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          const productCode = row.code;
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {productCode}
            </div>
          );
        },
        customProp: 'firstProduct.title',
      },
      {
        header: 'Credits',
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {row?.credits || 0}
            </div>
          );
        },
        customProp: 'credits',
      },
      {
        header: 'Type',
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          if (row.isProductCatalog) {
            const typeLabel =
              row.status === 'DRAFT' ? 'Draft' : 'Product Catalog';
            const bgColor =
              typeLabel === 'Draft' ? 'bg-gray-400' : 'bg-emerald-500';
            return (
              <div className="h-6 px-2 py-1 bg-white rounded-md border border-gray-200 justify-center items-center gap-2 inline-flex">
                <div className="w-2 h-2 relative">
                  <div
                    className={`w-1.5 h-1.5 left-[1px] top-[1px] absolute ${bgColor} rounded-full`}
                  />
                </div>
                <div className="text-center text-neutral-800 text-xs font-semibold font-['Figtree'] leading-none">
                  {typeLabel}
                </div>
              </div>
            );
          }
          return (
            <>
              {row?.type === 'main' ? (
                <div className="h-6 px-2 py-1 bg-white rounded-md border border-gray-200 justify-center items-center gap-2 inline-flex">
                  <div className="w-2 h-2 relative">
                    <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-sky-300 rounded-full" />
                  </div>
                  <div className="text-center text-neutral-800 text-xs font-semibold font-['Figtree'] leading-none">
                    Main
                  </div>
                </div>
              ) : (
                <div className="h-6 px-2 py-1 bg-white rounded-md border border-gray-200 justify-center items-center gap-2 inline-flex">
                  <div className="w-2 h-2 relative">
                    <div className="w-1.5 h-1.5 left-[1px] top-[1px] absolute bg-amber-400 rounded-full" />
                  </div>
                  <div className="text-center text-neutral-800 text-xs font-semibold font-['Figtree'] leading-none">
                    Add-on
                  </div>
                </div>
              )}
            </>
          );
        },
        customProp: 'type',
      },
      {
        header: 'Created date',
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          return (
            <div className="text-neutral-800 text-sm font-semibold font-['Figtree']">
              {formatDate(row?.createdAt, 'MMM DD, YYYY')}
            </div>
          );
        },
        customProp: 'createdAt',
      },
      {
        header: 'Action',
        cell: (info) => info.getValue(),
        accessorFn: (row) => {
          const savedOffers = JSON.parse(localStorage.getItem('utmParams') || '{}');
          const offerCode = row.code || row.offerCode;
          const salesLinkToCopy = getCopyUrl(row);
          const utmLinkToCopy = getCopyUrl(row, true, savedOffers[offerCode]);
          if (row?.isProductCatalog) {
            const isPublished = row?.status === 'PUBLISHED';

            return (
              <div className="flex justify-arround">
                <DropdownTable id={row._id} title='Copy'>
                  <li className='min-w-[210px]'>
                    <CopyToClipboard text={salesLinkToCopy} onCopy={onCopyText}>
                      <button
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content="Copy link"
                        disabled={!isPublished}
                        className={`flex gap-2.5 items-center px-4 py-2 w-full hover:bg-neutral-100 cursor-pointer ${isPublished
                          ? 'cursor-pointer'
                          : 'cursor-not-allowed opacity-30'
                          }`}
                      >
                        <CopyLinkIcon />
                        <span>Copy sales agent link</span>
                      </button>
                    </CopyToClipboard>
                  </li>
                  {savedOffers[offerCode] && (
                    <li>
                      <CopyToClipboard text={utmLinkToCopy} onCopy={onCopyText}>
                        <button
                          data-tooltip-id="copy-tooltip"
                          data-tooltip-content="Copy link"
                          disabled={!isPublished}
                          className={`flex gap-2.5 items-center px-4 py-2 w-full hover:bg-neutral-100 cursor-pointer ${isPublished
                            ? 'cursor-pointer'
                            : 'cursor-not-allowed opacity-30'
                            }`}
                        >
                          <CopyLinkIcon />
                          <span>Copy UTM tracking link</span>
                        </button>
                      </CopyToClipboard>
                    </li>
                  )}


                  <li className="pt-2 mt-2 border-t border-neutral-200">
                    <div className="flex gap-2.5 items-center px-4 py-2 hover:bg-neutral-100  cursor-pointer" role='button' onClick={() => setShowVerifyLinkModal(true)}>
                      <img src={checkCircleIcon} alt="target" className="w-4 h-4" />
                      <span>Verify UTM tracking link</span>
                    </div>
                  </li>

                  <li className="flex gap-2.5 items-center px-4 py-2 hover:bg-neutral-100 cursor-pointer" role='button' onClick={() => {
                    setLinkProps(row);
                    setShowUTMModal(true)
                  }}>
                    <img src={plusCircleIcon} alt="target" className="w-4 h-4" />
                    <span>Create UTM tracking link</span>
                  </li>
                </DropdownTable>

                {canDisplayEditAction(row) && <Button
                  onClick={() => navigate(`/offers/${row.code}/edit`, { state: row })}
                  className="ml-2 font-bold"
                  variant="DARK"
                >
                  Edit
                </Button>}
              </div>
            );
          }
          return (
            <>
              {row?.type === 'main' ? (
                <div className="flex">
                  <div className="px-1">
                    <CopyToClipboard text={salesLinkToCopy} onCopy={onCopyText}>
                      <button
                        data-tooltip-id="copy-tooltip"
                        data-tooltip-content="Copy link"
                        className="w-9 h-9 p-2.5 bg-neutral-100 hover:bg-[#E9E9E9] rounded-md justify-center items-center gap-2 inline-flex"
                      >
                        <CopyLinkIcon />
                      </button>
                    </CopyToClipboard>
                    <ReactTooltip
                      id="copy-tooltip"
                      place="top"
                      style={{ padding: 10, borderRadius: 10 }}
                    />
                  </div>
                </div>
              ) : (
                <div className="text-zinc-400 text-sm font-semibold font-['Figtree'] leading-tight">
                  No link for addon
                </div>
              )}
            </>
          );
        },
        canSort: false,
      },
    ],
    [],
  );

  return (
    <div className="pt-8 min-h-screen w-full">
      <div className="flex w-full">
        <div className="w-full text-neutral-800 text-2xl font-extrabold font-['Figtree'] leading-7 flex justify-between items-center">
          <Title>Offers</Title>

          {createOfferFlag && (
            <Button
              onClick={() => navigate('/offers/new-offer')}
              className="font-bold"
            >
              Create offer
            </Button>
          )}
        </div>
      </div>

      <div className="items-center w-full mt-6 bg-white rounded-t-lg shadow justify-between inline-flex max-[800px]:justify-center">
        <div className="px-4 py-6 gap-2 flex">
          <div className="text-zinc-500 text-sm font-medium font-['Figtree'] leading-tight">
            <TextInput
              placeholder="Search for offer name or code"
              value={searchInput}
              onChange={handleSearchInput}
            />
          </div>
        </div>
        <div className="px-4 py-6 gap-2 flex">
          <div className="flex items-center gap-2">
            <Flowbite theme={{ theme: customCheckboxTheme }}>
              <Checkbox
                id="showProductCatalogOffersOnly"
                checked={showProductCatalogOffersOnly}
                onChange={handleFilterChange}
              />
            </Flowbite>
            <Label
              className="font-semibold text-neutral-800 text-sm"
              htmlFor="showProductCatalogOffersOnly"
            >
              Show product catalog offers only
            </Label>
          </div>
        </div>
      </div>

      <div>
        {isProductCatalogOffersLoading ? (
          <div className="bg-white rounded-lg border-t-0 shadow">
            <SkeletonLoader />
          </div>
        ) : (
          <div>
            {isOffersEmpty ? (
              <div className="flex flex-col justify-center items-center py-16 bg-white">
                <img
                  src={noItemsImage}
                  alt="No items"
                  className="mb-4 w-[150px] h-[150px]"
                />
                <div className="flex flex-col justify-start items-center gap-1">
                  <div className="text-center text-neutral-800 text-base font-semibold font-['Figtree'] leading-tight">
                    No results found.
                  </div>
                  <div className="text-center">
                    <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                      Try a different keyword or{' '}
                    </span>
                    <button
                      onClick={handleResetFilters}
                      className="text-sky-400 text-sm font-medium underline leading-[23px] focus:outline-none"
                    >
                      reset filters
                    </button>
                    <span className="text-neutral-500 text-sm font-medium font-['Figtree'] leading-[23px]">
                      .
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full overflow-auto">
                <div className="w-full bg-white items-center inline-flex">
                  <AfyTable
                    data={offersData}
                    columns={tableColumns}
                    perPage={perPage}
                    handleSort={handleSort}
                    columnName={sortByfilter}
                  />
                </div>
                <div className="w-full bg-white rounded-b-lg py-6 px-6 shadow max-[1180px]:px-0 h-full mb-32">
                  <PaginationFlowBite
                    tablePagination={tablePagination}
                    handlePerPage={handlePerPage}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <ToastContainer />

      <Modal
        dismissible
        show={showModal}
        onClose={() => setShowModal(false)}
        size="lg"
      >
        <Modal.Body className="max-w-[512px] p-[30px] flex flex-col justify-center items-center">
          <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_12365_50446)">
              <rect x="2" y="1" width="52" height="52" rx="26" fill="#069668" />
              <path
                d="M17.8008 28.7L23.7373 35.5L38.5786 18.5"
                stroke="white"
                stroke-width="5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_12365_50446"
                x="0"
                y="0"
                width="56"
                height="56"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_12365_50446"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_12365_50446"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
          <h2 className="text-neutral-800 font-bold leading-tight my-3.5">
            Your offer is live! 🥳
          </h2>
          <div className="text-sm text-neutral-500 font-medium leading-tight">
            You can now visit or copy your unique link
          </div>
          <div className="bg-white h-10 rounded-md border border-gray-200 my-4 flex justify-between items-center max-w-[360px]">
            <div className="text-neutral-800 text-sm truncate p-4 ">
              {`${env.PUBLISHED_OFFER_URL}${offerCode}`}
            </div>
            <CopyToClipboard
              text={`${env.PUBLISHED_OFFER_URL}${offerCode}`}
              onCopy={onCopyText}
              className="bg-neutral-50 border-l border-gray-200 h-full p-2 rounded-r-md"
            >
              <button className="focus:outline-none">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 9.88235C7 8.05219 7 7.13711 7.64437 6.56856C8.28873 6 9.32582 6 11.4 6H13.6C15.6742 6 16.7113 6 17.3556 6.56856C18 7.13711 18 8.05219 18 9.88235V13.1176C18 14.9478 18 15.8629 17.3556 16.4314C16.7113 17 15.6742 17 13.6 17H11.4C9.32582 17 8.28873 17 7.64437 16.4314C7 15.8629 7 14.9478 7 13.1176V9.88235Z"
                    stroke="#252525"
                    stroke-width="1.5"
                  />
                  <path
                    d="M5.2 13C3.98497 13 3 12.1309 3 11.0588V7.17647C3 4.73626 3 3.51615 3.85915 2.75808C4.71831 2 6.10109 2 8.86667 2H11.8C13.015 2 14 2.86909 14 3.94118"
                    stroke="#252525"
                    stroke-width="1.5"
                  />
                </svg>
              </button>
            </CopyToClipboard>
          </div>
          <div className="flex gap-2 mt-5">
            <Button
              onClick={() =>
                window.open(`${env.PUBLISHED_OFFER_URL}${offerCode}`, '_blank')
              }
              variant="PRIMARY"
            >
              Visit offer
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <UTMParametersModal
        showUTMModal={showUTMModal}
        setShowUTMModal={setShowUTMModal}
        offer={linkProps}
        setShowLinkModal={setShowConfirmUtmLinkModal}
        setUtmTrackingLink={setUtmTrackingLink}
      />
      <VerifyLinkModal
        showVerifyModal={showVerifyLinkModal}
        setShowVerifyModal={setShowVerifyLinkModal}
        setShowConfirmModal={setShowConfirmVerifyLinkModal}
        setVerifyLink={setVerifyLink}
        setShowUTMModal={setShowUTMModal}
      />
      <ConfirmUtmParamsModal
        showLinkModal={showConfirmUtmLinkModal}
        setShowLinkModal={setShowConfirmUtmLinkModal}
        utmTrackingLink={utmTrackingLink}
        onCopyText={onCopyText}
      />
      <ConfirmVerifyLinkModal
        showLinkModal={showConfirmVerifyLinkModal}
        setShowLinkModal={setShowConfirmVerifyLinkModal}
        verifyLink={verifyLink}
        onCopyText={onCopyText}
      />
    </div>
  );
};

export default Offers;
