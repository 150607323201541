import React from 'react';

interface Props {
  className?: string;
}

const UndoCircle: React.FC<Props> = ({ className = 'w-6 h-6' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Iconer">
        <path
          id="Vector"
          d="M15.3032 6.70936L14.714 6.12011C12.1105 3.51661 7.88937 3.51661 5.28587 6.12011C2.68238 8.7236 2.68238 12.9447 5.28587 15.5482C7.88937 18.1517 12.1105 18.1517 14.714 15.5482C16.228 14.0342 16.8616 11.9731 16.6147 10.0012M15.3032 6.70936H11.7677M15.3032 6.70936V3.17383"
          stroke="#E75A50"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default UndoCircle;
