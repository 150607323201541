import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
    Button,
} from '../../../../../components';
import DefaultModal from '../../../../../components/modals/DefaultModal';
import { greenCheckIcon } from '../../../../../../assets/images';


interface ConfirmVerifyLinkModalProps {
    showLinkModal: boolean;
    setShowLinkModal: React.Dispatch<React.SetStateAction<boolean>>;
    verifyLink?: string;
    onCopyText: () => void;
}

const ConfirmVerifyLinkModal: React.FC<ConfirmVerifyLinkModalProps> = ({
    showLinkModal,
    setShowLinkModal,
    verifyLink,
    onCopyText,
}) => {
    return (
        <DefaultModal
            showModal={showLinkModal}
            setShowModal={setShowLinkModal}
            size='lg'
        >
            <div className='flex flex-col items-center'>
                <img src={greenCheckIcon} alt="check" className='h-[56px] mt-3 mb-4' />
                <p className='w-fit text-center font-bold'>Your UTM tracking link</p>
                <p className='w-fit mb-6 mt-4 text-center text-sm font-medium text-neutral-500'>The UTM parameters have been verified and any errors have been corrected. Your link is now ready to use!</p>

                <div className="bg-white h-10 rounded-md border border-gray-200  flex justify-between items-center max-w-[360px] mb-6">
                    <div className="text-neutral-800 text-sm truncate p-4 ">
                        {verifyLink}
                    </div>
                    <CopyToClipboard
                        text={verifyLink}
                        onCopy={onCopyText}
                        className="bg-neutral-50 border-l border-gray-200 h-full p-2 rounded-r-md"
                    >
                        <button className="focus:outline-none">
                            <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7 9.88235C7 8.05219 7 7.13711 7.64437 6.56856C8.28873 6 9.32582 6 11.4 6H13.6C15.6742 6 16.7113 6 17.3556 6.56856C18 7.13711 18 8.05219 18 9.88235V13.1176C18 14.9478 18 15.8629 17.3556 16.4314C16.7113 17 15.6742 17 13.6 17H11.4C9.32582 17 8.28873 17 7.64437 16.4314C7 15.8629 7 14.9478 7 13.1176V9.88235Z"
                                    stroke="#252525"
                                    stroke-width="1.5"
                                />
                                <path
                                    d="M5.2 13C3.98497 13 3 12.1309 3 11.0588V7.17647C3 4.73626 3 3.51615 3.85915 2.75808C4.71831 2 6.10109 2 8.86667 2H11.8C13.015 2 14 2.86909 14 3.94118"
                                    stroke="#252525"
                                    stroke-width="1.5"
                                />
                            </svg>
                        </button>
                    </CopyToClipboard>
                </div>
                <Button
                    onClick={() =>
                        window.open(verifyLink, '_blank')
                    }
                    variant="PRIMARY"
                >
                    Visit offer
                </Button>
            </div>
        </DefaultModal>
    );
};

export default ConfirmVerifyLinkModal;