import React from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { ArrowDownBlack, ArrowUpBlack } from "../../../components/icons";
import { getHeaderClassNames, getCellClassNames } from '../../utils/classnamesUtils';

interface IAfyTable<T> {
  data: T[];
  columns: ColumnDef<T>[];
  perPage: string | number;
  handleSort: (sortBy: string) => void;
  columnName: { key: string; value: string };
  showTableHead: boolean;
}

function AfyTable<T>({
  data,
  columns,
  perPage,
  handleSort,
  columnName,
  showTableHead = true,
}: IAfyTable<T>) {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const handleHeaderClick = (sortBy: string) => {
    handleSort(sortBy);
  };

  const table = useReactTable({
    columns,
    data,
    debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  if (!data || data.length === 0) {
    return <div className="flex justify-center items-center">No data available</div>;
  }

  return (
    <div className="w-full overflow-auto">
      <table className="w-full border-spacing-0 border-separate">
        {showTableHead && <thead className="text-left">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="justify-start">
              {headerGroup.headers.map((header, index) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={getHeaderClassNames(index, columnName.key, header?.column?.columnDef?.customProp)}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={
                          header.column.getCanSort()
                            ? "cursor-pointer select-none flex items-center gap-1.5"
                            : "flex items-center"
                        }
                        onClick={() => {
                          const currentSort = header.column.getIsSorted();
                          const nextSorting =
                            currentSort === "asc" ? "desc" : "asc";
                          setSorting([
                            {
                              id: header.column.id,
                              desc: nextSorting === "desc",
                            },
                          ]);
                          handleHeaderClick(
                            header?.column?.columnDef?.customProp as string
                          );
                        }}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === "asc"
                              ? "Sort descending"
                              : "Sort ascending"
                            : undefined
                        }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {columnName.key ===
                        header?.column?.columnDef?.customProp ? (
                          columnName.value == "-1" ? (
                            <ArrowUpBlack />
                          ) : (
                            <ArrowDownBlack />
                          )
                        ) : null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>}
        <tbody>
          {table
            .getRowModel()
            .rows.slice(0, perPage)
            .map((row) => {
              return (
                <tr
                  key={row.id}
                  className="whitespace-nowrap border-b hover-bg-sky-50"
                >
                  {row.getVisibleCells().map((cell, index) => {
                    return (
                      <td
                        key={cell.id}
                        className={getCellClassNames(index)}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default AfyTable;
