import React, { useContext, useEffect } from 'react';
import { upperFirst } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import OfferDetails from './OfferDetails';
import OfferPreview from './Preview';
import SelectTemplate from './SelectTemplate';
import { Offer, ProductFamilyProps } from '../utils/types';
import { getOfferByCode } from '../../../../api/offer';
import { getProductFamilies, getProductPackages } from '../../../../api/productCatalog/actions';
import { OfferContext } from '../utils/OfferProvider';
import { usePermissions } from '../../../../hooks/usePermissions';
import { useNavigate } from 'react-router-dom';
import { formatEmphatizedBooks, offerIsEditable } from '../utils';
import { formatProductFamilies } from '../utils/offer';

const CreateOfferSteps = () => {
  const navigate = useNavigate();

  const { canAccessEditOffer } = usePermissions();

  const { step, offerIsBeingEdited, ...contextObject } = useContext(OfferContext);
  const location = useLocation();

  const {
    data: productsFamiliesData,
    isLoading: isLoadingProduct,
  } = useQuery(
    ['getProductsByFamilyGroup0'],
    getProductFamilies,
  );

  const productsFamilies: ProductFamilyProps = productsFamiliesData || {};
  const productsOptions = formatProductFamilies(productsFamilies || {});

  const contextSetter = (variableName: string, isObject: boolean, value: string | number) => {
    const methodAccessor = 'set' + upperFirst(variableName);

    if (isObject)
      contextObject?.[methodAccessor]?.(JSON.parse(value as string));
    else if (value === 'true')
      contextObject?.[methodAccessor]?.(true);
    else if (value === 'false')
      contextObject?.[methodAccessor]?.(false);
    else
      contextObject?.[methodAccessor]?.(value);
  };

  const fillCurrentOffer = (offer: Offer) => {
    contextObject.setOfferId(offer._id);
    contextObject.setOfferName(offer.title);
    contextObject.setOfferCode(offer.code);
    contextObject.setOfferCountry(['United States']);

    const offerProduct = offer.products[0];
    const selectedProduct = productsOptions.find(({ key }) => key === offerProduct);

    contextObject.setOfferProduct(selectedProduct?.selectedLabel);
    contextObject.setProductRenewInterval(
      selectedProduct?.productData?.renewInterval || 'monthly'
    );

    getProductPackages(selectedProduct?.key as string).then((productPackagesData) => {
      if (!productPackagesData) return;

      const booksEmphatizeds = formatEmphatizedBooks(offer, productPackagesData);

      contextObject.setSelectedPackageNames(booksEmphatizeds);
      contextObject.setAvailableBookPackages(productPackagesData?.data);
      contextObject.setDefaultPackages(productPackagesData?.data);
      contextObject.setEmphasisOptions(productPackagesData?.data);
    });

    contextObject.setOfferBookCategories(offer.categories);
    contextObject.setOfferTrialEnabled(offer.trial && offer.trial > 0);
    contextObject.setOfferTrialPrice(offer.trialPrice);
    contextObject.setOfferTrialCredits(offer.credits);
    contextObject.setOfferTrialDuration(offer.trial);

    offer.templateVariables.forEach(({ handle, isObject, value }) => contextSetter(handle, isObject, value));

    const productTitle = offer.templateVariables.find(({ handle }) => handle === 'offerTitle')?.value;
    const productDescription = offer.templateVariables.find(({ handle }) => handle === 'offerDescription')?.value;

    contextObject.setProductTitle(productTitle);
    contextObject.setProductDescription(productDescription);
  };

  // chargifyProductHandle

  const performOfferFecthing = (offerCode: string) => {
    getOfferByCode(offerCode).then(({ data: offer }) => {
      if (offerIsEditable(offer)){
        fillCurrentOffer(offer)
      } else {
        navigate('/offers');
      }
    });
  }

  useEffect(() => {
    if (offerIsBeingEdited() && !canAccessEditOffer()) {
      navigate('/offers');
    } else if (offerIsBeingEdited() && !isLoadingProduct) {
      const offerFromRoute = location.state;

      if (offerFromRoute) fillCurrentOffer(offerFromRoute)
      else {
        const offerCode = location.pathname.split('/')[2];

        performOfferFecthing(offerCode);
      }

    }
  }, [location, isLoadingProduct]);

  return (
    <>
      {step === 1 && (
        <OfferDetails
          productsFamilies={productsFamilies}
          productsOptions={productsOptions}
        />
      )}

      {step === 2 && <SelectTemplate />}

      {step === 3 && <OfferPreview />}
    </>
  );
};

export default CreateOfferSteps;
