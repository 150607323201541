import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MemberPassword from './pages/memberManagement/MemberPassword';
import TeamMeeting from './pages/onboarding/teamMeeting';
import SalesReport from './pages/reports/SalesReport';
import TeamMeetingReport from './pages/onboarding/TeamMeetingReport';
import TeamPhoneCall from './pages/onboarding/teamPhoneCalls';
import TeamPhoneCallsReport from './pages/onboarding/teamPhoneCallsReport';
import RmMagaineReport from './pages/reports/rmMagazineReport';
import RmMagaineDetails from './pages/reports/rmMagazineReport/MagazineDetails';
import CoachMaitenence from './pages/onboarding/CoachMaintenance';
import EditCoach from './pages/onboarding/CoachMaintenance/EditCoach';
import CreateCoach from './pages/onboarding/CoachMaintenance/CreateCoach';
import Offers from './pages/marketing/Offers';
import SingleOffer from './pages/marketing/SingleOffer';
import TripWireReport from './pages/reports/tripWireReport';
import EditOffer from './pages/marketing/EditOffer';
import CreateOffer from './pages/marketing/CreateOffer';
import Offboarding from './pages/v2/offboarding';
import OffboardingMember from './pages/Offboarding/OffboardingMember';
import LoginPage from '../LoginPage';
import OffboardingDetails from './pages/v2/offboarding/details';
import HomePage from './pages/HomePage';
import MagazineRecipient from './pages/reports/MagazineRecipient';
import UserLeads from './pages/reports/MagazineRecipient/UserLeads';
import ReferralMarketing from './pages/v2/referral-marketing';
import RmmAutomationDetails from './pages/v2/referral-marketing/components/RmmAutomationDetails';
import MailOnDemand from './pages/reports/MailOnDemand';
import MODContent from './pages/reports/MailOnDemand/components/MODContent';
import BatchDetailView from './pages/reports/MailOnDemand/components/BatchDetailView';
import ProtectedRoute from './components/ProtectedRoute';
import { usePermissions } from './hooks/usePermissions';
import environments from '../environments';
export function Callback() {
  return (
    <div>
      <p>Call back</p>
    </div>
  );
}

const RoutesConfig = () => {
  const { canAccessCoachMaintenance, canAccessRMM } = usePermissions();
  const showMailOnDemand = environments.ENABLE_MOD;

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/member-password-change" element={<MemberPassword />} />
      <Route path="/team-meetings" element={<TeamMeeting />} />
      <Route path="/sales-report" element={<SalesReport />} />
      <Route
        path="/team-meetings/:teamMemberEmail"
        element={<TeamMeetingReport />}
      />
      <Route path="/team-phone-calls" element={<TeamPhoneCall />} />
      <Route
        path="/team-phone-calls/:teamMemberEmail/:currToken?/:currPage?"
        element={<TeamPhoneCallsReport />}
      />
      <Route path="/tripwire-report" element={<TripWireReport />} />
      <Route path="/magazine-recipient" element={<MagazineRecipient />} />
      <Route path="/magazine-recipient/:userId" element={<UserLeads />} />
      <Route path="/rm-magazine-report" element={<RmMagaineReport />} />
      <Route
        path="/rm-magazine-report/details/:year?/:month?/:customerId?"
        element={<RmMagaineDetails />}
      />
      <Route
        path="/coach-maintenance"
        element={
          <ProtectedRoute checkAccess={canAccessCoachMaintenance}>
            <CoachMaitenence />
          </ProtectedRoute>
        }
      />
      <Route
        path="/coaches/edit/:id"
        element={
          <ProtectedRoute checkAccess={canAccessCoachMaintenance}>
            <EditCoach />
          </ProtectedRoute>
        }
      />
      <Route
        path="/coaches/create"
        element={
          <ProtectedRoute checkAccess={canAccessCoachMaintenance}>
            <CreateCoach />
          </ProtectedRoute>
        }
      />
      <Route path="/offers" element={<Offers />} />
      <Route path="/offers/new-offer" element={<CreateOffer />} />
      <Route path="/offers/new-offer/preview" element={<CreateOffer />} />
      <Route path="/offers/:offerCode/edit" element={<CreateOffer />} />
      <Route path="/offers/:offerCode" element={<SingleOffer />} />
      {/* <Route path="/editoffers/:id" element={<EditOffer />} /> */}
      <Route path="/offboarding" element={<Offboarding />} />
      <Route path="/offboarding/:id" element={<OffboardingDetails />} />
      <Route path="/app/callback" element={<Callback />} />
      <Route path="/home" element={<HomePage />} />
      <Route
        path="/referral-marketing"
        element={
          <ProtectedRoute checkAccess={canAccessRMM}>
            <ReferralMarketing />
          </ProtectedRoute>
        }
      />
      <Route
        path="/referral-marketing/:id"
        element={
          <ProtectedRoute checkAccess={canAccessRMM}>
            <RmmAutomationDetails />
          </ProtectedRoute>
        }
      />
      {showMailOnDemand && (
        <Route path="/mail-on-demand" element={<MailOnDemand />}>
          <Route index element={<MODContent />} />
          <Route path="batch/:batchId" element={<BatchDetailView />} />
        </Route>
      )}
    </Routes>
  );
};

export default RoutesConfig;
