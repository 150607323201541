import { getInstance } from './instance';

const instance = getInstance();

interface GenerateReferralMagazineData {
  payload: {
    month: string;
    year: number;
    userEmail: string;
    customerId: string;
  };
  isPreview: boolean;
}

type QueryKeyType = [unknown, string, string, string];

interface UpdateReferralMagazineRequest {
  month: string;
  year: string;
  customerId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
}

const getAllMagazineMetrics = (month: string, year: number) => {
  const url = '/referral-marketing/magazines/reports/all';

  const params = { month, year };

  return instance
    .get(url, { params })
    .then((res) => res.data)
    .catch((error) => error);
};

const getRmMagazineMetricsEditing = (
  month: string,
  year: number,
  page = 1,
  perPage = 10,
  status: string,
  sortByFilter?: { [key: string]: string },
) => {
  const url = '/abo/reports/magazine-editing';
  const params = { month, year, page, perPage, status };
  if (sortByFilter) {
    params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
  }

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getRmMagazineMetricsSentToPrint = (
  month: string,
  year: number,
  page = 1,
  perPage = 10,
  status: string,
  sortByFilter?: { [key: string]: string },
) => {
  const url = '/abo/reports/magazine-sent-to-print';
  const params = { month, year, page, perPage, status };
  if (sortByFilter) {
    params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
  }

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getRmMagazineMetricsMagazineGenerated = (
  month: string,
  year: number,
  page = 1,
  perPage = 10,
  status: string,
  sortByFilter?: { [key: string]: string },
) => {
  const url = '/abo/reports/all';
  const params = { month, year, page, perPage, status };
  if (sortByFilter) {
    params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
  }

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getRmMagazineMetrics = (month: string, year: number) => {
  const url = '/abo/reports';
  const params = { month, year };

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getRmMagazineMetricsTableData = (
  month: string,
  year: number,
  page = 1,
  perPage = 10,
  status: string,
  sortByFilter?: { [key: string]: string },
) => {
  const url = '/abo/table-reports';
  const params = { month, year, page, perPage, status };
  if (sortByFilter) {
    params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
  }

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const getRmMagazineMetricsBySearch = (
  searchQuery: string,
  month: string,
  year: number,
  page = 1,
  perPage = 10,
  status: string,
  sortByFilter?: { [key: string]: string },
) => {
  const url = '/abo/metrics/reports/search';
  const params = {
    searchQuery,
    month,
    year,
    page,
    perPage,
    status,
  };
  if (sortByFilter) {
    params.sortBy = `${sortByFilter.key}:${sortByFilter.value}`;
  }

  return instance
    .get(url, { params })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const postRmMagazineGenerate = (
  month: string,
  year: number,
  userEmail: string,
  customerId: string,
  isPreview: boolean,
) => {
  const url = '/admin/referral-marketing/generate-magazine';
  const params = {
    month,
    year,
    userEmail,
    customerId,
    isPreview,
  };
  return instance
    .post(url, params)
    .then((res) => res)
    .catch((error) => Promise.reject(error));
};

const generateReferralMagazineAdmin = (data: GenerateReferralMagazineData) => {
  return postRmMagazineGenerate(
    data.payload.month,
    data.payload.year,
    data.payload.userEmail,
    data.payload.customerId,
    data.isPreview,
  );
};

export const uploadCustomCover = (body = {}) => {
  const url = '/abo/upload-custom-cover';
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const startMonthlyTurnOver = (body = {}) => {
  const url = 'admin/referral-marketing/monthly-turn-over';
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const getMonthlyTurnOverStatus = () => {
  const url = 'admin/referral-marketing/monthly-turn-over';

  return instance
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);
};

const schedulePermanentLinkTurnOver = (body = {}) => {
  const url = 'admin/referral-marketing/permanent-link-turn-over';
  return instance
    .post(url, body)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const getSchedulePermanentLinkTurnOver = () => {
  const url = 'admin/referral-marketing/permanent-link-turn-over';

  return instance
    .get(url)
    .then((res) => res.data)
    .catch((error) => error);
};

const getMagazineAdmin = ({ queryKey }: { queryKey: QueryKeyType }) => {
  const [, year, month, customerId] = queryKey;
  return instance
    .get(`/admin/referral-marketing/${year}/${month}/${customerId}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getGeneratedMagazineAdmin = ({
  queryKey,
}: {
  queryKey: QueryKeyType;
}) => {
  const [, year, month, customerId] = queryKey;
  return instance
    .get(
      `/admin/referral-marketing/generated-magazines/${year}/${month}/${customerId}`,
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const updateReferralMagazineAdmin = (req: UpdateReferralMagazineRequest) => {
  const { month, year, customerId, ...rest } = req;

  return instance
    .patch(
      `/admin/referral-marketing/${year}/${month}/${customerId}`,
      rest?.payload,
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const createSetupTicket = ({
  id,
  customerId,
}: {
  id: string;
  customerId: string;
}) => {
  return instance
    .post(`/admin/referral-marketing/${id}/${customerId}/create-ticket`, {})
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

const getMagazine = async (year: number, month: string) => {
  try {
    const response = await instance.get(
      `/referral-marketing/magazines/${year}/${month}`,
    );
    return response.data;
  } catch (error) {
    return await Promise.reject(error);
  }
};

const getCustomerDetails = async (email: string) => {
  try {
    const response = await instance.get(`/customers/find/filter/`, {
      params: { query: email },
    });
    return response.data;
  } catch (error) {
    return await Promise.reject(error);
  }
};

const getMagazineRecipients = async (page, perPage, keyword) => {
  try {
    const response = await instance.get(
      `customers/count-leads?&page=${page}&perPage=${perPage}&nameOrEmail=${keyword}&isRMDeliverable=true`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getMagazineRecipientLeads = async (
  customerId,
  page,
  perPage,
  keyword,
) => {
  try {
    const response = await instance.get(
      `customers/get-leads/${customerId}?page=${page}&perPage=${perPage}&nameOrEmail=${keyword}&isRMDeliverable=true`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getAllCSVLeads = async () => {
  try {
    const response = await instance.post(
      `email-campaigns/leads/export-all-leads`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getOneCSVLead = async (customerId: string) => {
  try {
    const response = await instance.get(
      `email-campaigns/leads/export-leads/${customerId}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getOneCustomerDetail = async (customerId: string) => {
  try {
    const response = await instance.get(`customers/find-one/${customerId}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export {
  getAllMagazineMetrics,
  getRmMagazineMetrics,
  getRmMagazineMetricsBySearch,
  getRmMagazineMetricsEditing,
  getRmMagazineMetricsSentToPrint,
  getRmMagazineMetricsMagazineGenerated,
  postRmMagazineGenerate,
  generateReferralMagazineAdmin,
  startMonthlyTurnOver,
  getMonthlyTurnOverStatus,
  schedulePermanentLinkTurnOver,
  getSchedulePermanentLinkTurnOver,
  getMagazineAdmin,
  updateReferralMagazineAdmin,
  createSetupTicket,
  getMagazine,
  getCustomerDetails,
  getRmMagazineMetricsTableData,
  getMagazineRecipients,
  getMagazineRecipientLeads,
  getAllCSVLeads,
  getOneCSVLead,
  getOneCustomerDetail,
};
