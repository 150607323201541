import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  SvgPlusIcon,
  BackArrow,
  PendingIcon,
} from '../../../../../components/icons';
import BatchOrdersTable from './BatchOrdersTable';
import { useMOD } from '../context/MODContext';
import Loader from '../../../../../modules/components/Loaders';

const BatchDetailView: React.FC = () => {
  const { batchId } = useParams<{ batchId: string }>();
  const navigate = useNavigate();
  const { currentBatch, fetchBatchById, isLoading, setCurrentTab } = useMOD();

  useEffect(() => {
    if (batchId) {
      fetchBatchById(batchId);
    }
  }, [batchId, fetchBatchById]);

  if (isLoading) {
    return <Loader />;
  }

  if (!currentBatch) {
    return <div>Batch not found</div>;
  }

  const handleBack = () => {
    navigate('/mail-on-demand');
    setCurrentTab('batches');
  };

  return (
    <div className="flex flex-col gap-6 pb-28">
      <div
        className="inline-flex w-[100px] px-2.5 py-2 items-center gap-2 cursor-pointer"
        onClick={handleBack}
      >
        <BackArrow className="w-5 h-5" />
        <span className="text-neutral-700 text-sm font-medium">Back</span>
      </div>

      <div className="flex items-center gap-2">
        <h2 className="text-neutral-800 text-2xl font-extrabold leading-7">
          Batch# {currentBatch?.id}
        </h2>
        <div className="px-2 py-1 bg-error-50 rounded-md flex items-center gap-2">
          <PendingIcon className="w-4 h-4" />
          <div className="text-error-500 text-sm font-semibold leading-tight">
            Pending: Upload shipment tracking file
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-6">
        <div className="bg-white rounded-lg shadow">
          <div className="px-6 py-[22px] border-b border-neutral-200">
            <div className="text-boxTitle text-base font-semibold leading-tight">
              Upload shipment tracking file
            </div>
          </div>

          <div className="p-6">
            <div className="relative h-[148px] px-[28px] py-[24px] bg-white rounded-md border border-neutral-300 border-dashed flex flex-col justify-center items-center gap-2">
              <div className="flex flex-col items-center gap-2">
                <div className="w-5 h-6">
                  <SvgPlusIcon />
                </div>
                <div className="flex items-center gap-1">
                  <span className="text-primary-500 text-sm font-semibold">
                    Upload file
                  </span>
                  <span className="text-neutral-600 text-sm font-semibold">
                    or drag and drop
                  </span>
                </div>
              </div>
              <input
                type="file"
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                accept=".csv,.xlsx,.xls"
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => {
                  const file = e.target.files?.[0];
                  if (file) {
                    console.log('File selected:', file);
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow">
          <BatchOrdersTable />
        </div>
      </div>
    </div>
  );
};

export default BatchDetailView;
