import React, { useState, useEffect } from "react";

interface DropdownTableProps {
    id: string;
    title: string;
    children?: React.ReactNode;
    alignment?: "left" | "center" | "right";
}

const DropdownTable: React.FC<DropdownTableProps> = ({ id, title, alignment = 'right', children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (e: MouseEvent) => {
        const dropdown = document.getElementById(`dropdown-content-${id}`);
        const button = document.getElementById(`dropdown-${id}`);

        if (dropdown && !dropdown.contains(e.target as Node) && button && !button.contains(e.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const alignmentClasses = alignment === "right"
        ? "right-0"
        : alignment === "center"
            ? "left-1/2 transform -translate-x-1/2"
            : "left-0";

    return (
        <div className="relative inline-block">
            <button
                id={`dropdown-${id}`}
                onClick={toggleDropdown}
                className="text-neutral-800 bg-white border border-neutral-200 hover:bg-neutral-50 hover:border-neutral-700 focus:border-neutral-700 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800"
                type="button"
            >
                {title}
                <svg
                    className="w-2.5 h-2.5 ml-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                >
                    <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                    />
                </svg>
            </button>

            {/* Toggle the visibility of the dropdown */}
            <div
                id={`dropdown-content-${id}`}
                className={`absolute z-50 ${isOpen ? 'block' : 'hidden'} bg-white divide-y divide-gray-100 rounded-lg shadow w-fit dark:bg-gray-700 mt-1 ${alignmentClasses}`}
                style={{ top: "100%" }}
            >
                <ul
                    className="py-2 text-sm font-medium"
                    aria-labelledby={`dropdown-${id}`}
                >
                    {children}
                </ul>
            </div>
        </div>
    );
};

export default DropdownTable;
