import React, { useState, useEffect, useRef } from 'react';
import { Checkbox } from 'flowbite-react';
import { ChevronDown, ChevronUp } from '../../components/icons';

interface DraftName {
  title: string;
  draftId: string;
}

interface MODDropdownProps {
  options: DraftName[];
  selectedValues: string[];
  onSelect: (draftIds: string[]) => void;
  placeholder?: string;
}

const MODDropdown: React.FC<MODDropdownProps> = ({
  options,
  selectedValues,
  onSelect,
  placeholder = 'All Books',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (draftId: string) => {
    const newSelectedValues = selectedValues.includes(draftId)
      ? selectedValues.filter((id) => id !== draftId)
      : [...selectedValues, draftId];
    onSelect(newSelectedValues);
  };

  const handleRemove = (e: React.MouseEvent, draftId: string) => {
    e.stopPropagation();
    onSelect(selectedValues.filter((id) => id !== draftId));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getSelectedBooks = () => {
    if (selectedValues.length === 0) return placeholder;

    const selectedBooks = options
      .filter((option) => selectedValues.includes(option.draftId))
      .map((option) => option);

    if (selectedBooks.length === 1) {
      const book = selectedBooks[0];
      return (
        <div className="flex items-center gap-2 w-full">
          <div className="flex items-center gap-1 bg-neutral-100 text-neutral-900 text-xs font-medium px-2.5 py-0.5 rounded-[6px]">
            <span className="truncate max-w-[160px]">{book.title}</span>
            <button
              onClick={(e) => handleRemove(e, book.draftId)}
              className="ml-1 hover:text-neutral-700 flex-shrink-0"
            >
              ×
            </button>
          </div>
        </div>
      );
    }

    const firstBook = selectedBooks[0];
    return (
      <div className="flex items-center gap-2 w-full">
        <div className="inline-flex items-center gap-2 flex-nowrap mr-6">
          <div className="flex items-center gap-1 bg-neutral-100 text-neutral-900 text-xs font-medium px-2.5 py-0.5 rounded-[6px]">
            <span className="truncate max-w-[120px]">{firstBook.title}</span>
            <button
              onClick={(e) => handleRemove(e, firstBook.draftId)}
              className="ml-1 hover:text-neutral-700 flex-shrink-0"
            >
              ×
            </button>
          </div>
          <div className="bg-neutral-100 text-neutral-900 text-xs font-medium px-2.5 py-0.5 rounded-[6px] flex-shrink-0">
            +{selectedBooks.length - 1}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div ref={dropdownRef} className="relative">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="bg-white border border-neutral-200 rounded-lg w-[250px] px-3 py-2.5 text-sm font-medium text-neutral-900 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-primary-500 text-left"
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex-1 min-w-0">{getSelectedBooks()}</div>
          {isOpen ? (
            <ChevronUp className="w-4 h-4 text-neutral-600 flex-shrink-0" />
          ) : (
            <ChevronDown className="w-4 h-4 text-neutral-600 flex-shrink-0" />
          )}
        </div>
      </button>

      {isOpen && (
        <div className="absolute z-10 w-[450px] mt-2 bg-white border border-neutral-200 rounded-lg shadow-lg">
          <div className="py-2 max-h-[300px] overflow-y-auto">
            {options.map((option) => (
              <div
                key={option.draftId}
                className="px-4 py-2 hover:bg-neutral-50 cursor-pointer"
                onClick={() => handleSelect(option.draftId)}
              >
                <div className="flex items-center gap-3">
                  <Checkbox
                    checked={selectedValues.includes(option.draftId)}
                    onChange={() => {}}
                    className="rounded border-neutral-300"
                  />
                  <span className="text-sm font-medium text-neutral-800 truncate">
                    {option.title}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MODDropdown;
