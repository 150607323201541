import * as React from 'react';
import type { SVGProps } from 'react';

const SvgIconer = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g fill="#fff">
      <path d="M6.333 1.334a1 1 0 0 0-1 1V3a1 1 0 0 0 1 1h3.334a1 1 0 0 0 1-1v-.667a1 1 0 0 0-1-1z" />
      <path
        fillRule="evenodd"
        d="M4.333 2.691c-.838.047-1.368.18-1.747.56C2 3.837 2 4.781 2 6.666v4c0 1.885 0 2.828.586 3.414s1.528.586 3.414.586h4c1.886 0 2.828 0 3.414-.586S14 12.55 14 10.666v-4c0-1.886 0-2.829-.586-3.415-.38-.38-.909-.513-1.747-.56v.31a2 2 0 0 1-2 2H6.333a2 2 0 0 1-2-2zm.334 6.476a.5.5 0 1 0 0 1H10a.5.5 0 0 0 0-1zm0 2.333a.5.5 0 1 0 0 1h3.666a.5.5 0 0 0 0-1z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);
export default SvgIconer;
