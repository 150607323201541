import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  MODContextType,
  Order,
  ID,
  Batch,
  GroupedOrdersResponse,
  OrderGroup,
  OrderStatus,
  DraftName,
} from '../types';
import {
  fetchMailOnDemandOrders,
  fetchBatches,
  fetchBatchById as apiFetchBatchById,
  createBatch,
  fetchUniqueDraftNames,
} from '../../../../api/mail-on-demand';

const MODContext = createContext<MODContextType | undefined>(undefined);

export const MODProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [orderGroups, setOrderGroups] = useState<OrderGroup[]>([]);
  const [selectedOrders, setSelectedOrders] = useState<ID[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [currentTab, setCurrentTab] = useState<'awaiting' | 'batches'>(
    'awaiting',
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState<{ from: Date; to: Date } | null>(
    null,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortByfilter, setSortByfilter] = useState({
    key: 'dateOrdered',
    value: 'asc' as 'asc' | 'desc',
  });
  const [batches, setBatches] = useState<Batch[]>([]);
  const [currentBatch, setCurrentBatch] = useState<Batch | null>(null);
  const [batchOrders, setBatchOrders] = useState<Order[]>([]);
  const [batchesTotal, setBatchesTotal] = useState(0);
  const [statusFilter, setStatusFilter] = useState<
    'All' | 'Pending' | 'Uploaded'
  >('All');
  const [totalOrders, setTotalOrders] = useState(0);
  const [draftNames, setDraftNames] = useState<DraftName[]>([]);
  const [selectedDraftName, setSelectedDraftName] = useState<string[]>([]);

  const toggleGroupExpansion = useCallback(
    (customerId: string, draftId: string) => {
      setOrderGroups((prevGroups) =>
        prevGroups.map((group) =>
          group.customerId === customerId && group.draftId === draftId
            ? { ...group, isExpanded: !group.isExpanded }
            : group,
        ),
      );
    },
    [],
  );

  const handleGroupSelection = useCallback(
    (customerId: string, draftId: string, selected: boolean) => {
      const group = orderGroups.find(
        (g) => g.customerId === customerId && g.draftId === draftId,
      );
      if (!group) return;

      const orderIds = group.orders.map((order) => order.orderId);
      setSelectedOrders((prev) => {
        if (selected) {
          const newSelection = [...prev];
          orderIds.forEach((id) => {
            if (!newSelection.includes(id)) {
              newSelection.push(id);
            }
          });
          return newSelection;
        } else {
          return prev.filter((id) => !orderIds.includes(id));
        }
      });
    },
    [orderGroups],
  );

  const fetchBatchList = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetchBatches(
        currentPage,
        pageSize,
        statusFilter,
        sortByfilter.key,
        sortByfilter.value as 'asc' | 'desc',
      );
      setBatches(response.data);
      setBatchesTotal(response.total);
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error('Failed to fetch batches'),
      );
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, pageSize, statusFilter, sortByfilter]);

  useEffect(() => {
    if (currentTab === 'batches') {
      fetchBatchList();
    }
  }, [currentPage, pageSize, statusFilter, currentTab]);

  const fetchBatchDetails = useCallback(async (batchId: string) => {
    setIsLoading(true);
    try {
      const response = await apiFetchBatchById(batchId);
      if (!response) {
        throw new Error('Batch not found');
      }

      const transformedBatch = {
        id: response.id,
        modOrderIds: response.modOrderIds,
        status: response.status,
        createdAt: response.createdAt,
        updatedAt: response.updatedAt,
      };

      const transformedOrders = response.orders.map((order) => ({
        orderId: order.id,
        orderNumber: order.orderNumber,
        status: order.status,
        dateOrdered: order.dateOrdered,
        statusHistory: order.statusHistory,
        quantity: order.quantity,
        customerName: `${order.customer.firstName} ${order.customer.lastName}`,
        modQueue: {
          draftName: order.modQueue.draftName,
          _id: order.modQueue._id,
        },
        selectedLeads: [
          {
            id: order.modQueue._id,
            name: `${order.modQueue.firstName} ${order.modQueue.lastName}`,
            email: order.modQueue.email,
            contactNumber: order.modQueue.phone,
            address: order.modQueue.address
              ? {
                  city: order.modQueue.address.city,
                  state: order.modQueue.address.state,
                  country: order.modQueue.address.country,
                }
              : null,
          },
        ],
        customer: {
          name: `${order.customer.firstName} ${order.customer.lastName}`,
          email: order.customer.email,
          phone: order.customer.phone,
        },
      }));

      setCurrentBatch(transformedBatch);
      setBatchOrders(transformedOrders);
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch batch'));
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchOrders = useCallback(
    async (startDate?: string, endDate?: string) => {
      setIsLoading(true);
      try {
        const response = await fetchMailOnDemandOrders(
          currentPage,
          pageSize,
          sortByfilter.key,
          sortByfilter.value,
          selectedDraftName.length > 0 ? selectedDraftName : undefined,
          startDate,
          endDate,
        );

        const transformedGroups: OrderGroup[] = response.data.map((group) => ({
          customerId: group.customerId,
          draftId: group.draftId,
          totalOrders: group.totalOrders,
          orders: group.orders.map((order) => ({
            orderId: order.orderId,
            orderNumber: order.orderNumber,
            customerName: order.customerName,
            customer: order.customer,
            selectedLeads: order.selectedLeads,
            status: order.status as OrderStatus,
            dateOrdered: new Date(order.dateOrdered),
            statusHistory: order.statusHistory.map((history) => ({
              status: history.status as OrderStatus,
              updatedAt: new Date(history.updatedAt),
            })),
            book: order.book,
            quantity: 1,
            draftId: order.draftId,
          })),
          isExpanded: false,
        }));

        setOrderGroups(transformedGroups);
        setTotalOrders(response.meta.total);
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error('Failed to fetch orders'),
        );
      } finally {
        setIsLoading(false);
      }
    },
    [currentPage, pageSize, sortByfilter, selectedDraftName],
  );

  useEffect(() => {
    const loadInitialData = async () => {
      setIsLoading(true);
      try {
        const [ordersData, batchesData] = await Promise.all([
          fetchMailOnDemandOrders(
            currentPage,
            pageSize,
            sortByfilter.key,
            sortByfilter.value,
          ),
          fetchBatches(),
        ]);

        setOrderGroups(
          ordersData.data.map((group) => ({
            customerId: group.customerId,
            draftId: group.draftId,
            totalOrders: group.totalOrders,
            orders: group.orders.map((order) => ({
              orderId: order.orderId,
              orderNumber: order.orderNumber,
              customerName: order.customerName,
              customer: order.customer,
              selectedLeads: order.selectedLeads,
              status: order.status as OrderStatus,
              dateOrdered: new Date(order.dateOrdered),
              statusHistory: order.statusHistory.map((history) => ({
                status: history.status as OrderStatus,
                updatedAt: new Date(history.updatedAt),
              })),
              book: order.book,
              quantity: 1,
              draftId: order.draftId,
            })),
            isExpanded: false,
          })),
        );
        setTotalOrders(ordersData.meta.total);
        setBatches(batchesData.data);
        setBatchesTotal(batchesData.total);
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error('Failed to fetch initial data'),
        );
      } finally {
        setIsLoading(false);
      }
    };

    loadInitialData();
  }, [currentPage, pageSize, sortByfilter]);

  useEffect(() => {
    const loadDraftNames = async () => {
      try {
        const names = await fetchUniqueDraftNames();
        setDraftNames(names);
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error('Failed to fetch draft names'),
        );
      }
    };
    loadDraftNames();
  }, []);

  const awaitingOrdersCount = orderGroups.reduce((acc, group) => {
    const inReviewOrders = group.orders.filter(
      (order) => order.status === 'In Review',
    ).length;
    return acc + inReviewOrders;
  }, 0);

  const batchesCount = batchesTotal ?? 0;

  const processOrders = useCallback(async (orderIds: string[]) => {
    setIsLoading(true);
    try {
      await createBatch(orderIds);
      setSelectedOrders([]);

      const batchesData = await fetchBatches();
      setBatches(batchesData.data);
      setBatchesTotal(batchesData.total);

      const ordersData = await fetchMailOnDemandOrders();
      setOrderGroups(
        ordersData.data.map((group) => ({
          customerId: group.customerId,
          draftId: group.draftId,
          totalOrders: group.totalOrders,
          orders: group.orders.map((order) => ({
            orderId: order.orderId,
            orderNumber: order.orderNumber,
            customerName: order.customerName,
            customer: order.customer,
            selectedLeads: order.selectedLeads,
            status: order.status as OrderStatus,
            dateOrdered: new Date(order.dateOrdered),
            statusHistory: order.statusHistory.map((history) => ({
              status: history.status as OrderStatus,
              updatedAt: new Date(history.updatedAt),
            })),
            book: order.book,
            quantity: 1,
            draftId: order.draftId,
          })),
          isExpanded: false,
        })),
      );
      setTotalOrders(ordersData.meta.total);
    } catch (err) {
      setError(
        err instanceof Error ? err : new Error('Failed to process orders'),
      );
    } finally {
      setIsLoading(false);
    }
  }, []);

  const value = {
    orderGroups,
    selectedOrders,
    isLoading,
    error,
    currentTab,
    searchTerm,
    dateRange,
    setSelectedOrders,
    setCurrentTab,
    setSearchTerm,
    setDateRange,
    currentPage,
    pageSize,
    setCurrentPage,
    setPageSize,
    batches,
    currentBatch,
    batchOrders,
    fetchBatchList,
    fetchBatchById: fetchBatchDetails,
    fetchOrders,
    awaitingOrdersCount,
    batchesCount,
    batchesTotal,
    processOrders,
    sortByfilter,
    setSortByfilter,
    statusFilter,
    setStatusFilter,
    toggleGroupExpansion,
    handleGroupSelection,
    draftNames,
    selectedDraftName,
    setSelectedDraftName,
  };

  return <MODContext.Provider value={value}>{children}</MODContext.Provider>;
};

export const useMOD = () => {
  const context = useContext(MODContext);
  if (context === undefined) {
    throw new Error('useMOD must be used within a MODProvider');
  }
  return context;
};
