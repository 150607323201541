import * as React from 'react';
import type { SVGProps } from 'react';
const PendingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#E4483D"
      strokeWidth={1.5}
      d="M7.5 8 5.651 9.83c-1.826 1.81-2.74 2.714-2.484 3.494q.034.1.082.195c.376.731 1.668.731 4.251.731s3.875 0 4.251-.73q.049-.096.082-.196c.255-.78-.658-1.685-2.484-3.493zm0 0 1.849-1.83c1.826-1.81 2.74-2.714 2.484-3.494a1.3 1.3 0 0 0-.082-.195c-.376-.731-1.668-.731-4.251-.731s-3.875 0-4.251.73q-.048.096-.082.196c-.255.78.658 1.685 2.484 3.493z"
    />
  </svg>
);
export default PendingIcon;
