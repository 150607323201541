import React, { useCallback, useState } from 'react';
import Title from '../../../components/Title';
import { TextInput } from 'flowbite-react';
import Button from '../../../components/Button';
import { SvgDownload } from '../../../../components/icons';
import PaginationFlowBite from '../../../components/PaginationFlowBite';
import { useNavigate } from 'react-router-dom';
import {
  getAllCSVLeads,
  getMagazineRecipients,
  getOneCSVLead,
} from '../../../api/rm-magazine-report';
import { useQuery } from 'react-query';
import SkeletonLoader from '../../../components/SkeletonLoader';
import { generateCsvDownload } from '../../../../utils/csvDownload';
import { debounce } from 'lodash-es';

function MagazineRecipient(props) {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [debouncedKeyword, setDebouncedKeyword] = useState('');
  const [data, setData] = useState([]);
  const [metaData, setMetada] = useState({} as any);

  const { isLoading } = useQuery(
    ['magazineRecipient', debouncedKeyword, page, perPage],
    () => getMagazineRecipients(page, perPage, debouncedKeyword),
    {
      onSuccess: (response) => {
        setData(response.data);
        setMetada(response.meta);
      },
      cacheTime: 0,
    },
  );

  const handleExportAll = () => {
    getAllCSVLeads().then((response) =>
      generateCsvDownload(response, 'MagazineRecipients'),
    );
  };

  const handleExportOne = (customerId) => {
    getOneCSVLead(customerId).then((response) =>
      generateCsvDownload(response, customerId),
    );
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePerPage = (value, firstItemIndex) => {
    const perPage = value.value;
    const pageNumber = Math.ceil(firstItemIndex / perPage) || 1;
    setPerPage(perPage);
    setPage(pageNumber);
  };

  const handleTextInput = (e) => {
    debouncedSearch(e.target.value);
  };

  const tablePagination = {
    pageSize: metaData.perPage,
    total: metaData.total,
    lastPage: metaData.lastPage,
    currentPage: metaData.currentPage + 1,
    onChange: handlePageChange,
  };

  const debouncedSearch = useCallback(
    debounce((search) => {
      setDebouncedKeyword(search);
    }, 500),
    [],
  );

  return (
    <div className="pt-3 min-h-screen pb-[100px]">
      <Title>Magazine recipients</Title>
      <div className="shadow-sm bg-white rounded-lg mt-6">
        <div className="flex justify-between border-b border-neutral-200 py-[22px] px-6 gap-6">
          <TextInput
            placeholder="Search for member's name or email"
            className="w-[494px]"
            onChange={handleTextInput}
          />
          <Button
            variant="LIGHT"
            className="bg-neutral-100 border-0 whitespace-nowrap font-700 gap-2"
            onClick={handleExportAll}
          >
            <SvgDownload className="w-5 h-5 mr-2" />
            Export
          </Button>
        </div>
        {!isLoading ? (
          <>
            <div className="p-6 flex flex-col gap-[14px]">
              {data.map((user) => {
                return (
                  <button
                    key={user.customerId}
                    className="border border-neutral-200 rounded-md py-[14px] px-6 flex justify-between items-center hover:bg-neutral-100 hover:cursor-pointer"
                    onClick={() => {
                      navigate(`/magazine-recipient/${user.customerId}`);
                    }}
                  >
                    <div className="flex flex-col">
                      <div className="flex gap-2">
                        <span className="font-700 text-sm">
                          {user.customerName}
                        </span>
                        <span className="text-xs font-600 text-secondary-orange-600 bg-secondary-orange-50 py-1 px-2 rounded-md">
                          {user.leadCount}{' '}
                          {user.leadCount > 1 ? 'leads' : 'lead'}
                        </span>
                      </div>
                      <span className="text-sm text-neutral-500 text-left">
                        {user.customerEmail}
                      </span>
                      <span className="text-sm text-neutral-500 text-left">
                        {user.customerPhone}
                      </span>
                    </div>
                    <Button
                      variant="LIGHT"
                      className="w-[34px] h-[34px]"
                      size=""
                      onClick={(e) => {
                        e.stopPropagation();
                        handleExportOne(user.customerId);
                      }}
                    >
                      <SvgDownload className="w-4 h-4" />
                    </Button>
                  </button>
                );
              })}
            </div>
            <div className="border-t border-neutral-200 px-6 py-[22px]">
              <PaginationFlowBite
                tablePagination={tablePagination}
                handlePerPage={handlePerPage}
              />
            </div>
          </>
        ) : (
          <SkeletonLoader />
        )}
      </div>
    </div>
  );
}

export default MagazineRecipient;
