import { BookPackageOptionProps, ProductFamilyProps } from "./types";
import { uniqueId } from 'lodash';

const getRenewInterval = (givenInterval): 'monthly' | 'yearly' => {
  if (givenInterval.includes('annual')) return 'yearly';
  return 'monthly';
}

export const formatProductFamilies = (productsFamilies: ProductFamilyProps) => {
  const productsOptions = Object.entries(productsFamilies).reduce((acc, [k, v]) => {
    const family = k;

    acc.push({
      id: uniqueId(),
      key: uniqueId(),
      label: (
        <span className="text-xs text-neutral-500 font-semibold uppercase">
          {family}
        </span>
      ),
      value: family,
      notAllowed: true,
    } as BookPackageOptionProps);

    v.forEach(
      ({ _id, isProductCatalogAllowed, chargifyProductHandle, title, type, product, value }) => {
        if (isProductCatalogAllowed) {
          acc.push({
            id: _id,
            label: (
              <div>
                <p>{title}</p>
                <p className="text-neutral-400">{type}</p>
              </div>
            ),
            value: _id,
            productData: { title, type, value, renewInterval: getRenewInterval(chargifyProductHandle) },
            notAllowed: false,
            selectedLabel: product,
            key: _id,
          });
        }
      },
    );
    return acc;
  }, [] as BookPackageOptionProps[]);

  return productsOptions;
};
