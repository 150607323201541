import * as React from 'react';
import type { SVGProps } from 'react';

const SvgInProcessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#fff"
      d="M3.466 2.2c.402-.867 1.78-.867 4.535-.867s4.133 0 4.534.867q.053.111.087.23c.273.926-.701 1.998-2.65 4.141l-1.305 1.43 1.305 1.428c1.949 2.144 2.923 3.216 2.65 4.14q-.034.12-.087.232c-.401.866-1.779.866-4.534.866s-4.133 0-4.535-.866a2 2 0 0 1-.087-.232c-.273-.924.702-1.996 2.65-4.14L7.334 8 6.029 6.571c-1.948-2.143-2.923-3.215-2.65-4.14a2 2 0 0 1 .087-.231"
    />
  </svg>
);
export default SvgInProcessIcon;
