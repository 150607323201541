export enum BackgroundJobStatus {
  DONE = 'DONE',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
}

export enum BackgroundJobType {
  MONTHLY_TURN_OVER = 'MONTHLY_TURN_OVER',
  RECURRING_TICKET = 'RECURRING_TICKET',
  GO_LIVE = 'GO_LIVE',
}

export const formatBackgroundJobType = (type) => {
  const formattedType = {
    MONTHLY_TURN_OVER: 'Turn Over',
    RECURRING_TICKET: 'Recurring Tickets',
    GO_LIVE: 'Go Live'
  }

  return formattedType[type] || '';
}
