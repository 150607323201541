import React, { useState } from 'react';
import { useMOD } from '../context/MODContext';
import { Checkbox, Flowbite } from 'flowbite-react';
import { customCheckboxTheme } from '../../../../../utils/customTheme';
import PaginationFlowBite from '../../../../components/PaginationFlowBite';
import SkeletonLoader from '../../../../components/SkeletonLoader';
import OrderDetailsDrawer from './OrderDetailsDrawer';
import { Order, Address } from '../types';
import {
  ShipmentIcon,
  ChevronDown,
  ChevronUp,
  UndoCircle,
} from '../../../../../components/icons';
import Button from '../../../../components/Button';

interface OrdersTableProps {
  onProcessClick: () => void;
}

const OrdersTable: React.FC<OrdersTableProps> = ({ onProcessClick }) => {
  const {
    orderGroups,
    selectedOrders,
    setSelectedOrders,
    searchTerm,
    dateRange,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    isLoading,
    sortByfilter,
    setSortByfilter,
    toggleGroupExpansion,
    handleGroupSelection,
  } = useMOD();

  const [selectedOrderForDrawer, setSelectedOrderForDrawer] =
    useState<Order | null>(null);

  const formatAddress = (address: Address | undefined): string => {
    if (!address) return '';
    const parts = [
      address.address1,
      address.address2,
      address.city,
      address.state,
      address.zip,
      address.country,
    ].filter(Boolean);
    return parts.join(', ');
  };

  const filteredGroups = orderGroups.filter((group) => {
    const searchTermLower = searchTerm?.toLowerCase() || '';
    const matchesSearch =
      !searchTerm ||
      group.orders.some(
        (order) =>
          order.orderNumber.toLowerCase().includes(searchTermLower) ||
          order.customerName.toLowerCase().includes(searchTermLower) ||
          order.selectedLeads.some(
            (lead) =>
              lead.name.toLowerCase().includes(searchTermLower) ||
              lead.email.toLowerCase().includes(searchTermLower) ||
              lead.contactNumber?.toLowerCase().includes(searchTermLower) ||
              formatAddress(lead.address as Address)
                .toLowerCase()
                .includes(searchTermLower),
          ),
      );

    const matchesDate =
      !dateRange ||
      group.orders.some(
        (order) =>
          new Date(order.dateOrdered) >= new Date(dateRange.from) &&
          new Date(order.dateOrdered) <= new Date(dateRange.to),
      );

    return matchesSearch && matchesDate;
  });

  const totalPages = Math.ceil(filteredGroups.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const paginatedGroups = filteredGroups.slice(
    startIndex,
    startIndex + pageSize,
  );

  const handleSort = (columnKey: string) => {
    setCurrentPage(1);
    if (sortByfilter.key === columnKey) {
      const isAsc = sortByfilter.value === 'asc';
      setSortByfilter({
        key: columnKey,
        value: isAsc ? 'desc' : 'asc',
      });
    } else {
      const defaultOrder = columnKey === 'dateOrdered' ? 'asc' : 'desc';
      setSortByfilter({ key: columnKey, value: defaultOrder });
    }
  };

  const handleOrderClick = (order: Order) => {
    setSelectedOrderForDrawer(order);
  };

  const handleCloseDrawer = () => {
    setSelectedOrderForDrawer(null);
  };

  const handlePerPage = (selectedOption: { value: number }) => {
    setPageSize(selectedOption.value);
    setCurrentPage(1);
  };

  const calculateAge = (dateOrdered: Date): string => {
    const days = Math.floor(
      (new Date().getTime() - new Date(dateOrdered).getTime()) /
        (1000 * 3600 * 24),
    );
    return `${days} days`;
  };

  const getOldestOrderDate = (orders: Order[]): Date => {
    return new Date(
      Math.min(...orders.map((order) => new Date(order.dateOrdered).getTime())),
    );
  };

  const clearSelection = () => {
    setSelectedOrders([]);
  };

  const totalRecipients = orderGroups.reduce(
    (acc, group) => acc + group.totalOrders,
    0,
  );

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center px-6 py-4 bg-white border-b border-neutral-200">
        <div className="flex items-center gap-2">
          <h2 className="text-xl font-semibold text-neutral-800">
            All <span className="text-neutral-500">({totalRecipients})</span>
          </h2>
        </div>
        <Button
          onClick={onProcessClick}
          disabled={selectedOrders.length === 0}
          variant="PRIMARY"
        >
          Process Printing ({selectedOrders.length})
        </Button>
      </div>

      {selectedOrders.length > 0 && (
        <div className="flex items-center gap-4 px-6 py-4 bg-white border-b border-neutral-200">
          <span className="text-neutral-800">
            <span className="font-semibold">{selectedOrders.length}</span>{' '}
            recipients selected
          </span>
          <button
            onClick={clearSelection}
            className="flex justify-center items-center gap-2 px-[18px] py-[13px] pl-4 bg-white border border-neutral-200 rounded-[6px] hover:bg-neutral-50"
          >
            <UndoCircle className="w-4 h-4 text-neutral-500" />
            <span className="text-[14px] font-bold text-neutral-900 leading-5">
              Clear Selection
            </span>
          </button>
        </div>
      )}

      <div className="overflow-x-auto border border-neutral-200 rounded-t-lg">
        {isLoading ? (
          <SkeletonLoader rows={pageSize} columns={6} />
        ) : (
          <table className="w-full">
            <thead>
              <tr className="bg-white border-b border-neutral-200">
                <th className="w-[64px] pl-6 py-3">
                  <div className="flex items-center justify-start">
                    <Flowbite theme={{ theme: customCheckboxTheme }}>
                      <Checkbox
                        checked={selectedOrders.length === totalRecipients}
                        onChange={() => {
                          if (selectedOrders.length === totalRecipients) {
                            setSelectedOrders([]);
                          } else {
                            setSelectedOrders(
                              orderGroups.flatMap((group) =>
                                group.orders.map((order) => order.orderId),
                              ),
                            );
                          }
                        }}
                      />
                    </Flowbite>
                  </div>
                </th>
                <th className="w-[140px] px-6 py-3 text-left">
                  <span className="text-neutral-800 text-sm font-semibold leading-tight">
                    Ordered By
                  </span>
                </th>
                <th className="w-[200px] px-6 py-3 text-left">
                  <span className="text-neutral-800 text-sm font-semibold leading-tight">
                    Title
                  </span>
                </th>
                <th className="w-[170px] px-6 py-3 text-left">
                  <span className="text-neutral-800 text-sm font-semibold leading-tight">
                    Ordered On
                  </span>
                </th>
                <th className="w-[100px] px-6 py-3 text-left">
                  <span className="text-neutral-800 text-sm font-semibold leading-tight">
                    Recipients
                  </span>
                </th>
                <th className="w-[170px] px-6 py-3 text-left">
                  <span className="text-neutral-800 text-sm font-semibold leading-tight">
                    Age
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedGroups.map((group) => (
                <React.Fragment key={`${group.customerId}-${group.draftId}`}>
                  <tr
                    className={`bg-white border-b border-neutral-200 ${
                      group.orders.some((order) =>
                        selectedOrders.includes(order.orderId),
                      )
                        ? 'bg-sky-50'
                        : 'hover:bg-sky-50'
                    }`}
                    onClick={() =>
                      handleGroupSelection(
                        group.customerId,
                        group.draftId,
                        !group.orders.every((order) =>
                          selectedOrders.includes(order.orderId),
                        ),
                      )
                    }
                  >
                    <td className="w-[64px] pl-6 py-3.5">
                      <div className="flex items-center gap-4">
                        <Flowbite theme={{ theme: customCheckboxTheme }}>
                          <Checkbox
                            checked={group.orders.every((order) =>
                              selectedOrders.includes(order.orderId),
                            )}
                            onChange={(e) =>
                              handleGroupSelection(
                                group.customerId,
                                group.draftId,
                                e.target.checked,
                              )
                            }
                            onClick={(e) => e.stopPropagation()}
                          />
                        </Flowbite>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleGroupExpansion(
                              group.customerId,
                              group.draftId,
                            );
                          }}
                          className="p-1 rounded border border-neutral-200 hover:bg-neutral-50"
                        >
                          {group.isExpanded ? (
                            <ChevronDown className="w-4 h-4 text-neutral-600" />
                          ) : (
                            <ChevronUp className="w-4 h-4 text-neutral-600" />
                          )}
                        </button>
                      </div>
                    </td>
                    <td className="px-6 py-3.5">
                      <span className="text-neutral-800 text-sm font-semibold">
                        {group.orders[0].customerName}
                      </span>
                    </td>
                    <td className="px-6 py-3.5">
                      <span className="text-neutral-800 text-sm font-semibold">
                        {group.orders[0].book?.title}
                      </span>
                    </td>
                    <td className="px-6 py-3.5">
                      <span className="text-neutral-800 text-sm font-semibold">
                        {new Date(
                          getOldestOrderDate(group.orders),
                        ).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                        })}
                      </span>
                    </td>
                    <td className="px-6 py-3.5">
                      <div className="flex items-center gap-2">
                        <span className="text-neutral-800 text-sm font-semibold">
                          {group.totalOrders}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-3.5">
                      <span className="text-error-500 text-sm font-semibold">
                        {calculateAge(getOldestOrderDate(group.orders))}
                      </span>
                    </td>
                  </tr>
                  {group.isExpanded &&
                    group.orders.map((order) => (
                      <tr
                        key={order.orderId}
                        className={`border-b border-neutral-200 ${
                          selectedOrders.includes(order.orderId)
                            ? 'bg-sky-50'
                            : 'bg-neutral-50'
                        }`}
                      >
                        <td className="w-[64px] pl-6 py-3.5" />
                        <td colSpan={5} className="px-6 py-3.5">
                          <div className="flex items-start gap-6">
                            <div className="flex items-center gap-2 min-w-[140px]">
                              <ShipmentIcon className="w-4 h-4" />
                              <button
                                className="text-neutral-800 text-sm font-semibold underline"
                                onClick={() => handleOrderClick(order)}
                              >
                                {order.orderNumber}
                              </button>
                            </div>
                            {order.selectedLeads.map((lead) => (
                              <div
                                key={lead.id}
                                className="flex items-center text-sm min-h-[24px] flex-1"
                              >
                                <span className="text-neutral-800 font-semibold shrink-0 w-[160px]">
                                  {lead.name}
                                </span>
                                <span className="text-neutral-500 shrink-0 w-[200px]">
                                  {lead.email}
                                </span>
                                <span className="text-neutral-500 shrink-0 w-[140px]">
                                  {lead.contactNumber}
                                </span>
                                <span className="text-neutral-500 min-w-0 flex-1 truncate">
                                  {formatAddress(lead.address as Address)}
                                </span>
                              </div>
                            ))}
                          </div>
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="px-6 py-[22px] bg-white border-x border-b border-neutral-200 rounded-b-lg">
        <PaginationFlowBite
          tablePagination={{
            pageSize,
            total: filteredGroups.length,
            lastPage: totalPages,
            currentPage,
            onChange: setCurrentPage,
          }}
          handlePerPage={handlePerPage}
        />
      </div>
      {selectedOrderForDrawer && (
        <OrderDetailsDrawer
          order={selectedOrderForDrawer}
          isOpen={!!selectedOrderForDrawer}
          onClose={handleCloseDrawer}
          showStatusHistory={false}
        />
      )}
    </div>
  );
};

export default OrdersTable;
