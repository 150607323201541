import React from "react";

const GoLiveIcon = ({ stroke = "white", ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" {...props} >
      <path d="M13.6667 18.3346H7.00006M10.3334 14.168V18.3346M18.6667 10.8346H2.00006M7.00006 14.168H13.6667C16.0237 14.168 17.2023 14.168 17.9345 13.4357C18.6667 12.7035 18.6667 11.525 18.6667 9.16797V8.33464C18.6667 5.19194 18.6667 3.62059 17.6904 2.64428C16.7141 1.66797 15.1428 1.66797 12.0001 1.66797H8.66673C5.52403 1.66797 3.95268 1.66797 2.97637 2.64428C2.00006 3.62059 2.00006 5.19194 2.00006 8.33464V9.16797C2.00006 11.525 2.00006 12.7035 2.73229 13.4357C3.46453 14.168 4.64304 14.168 7.00006 14.168Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round"/>
    </svg>
  );
};

export default GoLiveIcon;