import React from 'react';
import { format } from 'date-fns';
import { CustomFlowbiteTheme } from 'flowbite-react';
import { Order } from '../types';
import {
  InProcessIcon,
  InReviewIcon,
  ShippedIcon,
} from '../../../../../components/icons';
import { handleThumbnail } from '../../../../utils/thumbnail';
import BookIcon from '../../../../../assets/images/BookIcon.png';
import ClosableModal from '../../../../../components/common/ClosableModal';

interface OrderDetailsDrawerProps {
  order: Order;
  isOpen: boolean;
  onClose: () => void;
  showStatusHistory?: boolean;
}

const drawerTheme: CustomFlowbiteTheme['modal'] = {
  root: {
    show: {
      on: 'bg-black bg-opacity-45 flex',
      off: 'hidden',
    },
    positions: {
      'top-right':
        'items-start justify-end transform transition-transform duration-300 ease-in-out',
    },
  },
  content: {
    base: 'relative h-full w-[448px] md:h-auto font-[figtree]',
    inner: 'rounded-xs bg-white shadow-lg',
  },
  body: {
    base: 'px-6',
  },
};

const OrderDetailsDrawer: React.FC<OrderDetailsDrawerProps> = ({
  order,
  isOpen,
  onClose,
  showStatusHistory = false,
}) => {
  const calculateAge = (dateOrdered: Date): string => {
    const days = Math.floor(
      (new Date().getTime() - new Date(dateOrdered).getTime()) /
        (1000 * 3600 * 24),
    );
    return `${days} days`;
  };

  return (
    <ClosableModal
      title={`Order# ${order?.orderNumber}`}
      showModal={isOpen}
      setShowModal={onClose}
      theme={drawerTheme}
      dismissible={true}
      overlayClass={true}
    >
      <div className="space-y-6">
        <section className="space-y-2">
          <div className="text-sm font-semibold text-neutral-800">Title</div>
          <div className="px-6 py-[22px] bg-neutral-50 rounded-lg flex items-start gap-3">
            <div className="w-[42px] h-16 bg-book-background rounded-md border border-neutral-300 flex items-center justify-center overflow-hidden">
              <img
                src={handleThumbnail(order?.book?.thumbnail) || BookIcon}
                alt="Book cover"
                className="w-full h-full object-contain"
              />
            </div>
            <div className="flex-1">
              <div className="text-sm font-semibold text-neutral-900">
                {order?.book?.title || order?.modQueue?.draftName}
              </div>
            </div>
          </div>
        </section>

        <section className="space-y-3.5">
          <DetailRow
            label="Ship to"
            content={
              <>
                <div className="text-sm font-semibold text-neutral-800">
                  {order?.selectedLeads?.[0]?.name}
                </div>
                <div className="text-sm text-neutral-500">
                  {order?.selectedLeads?.[0]?.email}
                  <br />
                  {order?.selectedLeads?.[0]?.contactNumber}
                  <br />
                  {order?.selectedLeads?.[0]?.address &&
                    (typeof order.selectedLeads[0].address === 'string' ? (
                      order.selectedLeads[0].address
                    ) : typeof order.selectedLeads[0].address === 'object' ? (
                      <>
                        {order.selectedLeads[0].address.city && (
                          <>
                            {order.selectedLeads[0].address.city},{' '}
                            {order.selectedLeads[0].address.state}
                            <br />
                            {order.selectedLeads[0].address.country}
                          </>
                        )}
                      </>
                    ) : null)}
                </div>
              </>
            }
          />

          <DetailRow
            label="Ordered by"
            content={
              <>
                <div className="text-sm font-semibold text-neutral-800">
                  {order?.customer?.name}
                </div>
                <div className="text-sm text-neutral-500">
                  {order?.customer?.email}
                  <br />
                  {order?.customer?.phone}
                </div>
              </>
            }
          />

          <DetailRow
            label="Ordered on"
            content={
              <span className="text-sm font-semibold text-neutral-800">
                {order?.dateOrdered &&
                  format(new Date(order.dateOrdered), 'MMM d, yyyy')}
              </span>
            }
          />

          {!showStatusHistory && (
            <DetailRow
              label="Age"
              content={
                <span className="text-sm font-semibold text-error-500">
                  {order?.dateOrdered && calculateAge(order.dateOrdered)}
                </span>
              }
            />
          )}
        </section>

        {showStatusHistory && order?.statusHistory && (
          <section className="mt-11">
            {order.statusHistory
              .slice()
              .reverse()
              .map((status, index) => (
                <StatusHistoryItem
                  key={index}
                  status={status}
                  isLast={index === order.statusHistory.length - 1}
                />
              ))}
          </section>
        )}
      </div>
    </ClosableModal>
  );
};

interface DetailRowProps {
  label: string;
  content: React.ReactNode;
}

const DetailRow: React.FC<DetailRowProps> = ({ label, content }) => (
  <div className="pb-3.5 border-b border-neutral-200 flex justify-between">
    <div className="text-neutral-500 text-sm font-medium">{label}</div>
    <div className="flex-1 text-right">{content}</div>
  </div>
);

interface StatusHistoryItemProps {
  status: { status: string; updatedAt: Date };
  isLast: boolean;
}

const StatusHistoryItem: React.FC<StatusHistoryItemProps> = ({
  status,
  isLast,
}) => (
  <div className="flex items-start gap-[13px] pb-1">
    <div className="flex flex-col items-center">
      <div
        className={`w-8 h-8 p-2 rounded-full shadow flex items-center justify-center ${
          status.status === 'Shipped'
            ? 'bg-secondary-orange-500'
            : 'bg-neutral-400'
        }`}
      >
        {status.status === 'In Process' ? (
          <InProcessIcon className="w-4 h-4 text-white" />
        ) : status.status === 'Shipped' ? (
          <ShippedIcon className="w-4 h-4 text-white" />
        ) : (
          <InReviewIcon className="w-4 h-4 text-white" />
        )}
      </div>
      {!isLast && <div className="w-[1px] h-[60px] bg-neutral-200" />}
    </div>
    <div>
      <div className="text-sm font-semibold text-neutral-800">
        {status.status === 'In Process'
          ? 'In process'
          : status.status === 'Shipped'
          ? 'Your order has been shipped'
          : status.status === 'In Review'
          ? 'Your order is in review'
          : status.status}
      </div>
      <div className="text-sm text-neutral-500">
        {format(new Date(status.updatedAt), 'MMM d, yyyy · h:mm a')}
      </div>
    </div>
  </div>
);

export default OrderDetailsDrawer;
