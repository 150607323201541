import React, { useState, SyntheticEvent } from "react";
import { get, capitalize } from "lodash-es";
import { checkWordLimit } from "../../../utils";
import { TextInput, Textarea } from "flowbite-react";
import { defaultTextInputTheme } from "../../../../../../components/Theme/TextInput";
import { defaultTextAreaTheme } from "../../../../../../components/Theme/TextArea";
import { PriceInput } from "../../../../../../components/PriceInput";

import ImageUploaderModal from "../../components/ImageUploaderModal";
import { optionFieldsWithWordLimit } from "../../../constants";
import { useMagazineImageContext } from "../../../../../../../context/MagazineImageHook";
import { MagazineImageContextType } from "../../../../../../../context/MagazineImageContext";

const ListingDetails = ({
  step = "",
  formikHandler,
  hasInputFields,
  hasListingName = false,
  hasTextArea,
  uploadImageTitle = "Listing photo",
  presentImageLocation,
  uploadImageType,
  imageDimensions,
  propertyKey,
}) => {
  const { values = {}, setFieldValue, setFieldError, errors } = formikHandler || {};
  const targetLocation = step.concat(".", "_formFields");
  const [brokerLogoModal, setBrokerLogoModal] = useState(false);
  const [isEditImage, setIsEditImage] = useState(false);
  const [imageUrl, setImageUrl] = useState<null | string>(null);

  const { setShowModal, setImageType } = useMagazineImageContext() as MagazineImageContextType;

  const handleListingTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {
      target: { name = "", value = "" },
    } = e || {};

    const editorData = value;
    const { limitExceeded } = checkWordLimit(`_formFields.${name}`, editorData, 35);

    if (
      limitExceeded &&
      (uploadImageType === "backInsideCoverImageOption3" || uploadImageType === "backInsideCoverImageOption4")
    ) {
      return false;
    }

    const locationToSave = step.concat(".", `_formFields.${name}`);
    setFieldValue(locationToSave, editorData);
  };

  const handleListingTextAreaKeypress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter") {
      const {
        target: { name = "", value = "" },
      } = e || {};

      const lines = value.split("\n");
      const html = lines
        .filter((line) => line.trim() !== "")
        .map((line) => `• ${line.replace(/[ •]/g, "")}\n`)
        .join("");

      const locationToSave = step.concat(".", `_formFields.${name}`);
      setFieldValue(locationToSave, html);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name = "", value = "" },
    } = e || {};

    const formikValues = get(values, `${targetLocation}`) || {};
    const locationToSave = step.concat(".", "_formFields", ".", name);

    if (optionFieldsWithWordLimit.includes(name)) {
      const { limitExceeded, isRemovingWords } = checkWordLimit(formikValues[name], value, 50);

      if (limitExceeded && !isRemovingWords) {
        setFieldError(`${targetLocation}.${name}`, `${capitalize(name)} must not be more than 50 words.`);
        return;
      }
    }

    setFieldError(`${targetLocation}.${name}`, null);
    setFieldValue(locationToSave, value);
  };

  const handlePriceChange = (value: string) => {
    const locationToSave = step.concat(".", "_formFields", ".listingPrice");
    setFieldValue(locationToSave, value);
  };

  const toggleBrokerLogoModal = () => {
    setBrokerLogoModal(!brokerLogoModal);
  };

  const handleImageUpload = (value: string, keyName: string) => {
    const locationToSave = step.concat(".", "_formFields", ".", keyName);
    setFieldValue(locationToSave, value);
    toggleBrokerLogoModal();
  };

  const onRemoveImage = (e: SyntheticEvent<HTMLButtonElement>, propKey: string) => {
    setFieldValue(`${targetLocation}.${propKey}`, "");
    setImageUrl("");
    e.stopPropagation();
  };

  const onEditImage = () => {
    setIsEditImage(true);
    setImageUrl(presentImageLocation);
  };

  const toggleCancelEdit = () => {
    setIsEditImage(!isEditImage);
  };

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col items-start">
          <div className="text-neutral-800 text-sm font-semibold">{uploadImageTitle}</div>
          <div className="text-neutral-500 text-sm font-medium">
            Recommended size: {imageDimensions.width} x {imageDimensions.height}
          </div>
        </div>

        <ImageUploaderModal
          type={uploadImageType}
          modalTitle={uploadImageTitle}
          imageUrl={presentImageLocation}
          onSuccess={(value) => handleImageUpload(value, propertyKey)}
          onCancel={toggleBrokerLogoModal}
          handleOpenModal={(type) => {
            setImageType(type);
            setShowModal(true);
          }}
          onRemoveImage={(e: { stopPropagation: () => void }) => {
            onRemoveImage(uploadImageType);
            e.stopPropagation();
          }}
          onEditImage={(e: { stopPropagation: () => void }) => {
            onEditImage(uploadImageType);
            setBrokerLogoModal("photo");
            e.stopPropagation();
          }}
          imageUrlForEdit={imageUrl}
          isEditImage={isEditImage}
          toggleCancelEdit={toggleCancelEdit}
        />
      </div>
      {hasListingName && (
        <div className="w-full pr-3 flex gap-4">
          <div className="w-full flex flex-col gap-0">
            <div className="w-full flex flex-col gap-1">
              <span className="font-semibold text-sm text-neutral-800">Listing name</span>
              <TextInput
                theme={defaultTextInputTheme}
                id="listingName"
                name="listingName"
                type="text"
                value={get(values, `${targetLocation}.listingName`) || ''}
                onChange={handleChange}
                placeholder=""
                className="rounded-md"
              />
              <div className="text-red-500 py-2 text-sm">
                {get(errors, `${targetLocation}.listingName`) || ''}
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-0">
            <div className="w-full flex flex-col gap-1">
              <span className="font-semibold text-sm text-neutral-800">Listing address</span>
              <TextInput
                theme={defaultTextInputTheme}
                id="listingAddress"
                name="listingAddress"
                type="text"
                value={get(values, `${targetLocation}.listingAddress`)}
                onChange={handleChange}
                placeholder=""
                className="rounded-md"
              />
              <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.listingAddress`) || ""}</div>
            </div>
          </div>
        </div>
      )}
      {hasInputFields && !hasListingName && (
        <div className="w-full flex gap-4">
          <div className="w-full flex flex-col gap-0">
            <div className="w-full flex flex-col gap-1">
              <span className="font-semibold text-sm text-neutral-800">Listing address</span>
              <TextInput
                theme={defaultTextInputTheme}
                id="listingAddress"
                name="listingAddress"
                type="text"
                value={get(values, `${targetLocation}.listingAddress`) || ""}
                onChange={handleChange}
                placeholder=""
                className="rounded-md"
              />
              <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.listingAddress`) || ""}</div>
            </div>
          </div>
          <div className="w-full flex gap-4">
            <div className="relative w-full flex flex-col gap-1">
              <span className="font-semibold text-sm text-neutral-800">Listing price</span>
              <PriceInput
                theme={defaultTextInputTheme}
                id="listingPrice"
                name="listingPrice"
                type="text"
                addon="$"
                value={get(values, `${targetLocation}.listingPrice`) || ""}
                placeholder=""
                onValueChange={handlePriceChange}
              />
              <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.listingPrice`) || ""}</div>
            </div>
          </div>
        </div>
      )}
      {hasTextArea && (
        <div className="flex flex-col gap-4 mt-4">
          <div className="w-full flex flex-col gap-0">
            <div className="w-full flex flex-col gap-4">
              <div className="relative w-full flex flex-col gap-1">
                <label className="font-semibold text-sm text-neutral-800">Listing description</label>
                <Textarea
                  theme={defaultTextAreaTheme}
                  id="description"
                  name="description"
                  onChange={(e) => handleListingTextAreaChange(e)}
                  value={get(values, `${targetLocation}.description`) || ""}
                  rows={3}
                  placeholder=""
                />
                <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.description`)}</div>
              </div>
              <div className="relative w-full flex flex-col gap-1">
                <label className="font-semibold text-sm text-neutral-800">
                  Listing features <br />
                  <span className="text-neutral-400 font-medium text-sm">Use enter to add a list</span>
                </label>
                <Textarea
                  theme={defaultTextAreaTheme}
                  id="features"
                  name="features"
                  value={get(values, `${targetLocation}.features`) || ""}
                  onChange={(e) => handleListingTextAreaChange(e)}
                  onKeyUp={(e) => handleListingTextAreaKeypress(e)}
                  rows={3}
                  placeholder=""
                />
                <div className="text-red-500 py-2 text-sm">{get(errors, `${targetLocation}.features`)}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListingDetails;
