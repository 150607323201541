import env from '../../environments';

const urlPrefix = env.THUMBNAIL_URL;
const FULL_URL_MIN_LENGTH = 45;

export const handleThumbnail = (url: string | undefined | null) => {
  if (!url) return null;
  if (url.length > FULL_URL_MIN_LENGTH) {
    return url;
  }
  return `${urlPrefix}${url}`;
};
