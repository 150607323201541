import React, { useContext } from 'react';
import { List, ListItem } from 'flowbite-react';
import { Box, Button } from '../../../../../components';
import { OfferContext, OfferContextData } from '../../utils/OfferProvider';

const Step2: React.FC = () => {
  const {
    offerTrialDuration,
    productValueInCents,
    offerTrialPrice,
    showNextPaymentDate,
    showCancellationDisclaimer,
    showAddCustom,
    addCustomText,
    productName,
    ctaButtonTitle,
  } = useContext(OfferContext) as OfferContextData;

  if (!showNextPaymentDate && !showCancellationDisclaimer && !showAddCustom) {
    return (
      <Box className='p-[30px] flex flex-col gap-5'>
        <div className="flex justify-between items-center gap-3">
          <div className="h-4 my-0.5 w-[130px] rounded-full bg-neutral-300 " />
          <div>
            <div className="h-4 my-0.5 w-[67px] rounded-full bg-neutral-200 " />
          </div>
        </div>
        <Button size='lg'>
          {ctaButtonTitle}
        </Button>
      </Box>
    );
  }

  return (
    <Box className='p-[30px] flex flex-col gap-3.5'>
      <div className="flex justify-between items-center gap-3">
        <div className="h-4 my-0.5 w-[130px] rounded-full bg-neutral-300 " />
        <div>
          <div className="h-4 my-0.5 w-[67px] rounded-full bg-neutral-200 " />
        </div>
      </div>
      <Button size='lg'>
        {ctaButtonTitle}
      </Button>

      <div className='text-sm text-neutral-500 font-semibold'>
        {showNextPaymentDate && (
          <span className='mr-0.5'>
            {Number(offerTrialDuration) > 0 && Number(offerTrialPrice) > 0 &&
              (`Your next subscription billing of $${productValueInCents}/${productName.includes('Annual') ? 'year' : 'mo'} begins in ${offerTrialDuration} days.`)
            }
            {Number(offerTrialDuration) > 0 && Number(offerTrialPrice) === 0 && (
              `${offerTrialDuration} days free from today, then just $${productValueInCents}/${productName.includes('Annual') ? 'year' : 'mo'} (plus applicable taxes).`
            )}
            {Number(offerTrialDuration) === 0 && (
              `Your next subscription billing of $${productValueInCents}/${productName.includes('Annual') ? 'year' : 'mo'} begins in ${productName.includes('Annual') ? '365' : '30'} days.`
            )}
          </span>
        )}
        {showCancellationDisclaimer && (
          <span className='mr-0.5'>
            Easily cancel through our support department or by email (support@authorify.com).
          </span>
        )}
        {showAddCustom && addCustomText && (
          <span className='mr-0.5'>
            {addCustomText}
          </span>
        )}
      </div>
    </Box>
  );
};

export default Step2;