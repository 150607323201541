import React, { useContext } from 'react';
import classNames from 'classnames';
import { Box, Button, SwitchButton } from '../../../../../components';
import { OfferContext, OfferContextData } from '../../utils/OfferProvider';
import { List, ListItem } from 'flowbite-react';

const translateAdverb = (adverb: string) => adverb.replace('ly', '');

const Step1: React.FC = () => {

  const {
    productTitle,
    productDescription,
    offerTrialEnabled,
    offerTrialCredits,
    offerTrialPrice,
    productValueInCents,
    productRenewInterval,
    showTermsOfService,
    showOfferAndPrice,
    showPlanInformation,
    productName,
    offerTrialDuration,
  } = useContext(OfferContext) as OfferContextData;

  const PlanInformation = () => (
    <>
      {showPlanInformation && productName && productValueInCents && (
        <div className="flex flex-col gap-2 mb-6">
          <p className="self-center font-extrabold">
            My Subscription Plan
          </p>

          <p className="self-center text-neutral-700 font-semibold">
            {productName} {offerTrialEnabled && Number(offerTrialDuration) > 0 ? `($${offerTrialPrice} trial)` : ''}
          </p>

          <List className="self-center">
            <ListItem>
              {offerTrialEnabled ?
                `${offerTrialPrice} today for the first ${offerTrialDuration} days` : `$${productValueInCents}/${translateAdverb(productRenewInterval)} starting today`
              }
            </ListItem>
            <ListItem>
              {offerTrialEnabled ?
                `After ${offerTrialDuration} days, renews at ${productValueInCents}/${translateAdverb(productRenewInterval)}` : `Renews ${productRenewInterval}`
              }
            </ListItem>
          </List>
        </div>
      )}
    </>
  );

  const TermsAndService = () => (
    <>
      {showTermsOfService && (
        <div className="mt-3">
          <span className='items-center flex flex-col text-xs leading-6 text-neutral-500 font-medium'>
            <span>I also agree to the <u>Terms of Service</u> and <u>Privacy Policy</u>.</span>
          </span>
        </div>
      )}
    </>
  );

  if (!showOfferAndPrice) {
    return (
      <Box className='p-[30px] text-sm flex flex-col gap-5'>
        <PlanInformation />

        <div className="flex items-center gap-3">
          <SwitchButton
            checked={true}
            className='scale-75'
          />
          <div className="h-4 my-0.5 w-full rounded-full bg-neutral-200 " />
        </div>
        <Button size='lg'>
          <span className='h-4 w-[100px] rounded-full bg-primary-400 ' />
        </Button>

        <TermsAndService />
      </Box>
    );
  }

  return (
    <Box className='p-[30px]'>
      <div className="flex flex-col gap-0.5 justify-center items-center text-sm">
        <PlanInformation />

        {productTitle && (
          <p className='text-neutral-700 text-sm text-center font-semibold'>{productTitle}</p>
        )}
        {productDescription && (
          <p className='text-neutral-500 tesxt-sm font-medium mt-1'>{productDescription}</p>
        )}
        <p className={classNames("text-3xl font-medium pb-[18px]", productTitle || productDescription ? 'mt-[18px]' : '')}>
          ${offerTrialPrice && offerTrialEnabled ? offerTrialPrice : productValueInCents}
        </p>
        <div className="text-xs px-2 py-1 rounded-md text-[#D9630B] bg-[#FFE9D9]">
          For shipping and handling
        </div>
        {(offerTrialCredits ?? 0) > 0 && (
          <div
            className="text-neutral-500 font-medium mt-1"
            id="free-books"
          >
            + {offerTrialCredits} FREE Printed Books
          </div>

        )}
      </div>

      <Button className='w-full mt-[34px]' size='lg'>
        <span className='h-4 w-[100px] rounded-full bg-primary-400 ' />
      </Button>

      <TermsAndService />
    </Box>
  );
};

export default Step1;