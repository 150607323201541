import React from "react";

const SpinnerIcon = ({ width='81', height='81' }) => (
  <svg width={width} height={height} viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="40.4997" cy="40.4999" r="35.6667" stroke="#F5F5F5" strokeWidth="2" />
    <path d="M5.5 40.5C5.5 59.83 21.17 75.5 40.5 75.5C59.83 75.5 75.5 59.83 75.5 40.5C75.5 21.17 59.83 5.5 40.5 5.5"
      stroke="#4CC9E1" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

export default SpinnerIcon;

