import { getInstance } from './instance';

const instance = getInstance();

export const getCurrentWorkflow = () => {
  const url = 'abo/workflow/current';
  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const startNextWorkflowStep = () => {
  const url = 'abo/workflow/start';
  return instance
    .post(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const listWorkflows = (year) => {
  const url = `abo/workflow/list?year=${year}`;
  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};

export const getTurnOverDetails = (id, email, page, perPage) => {
  const filteredPage = page - 1;
  const url = `abo/workflow/step/${id}?q=${email}&page=${filteredPage}&perPage=${perPage}`;
  return instance
    .get(url)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
};