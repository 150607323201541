import React, { ChangeEvent, FocusEventHandler } from 'react';
import { Tooltip } from 'flowbite-react';
import classnames from 'classnames';

interface TextInputProps {
  id: string;
  value: string;
  onChange: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: string;
  className?: string;
  disabledText?: string;
  disabled?: boolean;
  error?: boolean;
  multiline?: boolean;
  rows?: number; // Only applicable for textarea
  width?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  value,
  onChange,
  onBlur,
  placeholder,
  type = 'text',
  className = '',
  disabled = false,
  disabledText = '',
  error = false,
  multiline = false,
  rows = 4,
  width,
}) => {
  const commonProps = {
    id,
    value,
    onChange,
    placeholder,
    onBlur,
    className: classnames(
      'flex h-[46px] border pl-3.5 pr-4 rounded-md text-sm font-medium placeholder:text-neutral-300 focus:border-2 focus:border-primary-500 focus:outline-none focus:ring-0 focus:ring-transparent',
      error ? 'border-2 border-red-500' : 'border-neutral-200',
      width ? `w-[${width}]` : 'w-full',
      className,
    ),
    disabled,
    style: width ? { width } : undefined,
  };

  const showTooltip = () => Boolean(disabled && disabledText);

  if (multiline) {
    return (
      <Tooltip
        content={disabledText}
        theme={{
          base: `text-neutral-50 text-sm p-2 px-3 rounded-lg font-normal font-figtree ${showTooltip() ? '' : 'invisible'}`,
          target: 'w-full',
        }}
      >
        <textarea {...commonProps} rows={rows} />
      </Tooltip>
    );
  }

  return (
    <Tooltip
      content={disabledText}
      theme={{
        base: `text-neutral-50 text-sm p-2 px-3 rounded-lg font-normal font-figtree ${showTooltip() ? '' : 'invisible'}`,
        target: 'w-full',
      }}
    >
      <input {...commonProps} type={type} />
    </Tooltip>
  );
};

export default TextInput;
