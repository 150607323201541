import React from 'react';
import { Modal, CustomFlowbiteTheme } from 'flowbite-react';
import CloseCircle from '../icons/CloseCircle';

interface ClosableModalProps {
  title: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  footerClass?: string;
  theme?: CustomFlowbiteTheme['modal'];
  dismissible?: boolean;
  overlayClass?: boolean;
}

const defaultTheme: CustomFlowbiteTheme['modal'] = {
  root: {
    show: {
      on: 'bg-black bg-opacity-45 flex',
      off: 'hidden',
    },
    positions: {
      'top-right':
        'items-start justify-end transform transition-transform duration-300 ease-in-out -translate-x-0',
    },
  },
  content: {
    base: 'relative h-full w-fit md:h-auto font-[figtree]',
    inner: 'rounded-xs bg-white shadow-lg',
  },
  footer: {
    base: 'flex space-x-2 border-neutral-200 min-h-[91px] px-6 py-[13px] border-neutral-600',
  },
};

const ClosableModal = ({
  title,
  children,
  footer,
  showModal,
  setShowModal,
  footerClass,
  theme = defaultTheme,
  dismissible = true,
  overlayClass = true,
}: ClosableModalProps) => {
  return (
    <Modal
      theme={theme}
      show={showModal}
      onClose={() => setShowModal(false)}
      size="md"
      popup
      dismissible={dismissible}
      position="top-right"
    >
      <div className="mx-6 mb-[18px] pt-6 pb-3 flex justify-between items-center font-bold border-b border-neutral-200">
        <span>{title}</span>
        <button
          className="w-[38px] h-[38px] p-2.5 bg-neutral-100 rounded-md flex items-center justify-center hover:bg-neutral-200 transition-colors duration-200"
          onClick={() => setShowModal(false)}
        >
          <CloseCircle className="w-4 h-4 text-neutral-600" />
        </button>
      </div>
      <div
        className="overflow-y-auto overflow-hidden max-w-[448px] pb-[60px]"
        style={{ height: 'calc(100vh - 84px)' }}
      >
        <Modal.Body>{children}</Modal.Body>
      </div>
      {footer && (
        <div className="fixed bottom-0 w-[448px] max-sm:w-full bg-white border-t">
          <Modal.Footer className={footerClass}>{footer}</Modal.Footer>
        </div>
      )}
    </Modal>
  );
};

export default ClosableModal;
