import Title from '../../../components/Title';
import React, { useEffect, useState } from 'react';
import Button from '../../../components/Button';
import { PlayIcon, GoLiveIcon, RecurringIcon, TurnOverIcon, WorkflowIcon, ArrowDown, ArrowUp } from '../../../../components/icons';
import { useMutation, useQuery } from 'react-query';
import { listWorkflows, getCurrentWorkflow, startNextWorkflowStep } from '../../../../modules/api/rmm-automation';
import { BackgroundJobStatus, BackgroundJobType, formatBackgroundJobType } from './utils';
import { formatDate, formatDateTime, formatMonthYear, formatShortMonthYear } from '../../../../utils/date';
import noItemsImage from '../../../../assets/images/no-items.png';
import SkeletonLoader from '../../../components/SkeletonLoader';
import { useNavigate } from 'react-router-dom';
import { Badge, Icon, StepsBadge } from './components/utils';
import { Tooltip, Select } from 'flowbite-react';

export default function ReferralMarketing() {
  const [ isLoadingScript, setIsLoadingScript ] = useState(true);
  const [ progressGoLive, setProgressGoLive ] = useState(0);
  const [ progressTurnOver, setProgressTurnOver ] = useState(0);
  const [ progressTickets, setProgressTickets ] = useState(0);
  const [ jobs, setJobs ] = useState([]);
  const [ selectedYear, setSelectedYear ] = useState('2025');
  const [ showedInfo, setShowedInfo ] = useState({});
  const [ currentScript, setCurrentScript ] = useState<BackgroundJobType | null>(null);
  const [ metaGoLive, setMetaGoLive ] = useState(null);
  const [ metaTurnOver, setMetaTurnOver ] = useState(null);
  const [ metaTickets, setMetaTickets ] = useState(null);
  const [ hasAllDataDelived, setHasAllDataDelivered ] = useState(false);

  const currentDate = new Date();
  const currentMonth = formatShortMonthYear(currentDate);
  const twoMonthsFromNow = formatShortMonthYear(currentDate, 2);

  const setStateForMetadata = {
    [BackgroundJobType.MONTHLY_TURN_OVER]: setMetaTurnOver,
    [BackgroundJobType.RECURRING_TICKET]: setMetaTickets,
    [BackgroundJobType.GO_LIVE]: setMetaGoLive,
  };
  const setStateForProgress = {
    [BackgroundJobType.MONTHLY_TURN_OVER]: setProgressTurnOver,
    [BackgroundJobType.RECURRING_TICKET]: setProgressTickets,
    [BackgroundJobType.GO_LIVE]: setProgressGoLive,
  };

  const toggleInfo = (id) => {
    setShowedInfo((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const navigate = useNavigate();


  const { mutate: startNextScript, isLoading: isUploadingScript } = useMutation(startNextWorkflowStep, {
    onSuccess: () => {
      setIsLoadingScript(true);
      setHasAllDataDelivered(false);
    },
    onError: (error) => {
      console.error('Error starting upload on script', error);
      setIsLoadingScript(false);
    },
  });

  const { isLoading: isFetching, refetch } = useQuery(
    ['listWorkflows', selectedYear],
    () => listWorkflows(selectedYear),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      onSuccess: ({ data }) => {
        setJobs(data);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );
  
  useQuery(
    'getCurrentWorkflow',
    getCurrentWorkflow,
    {
      enabled: isLoadingScript && !hasAllDataDelived,
      refetchOnWindowFocus: false,
      refetchInterval: hasAllDataDelived ? false : 5000,
      onSuccess: ({ steps }) => {
        defineCurrentStep(steps);
        defineStepsData(steps);
      },
      onError: (error) => {
        console.error(error);
      },
    }
  );

  useEffect(() => {
    if (jobs.length > 0) {
      const initialShowedInfo = jobs.reduce((acc, job) => {
        acc[job._id] = false;
        return acc;
      }, {});
      setShowedInfo(initialShowedInfo);
    }
  }, [jobs]);

  useEffect(() => {
    refetch();
  }, [selectedYear]);

  const defineCurrentStep = (steps) => {
    const ticketsStep = steps?.find(step => step.type === BackgroundJobType.RECURRING_TICKET);
    const turnOverStep = steps?.find(step => step.type === BackgroundJobType.MONTHLY_TURN_OVER);
    const goLiveStep = steps?.find(step => step.type === BackgroundJobType.GO_LIVE);

    if (goLiveStep?.status !== BackgroundJobStatus.DONE) {
      setCurrentScript(BackgroundJobType.GO_LIVE);
    } else if (turnOverStep?.status !== BackgroundJobStatus.DONE) {
      setCurrentScript(BackgroundJobType.MONTHLY_TURN_OVER);
    } else if (ticketsStep?.status !== BackgroundJobStatus.DONE) {
      setCurrentScript(BackgroundJobType.RECURRING_TICKET);
    } else {
      setCurrentScript(null);
    }
  };

  const defineStepsData = (steps) => {
    const stepsDone = steps?.filter(step => step.status === BackgroundJobStatus.DONE);
    const stepInProgress = steps?.find(step => step.status === BackgroundJobStatus.PROCESSING);
    const stepIsPending = steps?.find(step => step.status === BackgroundJobStatus.PENDING);

    stepsDone?.forEach((step) => {
      const metaDataFunction = setStateForMetadata[step.type];
      const progressFunction = setStateForProgress[step.type];
      if (metaDataFunction) {
        metaDataFunction(step.processed);
      }
      if(progressFunction) {
        progressFunction(100)
      }
    });
    if(stepInProgress) {
      const progressFunction = setStateForProgress[stepInProgress.type]; 
      if(progressFunction && stepInProgress.processed > 0) {
        const calculatedProgress = Math.round((stepInProgress.processed / stepInProgress.total) * 100);
        progressFunction(calculatedProgress);
      }
    } else if (!stepIsPending) {
      setHasAllDataDelivered(true);
      setIsLoadingScript(false);
      refetch();
    }
  };

  const cardInfo = (data) => {
    return (
      <div className="flex justify-between items-center gap-2 bg-white px-6 py-[14px] hover:bg-neutral-50 border-b" key={data._id}>
        <div className="flex items-center gap-4">
          {Icon(data.type, data.status)}
          <div className="flex flex-col min-w-[120px] text-neutral-800 text-sm gap-2 font-semibold font-['Figtree']">
            {formatBackgroundJobType(data.type)}
            <Tooltip content={formatDateTime(data.createdAt)} placement="top">
              <span className="cursor-pointer">{formatDate(data.createdAt, 'MMM DD, YYYY')}</span>
            </Tooltip>
            
          </div>
        </div>
        <div className="text-neutral-800 h-[24px] text-sm font-semibold font-['Figtree'] w-[144px]">
          {Badge(data.status, data.type, data.processed)}
        </div>
        {data?.type === BackgroundJobType.MONTHLY_TURN_OVER ? 
          <Button
            variant="LIGHT"
            onClick={() => data._id && navigate(`/referral-marketing/${data._id}`)}
          >
            View Magazines
          </Button>
          : 
          <div className='w-[141px]'></div>
        }
      </div>
    );
  }

  const workFlowInfo = (workflow) => {
    return (
      <div key={workflow._id} className='cursor-pointer min-w-[600px]'>
        <div className="flex justify-between gap-2 bg-white px-6 py-[18px] hover:bg-neutral-50 border-b" onClick={() => toggleInfo(workflow._id)}>
          <div className='flex gap-2'>
            <WorkflowIcon />
            {formatMonthYear(workflow.createdAt)}
            {StepsBadge(workflow.steps, workflow.completed)}
          </div>
          { (showedInfo && showedInfo[workflow._id]) ? <ArrowUp className="w-5 h-5" /> : <ArrowDown className="w-5 h-5" /> }
        </div>
        {showedInfo && showedInfo[workflow._id] && 
          workflow.steps.map((step) => {
            return (cardInfo(step))
          })
        }
      </div>
    );
  };
  
  return (
    <div className="min-h-screen max-w-full">
      <div className="mb-6 pt-8 flex gap-2 items-center max-[550px]:flex-col">
        <Title>RMM Automation</Title>
      </div>

      <div className='grid grid-cols-1 min-[1280px]:grid-cols-3 gap-4 mb-6'>

        <div className="w-full h-full bg-white shadow-card rounded-lg">
          <div className='flex items-center gap-2 border-b px-6 py-[22px]'>
            <GoLiveIcon stroke="#4CC9E1" className="min-w-[16px]"/>
            <p className="font-semibold capitalize text-base">Go Live for {currentMonth}</p>
          </div>
          <div className='h-[478px] w-full flex flex-col justify-between p-6'>
            <div className="text-gray-500 text-base h-[48px]">
              Updates all live magazines and landing pages to the next issue. This can only be run on the 1st of each month
            </div>

            <div className='flex justify-center'>
              {(currentScript === BackgroundJobType.GO_LIVE || metaGoLive !== null ) && <div className="relative w-[192px] h-[192px]">
                 <svg
                  className="size-full -rotate-90"
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="18"
                    cy="18"
                    r="16"
                    fill="none"
                    className="stroke-current text-gray-200"
                    stroke-width="1"
                  ></circle>
                  <circle
                    cx="18"
                    cy="18"
                    r="16"
                    fill="none"
                    className="stroke-current text-primary-500"
                    stroke-width="1"
                    stroke-dasharray="100"
                    stroke-dashoffset={100 - progressGoLive}
                    stroke-linecap="round"
                  ></circle>
                </svg>

                <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                  <span className="flex flex-col text-center text-2xl font-bold text-primary-500">
                    {currentScript === BackgroundJobType.GO_LIVE ? `${progressGoLive} %` : metaGoLive !== null && `${metaGoLive} live magazines`}
                  </span>
                </div>
              </div>}
            </div>

            <Button className="h-[46px]" disabled={(currentScript !== BackgroundJobType.GO_LIVE || isUploadingScript || isLoadingScript)} onClick={() => startNextScript()}>
              <PlayIcon className='mr-2' />
              Start
            </Button>
          </div>
        </div>


        <div className="w-full h-full bg-white shadow-card rounded-lg">
          <div className='flex items-center gap-2 border-b px-6 py-[22px]'>
            <TurnOverIcon fill="#4CC9E1" className="min-w-[16px]"/>
            <p className="font-semibold capitalize text-base">Turn Over for {twoMonthsFromNow}</p>
          </div>
          <div className='h-[478px] w-full flex flex-col justify-between p-6'>
            <div className="text-gray-500 text-base h-[48px]">
              This will clone and download all magazines. This can only be run on the 21st of each month.  
            </div>

            <div className='flex justify-center'>
              {(currentScript === BackgroundJobType.MONTHLY_TURN_OVER ||metaTurnOver !== null) && <div className="relative w-[192px] h-[192px]">
                <svg
                  className="size-full -rotate-90"
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="18"
                    cy="18"
                    r="16"
                    fill="none"
                    className="stroke-current text-gray-200"
                    stroke-width="1"
                  ></circle>
                  <circle
                    cx="18"
                    cy="18"
                    r="16"
                    fill="none"
                    className="stroke-current text-primary-500"
                    stroke-width="1"
                    stroke-dasharray="100"
                    stroke-dashoffset={100 - progressTurnOver}
                    stroke-linecap="round"
                  ></circle>
                </svg>

                <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                  <span className="flex flex-col text-center text-2xl font-bold text-primary-500">
                    {currentScript === BackgroundJobType.MONTHLY_TURN_OVER ? `${progressTurnOver} %` : metaTurnOver !== null && `${metaTurnOver} cloned magazines`}
                  </span>
                </div>
              </div>}
            </div>

            <Button className="h-[46px]" disabled={(currentScript !== BackgroundJobType.MONTHLY_TURN_OVER || isUploadingScript || isLoadingScript)} onClick={() => startNextScript()}>
              <PlayIcon className='mr-2' />
                Start
            </Button>
          </div>
        </div>

        <div className="w-full h-full bg-white shadow-card rounded-lg">
          <div className='flex items-center gap-2 border-b px-6 py-[22px]'>
            <RecurringIcon fill="#4CC9E1" className="min-w-[16px]"/>
            <p className="font-semibold text-base">Recurring Tickets for {twoMonthsFromNow}</p>
          </div>
          <div className='h-[478px] w-full flex flex-col justify-between p-6'>
            <div className="text-gray-500 text-base h-[48px]">
              Creates recurring tickets. This can only be run on the 25th of each month
            </div>

            <div className='flex justify-center'>
              {(currentScript === BackgroundJobType.RECURRING_TICKET || metaTickets !== null) && <div className="relative w-[192px] h-[192px]">
                <svg
                  className="size-full -rotate-90"
                  viewBox="0 0 36 36"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="18"
                    cy="18"
                    r="16"
                    fill="none"
                    className="stroke-current text-gray-200"
                    stroke-width="1"
                  ></circle>
                  <circle
                    cx="18"
                    cy="18"
                    r="16"
                    fill="none"
                    className="stroke-current text-primary-500"
                    stroke-width="1"
                    stroke-dasharray="100"
                    stroke-dashoffset={100 - progressTickets}
                    stroke-linecap="round"
                  ></circle>
                </svg>

                <div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
                  <span className="flex flex-col text-center text-2xl font-bold text-primary-500">
                  {currentScript === BackgroundJobType.RECURRING_TICKET 
                    ? `${progressTickets} %` 
                    : metaTickets !== null && `${metaTickets} workflow${metaTickets === 1 ? '' : 's'} executed`}
                  </span>
                </div>
              </div>}
            </div>

            <Button className="h-[46px]" disabled={(currentScript !== BackgroundJobType.RECURRING_TICKET || isUploadingScript || isLoadingScript)} onClick={() => startNextScript()}>
              <PlayIcon className='mr-2' />
                Start
            </Button>
          </div>
        </div>
      </div>

      <div className="w-full h-full overflow-x-auto mb-16">
        <div className='flex items-center bg-white gap-2 justify-between px-6 py-[16px] border-b rounded-t-lg shadow-card'>
          <p className='font-semibold'>Activity</p>
          <Select
            id="rmm-filter"
            key={selectedYear}
            value={selectedYear}
            className='w-[168px]'
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
          </Select>
        </div>
        
        {isFetching ? (
          <SkeletonLoader />
        ) : jobs.length > 0 ? (
          <div className="w-full h-full justify-center items-center overflow-x-auto">
            {jobs.map((job) => {
              return (workFlowInfo(job))
            })}
          </div>
        ) : (
          <div className="w-full h-[382px] py-[100px] flex flex-col justify-center bg-white border-b rounded-b-lg items-center gap-3.5">
            <img
              className="w-[182px] h-[121px] object-cover"
              src={noItemsImage}
              alt="No items found"
            />
            <div className="self-stretch h-[47px] flex flex-col justify-start items-center gap-1">
              <div className="self-stretch text-center text-neutral-800 text-base font-semibold font-['Figtree'] leading-tight">
                No results found, try a different year.
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
