import React, { useState } from 'react';
import { useMOD } from '../context/MODContext';
import TextInput from '../../../../components/TextInput';
import PaginationFlowBite from '../../../../components/PaginationFlowBite';
import { format } from 'date-fns';
import Datepicker from 'react-tailwindcss-datepicker';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import OrderDetailsDrawer from './OrderDetailsDrawer';
import { Order } from '../types';

const BatchOrdersTable: React.FC = () => {
  const {
    batchOrders,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    searchTerm,
    setSearchTerm,
    dateRange,
    setDateRange,
  } = useMOD();

  const [value, setValue] = useState<DateValueType>({
    startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
    endDate: new Date(),
  });

  const [selectedOrderForDrawer, setSelectedOrderForDrawer] =
    useState<Order | null>(null);

  const handleValueChange = (newValue: DateValueType) => {
    setValue(newValue);
    if (newValue?.startDate && newValue?.endDate) {
      setDateRange({
        from: new Date(newValue.startDate),
        to: new Date(newValue.endDate),
      });
    }
  };

  const filteredOrders = batchOrders.filter((order) => {
    const searchTermLower = searchTerm?.toLowerCase() || '';

    const matchesSearch =
      !searchTerm ||
      order.orderId.toLowerCase().includes(searchTermLower) ||
      order.customerName.toLowerCase().includes(searchTermLower) ||
      order.selectedLeads.some(
        (lead) =>
          lead.name.toLowerCase().includes(searchTermLower) ||
          lead.email.toLowerCase().includes(searchTermLower) ||
          lead.contactNumber?.toLowerCase().includes(searchTermLower) ||
          lead.address?.toLowerCase().includes(searchTermLower),
      );

    const matchesDate =
      !dateRange ||
      (new Date(order.dateOrdered) >= new Date(dateRange.from) &&
        new Date(order.dateOrdered) <= new Date(dateRange.to));

    return matchesSearch && matchesDate;
  });

  const totalPages = Math.ceil(filteredOrders.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const paginatedOrders = filteredOrders.slice(
    startIndex,
    startIndex + pageSize,
  );

  const handlePerPage = (selectedOption: { value: number }) => {
    setPageSize(selectedOption.value);
    setCurrentPage(1);
  };

  const handleOrderClick = (order: Order) => {
    setSelectedOrderForDrawer(order);
  };

  const handleCloseDrawer = () => {
    setSelectedOrderForDrawer(null);
  };

  return (
    <div className="flex flex-col">
      <div className="px-6 py-[22px] border-b border-neutral-200">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3.5">
            <TextInput
              id="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search for name, email, order, or tracking number"
              width="418px"
            />
            <select
              className="h-[46px] mr-4 block w-[123px] rounded-md border-0 py-3 pl-3 pr-10 text-neutral-800 ring-1 ring-inset ring-neutral-300 focus:ring-2 focus:ring-primary-500 sm:text-sm sm:leading-6"
              defaultValue="all"
            >
              <option value="all">Status: All</option>
              <option value="shipped">Status: Shipped</option>
              <option value="delivered">Status: Delivered</option>
            </select>
          </div>
          <div style={{ width: '184px' }}>
            <Datepicker
              value={value}
              onChange={handleValueChange}
              primaryColor="sky"
              separator="-"
              showShortcuts={true}
              showFooter={true}
              displayFormat="MMM DD"
              maxDate={new Date()}
            />
          </div>
        </div>
      </div>

      <table className="w-full">
        <thead>
          <tr>
            <th className="w-[131px] px-6 py-3 text-left border-b border-neutral-200">
              <span className="text-neutral-800 text-sm font-semibold">
                Order#
              </span>
            </th>
            <th className="w-[200px] px-6 py-3 text-left border-b border-neutral-200">
              <span className="text-neutral-800 text-sm font-semibold">
                Ordered by
              </span>
            </th>
            <th className="w-[200px] px-6 py-3 text-left border-b border-neutral-200">
              <span className="text-neutral-800 text-sm font-semibold">
                Title
              </span>
            </th>
            <th className="w-[188px] px-6 py-3 text-left border-b border-neutral-200">
              <span className="text-neutral-800 text-sm font-semibold">
                Ordered on
              </span>
            </th>
            <th className="w-[336px] px-6 py-3 text-left border-b border-neutral-200">
              <span className="text-neutral-800 text-sm font-semibold">
                Recipient
              </span>
            </th>
            <th className="w-[146px] px-6 py-3 text-left border-b border-neutral-200">
              <span className="text-neutral-800 text-sm font-semibold">
                Status
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedOrders.map((order) => (
            <tr key={order.orderId} className="border-b border-neutral-200">
              <td className="px-6 py-3.5">
                <button
                  className="text-neutral-800 text-sm font-semibold underline"
                  onClick={() => handleOrderClick(order)}
                >
                  {order.orderNumber}
                </button>
              </td>
              <td className="px-6 py-3.5">
                <span className="text-neutral-800 text-sm font-semibold">
                  {order.customerName}
                </span>
              </td>
              <td className="px-6 py-3.5">
                <span className="text-neutral-800 text-sm font-semibold">
                  {order.modQueue?.draftName}
                </span>
              </td>
              <td className="px-6 py-3.5">
                <span className="text-neutral-800 text-sm font-semibold">
                  {format(new Date(order.dateOrdered), 'MMM d, yyyy')}
                </span>
              </td>
              <td className="px-6 py-3.5">
                {order.selectedLeads.map((lead) => (
                  <div key={lead.id} className="flex flex-col">
                    <span className="text-neutral-800 text-sm font-semibold">
                      {lead.name}
                    </span>
                    <span className="text-neutral-500 text-sm font-medium">
                      {lead.email}
                    </span>
                    <span className="text-neutral-500 text-sm font-medium">
                      {lead.contactNumber}
                    </span>
                    <span className="text-neutral-500 text-sm font-medium">
                      {typeof lead.address === 'string'
                        ? lead.address
                        : lead.address?.city && lead.address?.state
                        ? `${lead.address.city}, ${lead.address.state}${
                            lead.address.country
                              ? `, ${lead.address.country}`
                              : ''
                          }`
                        : null}
                    </span>
                  </div>
                ))}
              </td>
              <td className="px-6 py-3.5">
                <div
                  className={`px-2 py-1 rounded-md inline-flex items-center gap-2 ${
                    order.status === 'Shipped'
                      ? 'bg-secondary-orange-50'
                      : 'bg-neutral-100'
                  }`}
                >
                  <div className="p-px flex items-center gap-2.5">
                    <div
                      className={`w-1.5 h-1.5 rounded-full ${
                        order.status === 'Shipped'
                          ? 'bg-secondary-orange-500'
                          : 'bg-neutral-600'
                      }`}
                    />
                  </div>
                  <span
                    className={`text-sm font-semibold ${
                      order.status === 'Shipped'
                        ? 'text-secondary-orange-500'
                        : 'text-neutral-800'
                    }`}
                  >
                    {order.status}
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="px-6 py-[22px] border-t border-neutral-200">
        <PaginationFlowBite
          tablePagination={{
            pageSize,
            total: filteredOrders.length,
            lastPage: totalPages,
            currentPage,
            onChange: setCurrentPage,
          }}
          handlePerPage={handlePerPage}
        />
      </div>

      {selectedOrderForDrawer && (
        <OrderDetailsDrawer
          order={selectedOrderForDrawer}
          isOpen={!!selectedOrderForDrawer}
          onClose={handleCloseDrawer}
          showStatusHistory={true}
        />
      )}
    </div>
  );
};

export default BatchOrdersTable;
