import React, { useContext, useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import {
  ArrowChevron,
  ArrowLeftWhite,
  DesktopIcon,
  MobileIcon,
} from '../../../../../../components/icons';
import ToastNotification from '../../../../../components/ToastNotification';
import {
  createOffer,
  previewTemplate,
  publishOffer,
  saveDraft,
  updateOffer,
} from '../../../../../api/productCatalog/actions';
import { OfferContext } from '../../utils/OfferProvider';
import environments from '../../../../../../environments';

const OfferPreview = () => {
  const {
    templateId,
    setStep,
    offerId,
    offerName,
    offerCode,
    templateVariables,
    offerProduct,
    offerTrialEnabled,
    offerTrialDuration,
    offerTrialPrice,
    productValueInCents,
    offerCountry,
    offerTrialCredits,
    emphasis,
    bookIdPackages,
    moveScheduleStepToWelcomeJourney,
    offerIsBeingEdited,
  } = useContext(OfferContext) as {
    templateId: string;
    setStep: (step: number) => void;
    offerName: string;
    offerCode: string;
    offerTrialDuration: string;
    templateVariables: {
      handle: string;
      value: string | number;
      isObject?: boolean;
    }[];
    offerProduct: string;
    offerTrialEnabled: boolean;
    offerTrialPrice: string;
    offerTrialCredits: number;
    emphasis: string[];
    bookIdPackages: string[] | null;
    moveScheduleStepToWelcomeJourney: boolean;
  };

  const [previewContent, setPreviewContent] = useState<string>('');
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [stepNumber, setStepNumber] = useState<number>(1);
  const htmlContent = useRef<string>();
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const navigate = useNavigate();

  const changeStepPreview = (step: number) => {
    setPreviewContent(
      `<script>localStorage.step=${step}</script>` + htmlContent.current,
    );
  };

  const formatTemplateVariablesForPreview = () => {
    const variables = {};
    templateVariables.forEach((element) => {

      const formatValue = () => {
        switch (element.handle){
          case "trialOrOfferPrice":
            return (element.value as number) * 100;
          case "moveScheduleStepToWelcomeJourney":
          case "showAddCustom":
          case "showCancellationDisclaimer":
          case "showNextPaymentDate":
          case "showOfferAndPrice":
          case "showTermsOfService":
          case "showPlanInformation":
            return element.value === "true" ? true : false
          case "offerPrice":
          case "offerTrialDuration":
          case "offerTrialFreeBooks":
            return Number(element.value)
          default:
            return element.value
        }
      }

      variables[element.handle] = formatValue()

    });
    return JSON.stringify(variables)
  }

  const getTotalSteps = () => {
    return moveScheduleStepToWelcomeJourney ? 2 : 3
  }


  useEffect(() => {
    const fetchPreview = async () => {
      if (templateId && templateVariables) {
        try {
          const response = await previewTemplate(
            templateId,
            templateVariables.reduce((acc, { handle, value }) => {
              switch(handle){
                case "trialOrOfferPrice":
                  acc[handle] = (value as number * 100).toString()
                  break;
                default:
                  acc[handle] = value as string;
              }
              return acc;
            }, {} as Record<string, string>),
          );

          htmlContent.current = `
            <script>
              localStorage.variables = JSON.stringify(${formatTemplateVariablesForPreview()})
            </script>
          ` + response.data

          changeStepPreview(stepNumber);
        } catch (error) {
          ToastNotification({
            type: 'error',
            message: 'Error fetching preview',
          });
        }
      }
    };
    fetchPreview();
  }, [templateId, templateVariables]);

  const handleViewSwitch = (view: 'desktop' | 'mobile') => {
    setIsMobileView(view === 'mobile');
  };

  const { mutate: publishOfferMutation } = useMutation(publishOffer);

  const { mutate: createOfferMutation } = useMutation(createOffer, {
    onSuccess: () => {
      setTimeout(() => {
        publishOfferMutation(offerCode);
      }, 2000);
      navigate('/offers', { state: { showModal: true, offerCode: offerCode } });
    },
    onError: () => {
      ToastNotification({
        type: 'error',
        message: 'Error publishing offer',
      });
    },
  });

  const { mutate: updateOfferMutation } = useMutation(updateOffer, {
    onSuccess: () => {
      ToastNotification({
        type: 'success',
        message: 'Offer successfully updated!',
      });

      setTimeout(() => {
        navigate('/offers', { state: { showModal: true, offerCode: offerCode } });
      }, 1000);
    },
    onError: () => {
      ToastNotification({
        type: 'error',
        message: 'Error publishing offer',
      });
    },
  });

  const payload = {
    name: offerName,
    template: templateId,
    templateVariables,
    valueInCents: productValueInCents,
    offerCode: offerCode,
    product: offerProduct,
    trialParameters: offerTrialEnabled
      ? {
          priceInCents: parseInt(offerTrialPrice) * 100,
          credits: +offerTrialCredits,
          interval: offerTrialDuration ? parseInt(offerTrialDuration) : 0,
          intervalUnit: 'day',
        }
      : undefined,
    emphasis: emphasis,
    packages: bookIdPackages,
    country: offerCountry.map((value) => {
      if (value === 'United States') return 'US';
      else if (value === 'Canada') return 'CA';
    }),
  };

  if (!payload.packages) {
    delete payload.packages;
  }

  const handleSaveDraft = async () => {
    await saveDraft(payload);
  };

  const handlePreviousStepButton = () => {
    let newStep = stepNumber - 1;
    if (newStep < 1) newStep= getTotalSteps();
    setStepNumber(newStep);
    changeStepPreview(newStep);
  };

  const handleNextStepButton = () => {
    let newStep = stepNumber + 1;
    if (newStep > getTotalSteps()) newStep=1;
    setStepNumber(newStep);
    changeStepPreview(newStep);
  };

  const handlePublish = () => {
    if (offerIsBeingEdited()) {
      const resolvedPayload = {
        ...payload,
        title: payload.name,
        credits: Number(offerTrialCredits),
        freeBooks: Number(offerTrialCredits),
      };

      delete resolvedPayload.country;

      updateOfferMutation({ offerId, payload: resolvedPayload });
    } else createOfferMutation(payload);
  };

  useEffect(() => {
    const overlay = overlayRef.current;

    const disablePointerEvents = () => {
      if (overlay) {
        overlay.style.pointerEvents = 'none';
      }
    };

    const enablePointerEvents = () => {
      if (overlay) {
        overlay.style.pointerEvents = 'auto';
      }
    };

    window.addEventListener('mousedown', disablePointerEvents);
    window.addEventListener('mouseup', enablePointerEvents);

    return () => {
      window.removeEventListener('mousedown', disablePointerEvents);
      window.removeEventListener('mouseup', enablePointerEvents);
    };
  }, []);

  useEffect(() => {
    const handleScroll = (e: WheelEvent) => {
      e.preventDefault();
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.scrollBy(0, e.deltaY);
      }
    };

    const scrollContainer = overlayRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('wheel', handleScroll, {
        passive: false,
      });
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  return (
    <div className="fixed inset-0 z-50 bg-neutral-50 flex flex-col justify-start items-center gap-[49px]">
      <div className="self-stretch h-16 flex-col justify-start items-center inline-flex">
        <div className="self-stretch h-16 px-6 pt-2 pb-[9px] bg-neutral-800 border-b justify-between items-center inline-flex">
          <button
            className="justify-start items-center gap-2 flex cursor-pointer"
            onClick={() => setStep(2)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') setStep(2);
            }}
            aria-label="Back"
          >
            <ArrowLeftWhite className="w-5 h-5" />
            <div className="text-neutral-50 text-sm font-bold leading-tight">
              Back
            </div>
          </button>
          <div className="justify-center items-center gap-1 flex">
            <button
              className={`h-[46px] pl-4 pr-[18px] py-[13px] rounded-md justify-center items-center gap-2 flex cursor-pointer ${
                !isMobileView ? 'bg-neutral-700' : ''
              }`}
              onClick={() => handleViewSwitch('desktop')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleViewSwitch('desktop');
              }}
              aria-label="Desktop View"
            >
              <DesktopIcon className="w-5 h-5" />
              <div className="text-neutral-50 text-sm font-bold leading-tight">
                Desktop
              </div>
            </button>
            <button
              className={`h-[46px] pl-4 pr-[18px] py-[13px] rounded-md justify-center items-center gap-2 flex cursor-pointer ${
                isMobileView ? 'bg-neutral-700' : ''
              }`}
              onClick={() => handleViewSwitch('mobile')}
              onKeyPress={(e) => {
                if (e.key === 'Enter') handleViewSwitch('mobile');
              }}
              aria-label="Mobile View"
            >
              <MobileIcon className="w-5 h-5" />
              <div className="text-neutral-50 text-sm font-bold leading-tight">
                Mobile
              </div>
            </button>
            {environments.ENABLE_OFFER_STEPS_PREVIEW && (
              <div className="flex items-center text-white text-sm font-bold gap-[14px] ml-2">
                <button
                  className="hover:bg-neutral-700 rounded-full transition-all duration-200"
                  onClick={handlePreviousStepButton}
                >
                  <ArrowChevron className="transform rotate-180" />
                </button>
                Step {stepNumber}
                <button
                  className="hover:bg-neutral-700 rounded-full transition-all duration-200"
                  onClick={handleNextStepButton}
                >
                  <ArrowChevron />
                </button>
              </div>
            )}
          </div>
          <div className="justify-start items-center gap-2 flex">
            <div className="justify-start items-center gap-2 flex">
              {/* <button
                className="h-[46px] px-[18px] py-[13px] bg-neutral-700 rounded-md justify-center items-center flex cursor-pointer"
                onClick={handleSaveDraft}
                aria-label="Save as Draft"
              >
                <div className="text-neutral-50 text-sm font-bold leading-tight">
                  Save as draft
                </div>
              </button> */}
              <button
                className="h-[46px] px-[18px] py-[13px] bg-sky-400 rounded-md justify-center items-center flex cursor-pointer"
                onClick={handlePublish}
                aria-label="Publish"
              >
                <div className="text-neutral-800 text-sm font-bold leading-tight">
                  {offerIsBeingEdited() ? 'Save Changes' : 'Publish'}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`relative flex justify-center items-center h-[95%] rounded-lg shadow-xl ${
          isMobileView ? 'w-[475px]' : 'w-[95%]'
        }`}
        ref={overlayRef}
      >
        {previewContent ? (
          <div className="relative w-full h-full">
            <iframe
              ref={iframeRef}
              id="offer-preview-iframe"
              title="Offer Preview"
              srcDoc={previewContent}
              style={{
                width: isMobileView ? '475px' : '100%',
                height: '100%',
                border: 'none',
                pointerEvents: 'none',
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'transparent',
                pointerEvents: 'auto',
              }}
              onMouseDown={(e) => e.preventDefault()}
              aria-hidden="true"
            />
          </div>
        ) : (
          <p>Loading preview...</p>
        )}
      </div>
      <ToastContainer limit={1} />
    </div>
  );
};

export default OfferPreview;
