import {
  BatchResponse,
  ModOrderInReviewResponse,
  GroupedOrdersResponse,
} from '../pages/reports/MailOnDemand/types';
import { getInstance } from './instance';

const instance = getInstance();

export const fetchMailOnDemandOrders = async (
  page: number = 1,
  perPage: number = 10,
  sortBy?: string,
  sortOrder: 'asc' | 'desc' = 'desc',
  draftNames?: string[],
  startDate?: string,
  endDate?: string,
): Promise<GroupedOrdersResponse> => {
  try {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('perPage', perPage.toString());
    if (sortBy) {
      params.append('sortBy', sortBy);
      params.append('sortOrder', sortOrder);
    }
    if (draftNames && draftNames.length > 0) {
      draftNames.forEach((draftName) => {
        params.append('draftName', draftName);
      });
    }
    if (startDate) {
      params.append('startDate', startDate);
    }
    if (endDate) {
      params.append('endDate', endDate);
    }

    const { data } = await instance.get(
      'mail-on-demand/orders/find-all-in-review',
      { params },
    );
    return data;
  } catch (error) {
    console.error('Error fetching print on demand orders:', error);
    throw error;
  }
};

export const fetchBatches = async (
  page: number = 1,
  perPage: number = 10,
  status?: string,
  sortBy?: string,
  sortOrder: 'asc' | 'desc' = 'desc',
): Promise<BatchResponse> => {
  try {
    const params = new URLSearchParams({
      page: (page - 1).toString(),
      perPage: perPage.toString(),
      ...(status && status !== 'All' && { status }),
      ...(sortBy && { sortBy }),
      sortOrder,
    });

    const response = await instance.get(
      `mail-on-demand/batches/find-all?${params.toString()}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching batches:', error);
    throw error;
  }
};

export const fetchBatchById = async (id: string): Promise<BatchResponse> => {
  try {
    const response = await instance.get(
      `mail-on-demand/batches/find-one?id=${id}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching batch details:', error);
    throw error;
  }
};

export const createBatch = async (modOrderIds: string[]): Promise<any> => {
  try {
    const response = await instance.post('mail-on-demand/batches/create', {
      modOrderIds,
    });
    return response.data;
  } catch (error) {
    console.error('Error creating batch:', error);
    throw error;
  }
};

export const fetchUniqueDraftNames = async (): Promise<string[]> => {
  try {
    const { data } = await instance.get('mail-on-demand/orders/books');
    return data;
  } catch (error) {
    console.error('Error fetching unique draft names:', error);
    throw error;
  }
};
