import * as React from 'react';

const RedClockIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_20823_108972)">
      <path
        d="M6.66683 14.9993C6.66683 19.6017 10.3978 23.3327 15.0002 23.3327C19.6025 23.3327 23.3335 19.6017 23.3335 14.9993C23.3335 10.397 19.6025 6.66602 15.0002 6.66602"
        stroke="#E75A50"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 11.75C15.4142 11.75 15.75 12.0858 15.75 12.5V15.0833H18.3333C18.7475 15.0833 19.0833 15.4191 19.0833 15.8333C19.0833 16.2475 18.7475 16.5833 18.3333 16.5833H15C14.5858 16.5833 14.25 16.2475 14.25 15.8333V12.5C14.25 12.0858 14.5858 11.75 15 11.75Z"
        fill="#E75A50"
      />
      <circle
        cx="14.9998"
        cy="14.9993"
        r="8.33333"
        stroke="#E75A50"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeDasharray="0.5 3.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_20823_108972">
        <rect width="20" height="20" fill="white" transform="translate(5 5)" />
      </clipPath>
    </defs>
  </svg>
);

export default RedClockIcon;
