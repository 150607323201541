import React from 'react';
import { MODProvider } from './context/MODContext';
import { Outlet, useLocation } from 'react-router-dom';

const MailOnDemand: React.FC = () => {
  const location = useLocation();
  const isBatchView = location.pathname.includes('/batch/');

  return (
    <MODProvider>
      <div className="w-full pt-4 min-h-screen flex-col inline-flex font-figtree">
        {!isBatchView && (
          <h1 className="text-neutral-800 text-2xl font-extrabold leading-7 mb-6">
            Mail on Demand
          </h1>
        )}
        <Outlet />
      </div>
    </MODProvider>
  );
};

export default MailOnDemand;
